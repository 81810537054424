import {createContext, useEffect, useState} from "react";
import {ProductBriefProps} from "../components/ProductBriefPanel/ProductBriefPanel";
import {fetchWithTimeout} from "../modules/helper";

interface BetterForYouContextInterface {
  betterProducts: ProductBriefProps[];
  isLoading: boolean;
  productBarcode: string | undefined;
  searchForProduct: (productBarcode: string) => void;
}

const BetterForYouContext = createContext<BetterForYouContextInterface | null>(null);

export function BetterForYouProvider(props: {children: any}) {
  // get better for you list from API then save in context
  const [betterForYouList, setBetterForYouList] = useState<ProductBriefProps[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [barcode, setBarcode] = useState<string| undefined>(undefined);

  // fetch data
  useEffect( () => {
    if (!barcode) {
      return;
    }

    fetchWithTimeout(process.env.REACT_APP_API_END_POINT  + "/api/better_for_you.json", {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ barcode: barcode })
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        console.debug("Better for you have been fetched from API.");
        // data in ProductBriefProps style
        setBetterForYouList(data.data);
        setIsLoading(false);
      })
      .catch(error => {
        console.error(error);
        setBetterForYouList([]);
        setIsLoading(false);
      });
  },[barcode]);

  // store in this context
  const context: BetterForYouContextInterface = {
    betterProducts: betterForYouList,
    isLoading: isLoading,
    productBarcode: barcode,
    searchForProduct: searchHandler,
  };

  function searchHandler(productBarcode: string): void {
    setBarcode(productBarcode);
    setIsLoading(true);
  }

  return <BetterForYouContext.Provider value={context}>
    {props.children}
  </BetterForYouContext.Provider>
}

export default BetterForYouContext;
