import React from 'react';
import './App.css';
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";

import {Route, Routes, useLocation} from 'react-router-dom';

import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Learn from "./pages/Learn/Learn";
import Article from "./pages/Article/Article";
import { ArticleListProvider } from "./services/LearnService";
import MeetOurExperts from "./pages/MeetOurExperts/MeetOurExperts";
import FAQ from "./pages/FAQ/FAQ";
import ContactUs from "./pages/ContactUs/ContactUs";
import TermsOfUse from "./pages/TermsOfUse/TermsOfUse";
import SearchResults from "./pages/SearchResults/SearchResults";
import Product from "./pages/Product/Product";
import { SearchResultsProvider } from "./services/ProductSearchResultsService";
import { BetterForYouProvider } from "./services/ProductBetterForYouService";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { MyListsProvider } from "./services/MyListsContext";
import { SearchRelatedDataProvider } from "./services/SearchRelatedDataService";

const queryClient = new QueryClient()

function App() {
  const location = useLocation();

  return <div className="App">
    {
      // TODO 1 remove this block when release to prod
      process.env.REACT_APP_CUSTOM_ENVIRONMENT === "prod" ? <div style={{height: "50vh", display: "grid"}}>
          <div style={{margin: "auto", padding: "20px"}}>
            <h1>We'll be live soon!</h1>
            <h4>We are currently developing the website. It will be live soon!</h4>
            <div>— The Team</div>
          </div>
        </div> :
        <MyListsProvider>
          <SearchRelatedDataProvider>
            {location.pathname !== "/" &&
            <header className="App-header">
                <Header/>
            </header>
            }
            <div className="Page">
              <QueryClientProvider client={queryClient}>
                <ArticleListProvider>
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="about" element={<About />} />
                    <Route path="learn" element={<Learn />} />
                    <Route path="article/:id" element={<Article />}/>
                    <Route path="meet-experts" element={<MeetOurExperts />} />
                    <Route path="faq" element={<FAQ />} />
                    <Route path="contact-us" element={<ContactUs />} />
                    <Route path="terms-of-use" element={<TermsOfUse />} />
                    <Route path="products/:searchText" element={<SearchResultsProvider> <SearchResults /> </SearchResultsProvider>} />
                    <Route path="product/:id" element={<SearchResultsProvider><BetterForYouProvider> <Product /> </BetterForYouProvider></SearchResultsProvider>} />
                    <Route path="*" element={<div style={{margin: "30px"}}>Please use the correct URL path.</div>} />
                  </Routes>
                </ArticleListProvider>
              </QueryClientProvider>
            </div>
            <footer>
              <Footer/>
            </footer>
          </SearchRelatedDataProvider>
        </MyListsProvider>
    }

  </div>;
}

export default App;
