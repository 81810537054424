import React, {createContext, useEffect, useState} from "react";
import {BrandNames, Categories} from "../modules/constants";
import {fetchWithTimeout} from "../modules/helper";

interface SearchRelatedDataContextInterface {
  searchList: string[];
  brandNames: string[];
  categories: { full_name: string; food_group_code: string; }[];
  isLoading: boolean;
}

const SearchRelatedDataContext = createContext<SearchRelatedDataContextInterface | null>(null);

export function SearchRelatedDataProvider(props: any) {
  // get searchList from API then save in context
  const [searchList, setSearchList] = useState<string[]>([]);
  const [brandNames, setBrandNames] = React.useState<string[]>([]);
  const [categories, setCategories] = React.useState<{ full_name: string; food_group_code: string; }[]>([]);
  const [isLoadingSearch, setIsLoadingSearch] = useState(true);
  const [isLoadingBrandNames, setIsLoadingBrandNames] = useState(true);
  const [isLoadingCategories, setIsLoadingCategories] = useState(true);

  // fetch data
  useEffect( () => {
    // get popular search list
    fetchWithTimeout(process.env.REACT_APP_API_END_POINT + "/api/popular_searches.json?size=50")
      .then(response => response.json())
      .then(data => {
        console.debug("Popular Search Data fetched from API.");
        // save result data
        setSearchList(data.data.filter((item: string) => item !== ""));
        setIsLoadingSearch(false);
      })
      .catch(error => {
        console.error("Popular Search Data fetch from API failed.", error);
        setSearchList([]);
        setIsLoadingSearch(false);
      });

    // get brandNames
    fetchWithTimeout(process.env.REACT_APP_API_END_POINT +  "/api/brand_names.json")
      .then(response => response.json())
      .then(data => {
        console.debug("BrandNames fetched from API.");
        setBrandNames(data.data);
        setIsLoadingBrandNames(false);
      })
      .catch(error => {
        console.error("BrandNames fetch from API failed.", error);
        setBrandNames(BrandNames);
        setIsLoadingBrandNames(false);
      });

    // get categories
    fetchWithTimeout(process.env.REACT_APP_API_END_POINT +  "/api/food_categories.json")
      .then(response => response.json())
      .then(data => {
        console.debug("Categories fetched from API.");
        setCategories(data.data);
        setIsLoadingCategories(false);
      })
      .catch(error => {
        console.error("Categories fetch from API failed.", error);
        setCategories(Categories);
        setIsLoadingCategories(false);
      })
  },[]);

  // store in this context
  const context: SearchRelatedDataContextInterface = {
    searchList: searchList,
    brandNames: brandNames,
    categories: categories,
    isLoading: isLoadingSearch || isLoadingBrandNames || isLoadingCategories ,
  };

  return <SearchRelatedDataContext.Provider value={context}>
    {props.children}
  </SearchRelatedDataContext.Provider>
}

export default SearchRelatedDataContext;
