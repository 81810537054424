import React, {useContext} from 'react';
import styles from './ProductBriefPanel.module.css';
import {useNavigate} from "react-router-dom";
import {Alert, Box, CircularProgress, Divider, Snackbar} from "@mui/material";
import { get, set } from "idb-keyval";

import AddProductToList from "../AddProductToList/AddProductToList";
import MyListsContext from "../../services/MyListsContext";

import GFIcon from "../../resources/Search Results/GF.svg";
import PlaceHolderProductIcon from "../../resources/Search Results/placeholde product icon.svg";
import RemoveIcon from "../../resources/My lists/remove icon.svg";

import Star05Icon from "../../resources/Health Stars/Health Star - 0.5.svg";
import Star10Icon from "../../resources/Health Stars/Health Star - 1.svg";
import Star15Icon from "../../resources/Health Stars/Health Star - 1.5.svg";
import Star20Icon from "../../resources/Health Stars/Health Star - 2.svg";
import Star25Icon from "../../resources/Health Stars/Health Star - 2.5.svg";
import Star30Icon from "../../resources/Health Stars/Health Star - 3.svg";
import Star35Icon from "../../resources/Health Stars/Health Star - 3.5.svg";
import Star40Icon from "../../resources/Health Stars/Health Star - 4.svg";
import Star45Icon from "../../resources/Health Stars/Health Star - 4.5.svg";
import Star50Icon from "../../resources/Health Stars/Health Star - 5.svg";


export interface ProductBriefProps {
  barcode: string,
  product_name: string,
  brand_name: string,
  packet_size: number,
  packet_unit: string,
  product_hsr_score: number,
  product_photo: string,
  gluten_free: boolean
}

const selectStarIcon = (score: number) => {
  const roundHalfOfScore = Math.round(score*2)/2;
  switch (roundHalfOfScore) {
    case 0.5:
      return Star05Icon;
    case 1:
      return Star10Icon;
    case 1.5:
      return Star15Icon;
    case 2:
      return Star20Icon;
    case 2.5:
      return Star25Icon;
    case 3:
      return Star30Icon;
    case 3.5:
      return Star35Icon;
    case 4:
      return Star40Icon;
    case 4.5:
      return Star45Icon;
    case 5:
      return Star50Icon;
    default:
      return Star05Icon;
  }
};

function ProductBriefPanel(props: { item: ProductBriefProps, isDetail?: boolean, myListsName?: string }) {
  // navigate to another product
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");

  const handleClick = () => {
    navigate(`/product/${props.item.barcode}`);
  }

  // get data from context
  const myListsCtx = useContext(MyListsContext);
  if (!myListsCtx) {
    throw new Error("Context must be used within a Provider");
  }

  if (myListsCtx.isLoading) {
    return <CircularProgress />;
  }

  const favouriteList: ProductBriefProps[] = myListsCtx.favouriteList;

  // save product to lists
  const checkItemExistsInList = (list: ProductBriefProps[]) => {
    return list.some(eachProduct => eachProduct.barcode === props.item.barcode);
  }

  const checkCurrentProductInLists = async (listNames: string[]) => {
    const confirmedListNames = await Promise.all(
        listNames.map(async (listName) => {
          let productsInList = await get<ProductBriefProps[]>(listName);
          // set to [] when it's a new empty list
          productsInList = productsInList ? productsInList : [];
          if (checkItemExistsInList(productsInList)) {
            return listName;
          }
          return null;
        })
    );

    // Filter out null values (listNames where the item doesn't exist)
    return confirmedListNames.filter((name) => name !== null);
  };

  const saveInFavouriteList = () => {
    let newList;
    if (checkItemExistsInList(favouriteList)) {
      // remove it from favorite list
      console.debug('remove', props.item.barcode);
      newList = favouriteList.filter(eachProduct => eachProduct.barcode !== props.item.barcode);
    } else {
      // add it into favorite list
      console.debug('add', props.item.barcode);
      newList = favouriteList.concat(props.item);
    }

    // update favouriteList
    myListsCtx.updateFavouriteList(newList);
  }

  const saveInMyList = (listName: string) => {
    get<ProductBriefProps[]>(listName).then(productsInList => {
      // set to [] when it's a new empty list
      productsInList = productsInList ? productsInList : [];

      if (!checkItemExistsInList(productsInList)) {
        // add it into myList with the name only if it does not exist in productsInList
        console.debug('add', props.item.barcode);
        const newList = productsInList.concat(props.item);

        set(listName, newList).then(() => {
          console.debug('Update list successfully:', listName);
          setSnackbarMessage('Added to list "' + listName + '" successfully.');
          setOpenSnackbar(true);
        }).catch((err) => {
          console.error('Set ' + listName + ' failed!', err);
          alert('Set ' + listName + ' list failed when adding item, please contact us so we can fix it for you.');
        });
      } else {
        // remove it from myList with the name
        console.debug('remove', props.item.barcode);
        const newList = productsInList.filter(eachProduct => eachProduct.barcode !== props.item.barcode);
        set(listName, newList).then(() => {
          console.debug('Update list successfully:', listName);
          setSnackbarMessage('Remove from list "' + listName + '" successfully.');
          setOpenSnackbar(true);
        }).catch((err) => {
          console.error('Set ' + listName + ' failed!', err);
          alert('Set ' + listName + ' list failed when removing item, please contact us so we can fix it for you.');
        });
      }
    });
  }

  const removeFromMyList = (listName: string | undefined) => {
    if (!listName) return;

    get<ProductBriefProps[]>(listName).then(productsInList => {
      // set to [] when it's a new empty list
      productsInList = productsInList ? productsInList : [];

      if (checkItemExistsInList(productsInList)) {
        // remove it from myList when it already exist
        console.debug('remove', props.item.barcode);
        const newList: ProductBriefProps[] = productsInList.filter(eachProduct => eachProduct.barcode !== props.item.barcode);

        set(listName, newList).then(() => {
          console.debug('Update list successfully:', listName);
          window.location.reload(); // TODO 1 maybe use context to handle all list can avoid this reload.
          setSnackbarMessage('Removed from list "' + listName + '" successfully.');
          setOpenSnackbar(true);
        }).catch((err) => {
          console.error('Set ' + listName + ' failed!', err);
          alert('Set ' + listName + ' list failed, please contact us so we can fix it for you.');
        });
      }
    });
  }

  return <div>
    <Snackbar
      open={openSnackbar}
      autoHideDuration={6000}
      onClose={() => setOpenSnackbar(false)}
    >
      <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%' }}>
        {snackbarMessage}
      </Alert>
    </Snackbar>
    {/* card for small screen */}
    <div className="ShowFor900SmallScreenOnly">
      {
        props.myListsName && <div>
          {/* Remove Button */}
            <div className={styles.RemoveButton}>
                <img src={RemoveIcon} alt="remove" className={styles.ButtonIcon} onClick={() => removeFromMyList(props.myListsName)}/>
                <span style={{paddingLeft: "10px"}}>Remove</span>
            </div>
        </div>
      }
      <Box className={styles.ProductBriefPanel}
           style={props.myListsName ? {maxWidth: "90%"} : {maxWidth: "100%"}}
           data-testid="ProductBriefPanelSmallScreen">
        <div className={styles.Container}>
          <div className={styles.ProductInfo} onClick={handleClick}>
            {/* image */}
            <div className={styles.ImgContainer}>
              <img src={props.item.product_photo ? props.item.product_photo : PlaceHolderProductIcon} alt="product img" className={styles.ProductImg}/>
              <img src={selectStarIcon(props.item.product_hsr_score)} alt="star icon" className={styles.StarIcon}/>
            </div>
            {/* text */}
            <div style={{paddingLeft: "2px"}}>
              <div className={styles.ProductNameText}>{props.item.product_name}</div>
              <div className={styles.ProductOtherText}>{props.item.brand_name}, {props.item.packet_size}{props.item.packet_unit}</div>
              <div className={styles.ProductTags}>
                {props.item.gluten_free && <img src={GFIcon} alt="GF" style={{width: "clamp(27px, 1.85vw + 20px, 55px)"}}/>}
                {props.isDetail && <AddProductToList saveInMyList={saveInMyList}
                                                     checkCurrentProductInLists={checkCurrentProductInLists}
                                                     saveInFavouriteList={saveInFavouriteList}/>}
              </div>
            </div>
          </div>
          {
            !props.myListsName && <div>
              {/* favourite */}
                <div className={styles.Favourite}>
                  <div className={checkItemExistsInList(favouriteList) ? styles.SelectedFavouriteIcon : styles.NoHoverFavouriteIcon}
                       onClick={saveInFavouriteList}/>
                </div>
            </div>
          }
        </div>
      </Box>
    </div>
    {/* panel for big screen */}
    <div className="ShowFor900BigScreenOnly">
      {/* selected product detail on top or item in a product list */}
      {
        props.isDetail ?
          // item detail view
          <Box style={{width: "clamp(550px, 43.3vw + 377px, 1200px)"}} data-testid="ProductDetailPanelBigScreen">
            <div className={styles.ProductInfo}>
              {/* image */}
              <div className={styles.DetailImgContainer}>
                <img src={props.item.product_photo ? props.item.product_photo : PlaceHolderProductIcon}
                     alt="product img" className={styles.DetailProductImg}/>
                <img src={selectStarIcon(props.item.product_hsr_score)} alt="star icon" className={styles.DetailStarIcon}/>
              </div>
              {/* text */}
              <div style={{marginLeft: "25px"}}>
                <div className={styles.ProductNameTextDetail}>{props.item.product_name}</div>
                <div className={styles.ProductOtherTextDetail}>{props.item.brand_name}, {props.item.packet_size}{props.item.packet_unit}</div>
                <div className={styles.ProductTags}>
                  {props.item.gluten_free && <img src={GFIcon} alt="GF" className={styles.ProductTag}/>}
                  <AddProductToList saveInMyList={saveInMyList}
                                    checkCurrentProductInLists={checkCurrentProductInLists}
                                    saveInFavouriteList={saveInFavouriteList}/>
                </div>
              </div>
            </div>
          </Box> :
          // item in list view
          <Box className={styles.ProductBriefPanel} data-testid="ProductBriefPanelListView">
            <div className={styles.Container}>
              <div className={styles.ProductInfo} onClick={handleClick}>
                {/* image */}
                <div className={styles.ImgContainer}>
                  <img src={props.item.product_photo ? props.item.product_photo : PlaceHolderProductIcon} alt="product img" className={styles.ProductImg}/>
                  <img src={selectStarIcon(props.item.product_hsr_score)} alt="star icon" className={styles.StarIcon}/>
                </div>
                {/* text */}
                <div style={{marginLeft: "5px"}}>
                  <div className={styles.ProductNameText}>{props.item.product_name}</div>
                  <div className={styles.ProductOtherText}>{props.item.brand_name}, {props.item.packet_size}{props.item.packet_unit}</div>
                  <div className={styles.ProductTags}>
                    {props.item.gluten_free && <img src={GFIcon} alt="GF" style={{width: "clamp(27px, 1.85vw + 20px, 55px)"}}/>}
                  </div>
                </div>
              </div>
              {
                props.myListsName ? <div style={{margin: "auto"}}>
                    {/* remove */}
                    <img src={RemoveIcon} alt="remove" className={styles.ButtonIcon} onClick={() => removeFromMyList(props.myListsName)}/>
                  </div>
                  :
                  <div style={{display: "flex"}}>
                    {/* favourite */}
                    <div className={styles.Favourite}>
                      <div className={checkItemExistsInList(favouriteList) ? styles.SelectedFavouriteIcon : styles.FavouriteIcon}
                           onClick={saveInFavouriteList}/>
                    </div>
                  </div>
              }
            </div>
            {
              props.myListsName && <Divider />
            }
          </Box>
      }
    </div>
  </div>
}

export default ProductBriefPanel;
