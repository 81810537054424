import React, {createContext, useEffect, useState} from "react";
import {get, set, del} from "idb-keyval";
import {ProductBriefProps} from "../components/ProductBriefPanel/ProductBriefPanel";

interface MyListsContextInterface {
  isLoading: boolean;
  listNames: string[];
  favouriteList: ProductBriefProps[];
  updateFavouriteList: any;
  deleteCustomList: any;
  editCustomList: any;
}

const MyListsContext = createContext<MyListsContextInterface | null>(null);

export function MyListsProvider(props: any) {
  const [isLoading, setIsLoading] = useState(true);
  const [myListNames, setMyListNames] = React.useState<string[]>([]);
  const [favouriteList, setFavouriteList] = useState<ProductBriefProps[]>([]);

  useEffect(() => {
    // get myListNames from local storage
    console.debug("get myListNames from local storage.");
    get<string[]>("myListNames").then(listNames => {
      if (listNames) {
        setMyListNames(listNames);
        console.debug("update myListNames!");
      }
      setIsLoading(false);
    });

    // init favouriteList from local storage
    console.debug("init favouriteList from local storage");
    get<ProductBriefProps[]>("Favourites").then(value => {
      if (value) {
        setFavouriteList(value);
        console.debug("update favouriteList!");
      }
    });
  }, []);

  const updateFavouriteListHandler = (newList: ProductBriefProps[]) => {
    setFavouriteList(newList);
    // update local storageq
    set('Favourites', newList).then(() => {
      console.debug('Update favouriteList successfully, newList size:', newList.length);
    }).catch((err) => {
      console.error('Set favouriteList failed!', err);
      alert('Set favourite list failed, please contact us so we can fix it for you.');
    });
  }

  const createCustomList = (listName: string, productList: ProductBriefProps[]): Promise<void> => {
    if (!listName) {
      return Promise.reject("list name shouldn't be empty");
    }

    // if newListName not exist in myListNames and it's not 'Favourites', add it to local storage
    if (myListNames.indexOf(listName) >= 0 || listName === 'Favourites') {
      return Promise.reject("list name '" + listName + "' exist already");
    }

    return set(listName, productList).then(() => {
        // add into myListNames
        myListNames.push(listName);
        return set('myListNames', myListNames);
      }
    ).catch(err => {
      console.error('Create list ' + listName + ' failed!', err);
      alert('Create list ' + listName + ' failed: ' + err + '. Please contact us so we can fix it for you.');
    });
  }

  const deleteCustomListHandler = (listName: string) => {
    // 1. remove the name-products k-v from local storage
    del(listName).then(() => {
      console.debug('list has been deleted:', listName);
      // 2. delete from myListNames
      const index = myListNames.indexOf(listName);
      if (index > -1) {
        myListNames.splice(index, 1);
        set('myListNames', myListNames).then(() => {
            window.location.reload();
          }
        );
      }
    }).catch((err) => {
      console.error('Remove products of list failed!', err);
      alert('Remove products of list failed: ' + err + '. Please contact us so we can fix it for you.');
    });
  }

  const editCustomListHandler = (oldName: string, newName: string) => {
    // 1. get value of oldName
    get<ProductBriefProps[]>(oldName).then(value => {
      if (value) {
        // 2. create a new list with newName and value
        createCustomList(newName, value).then(() => {
          console.debug('create list successfully:', newName);
          // 3. remove oldName k-v
          deleteCustomListHandler(oldName);
        }).catch((err) => {
          console.error('create list failed!', err);
          alert('create list failed: ' + err + '. Please contact us so we can fix it for you.');
        });
      }
    });
  }

  // store in this context
  const context: MyListsContextInterface = {
    isLoading: isLoading,
    listNames: myListNames,
    favouriteList: favouriteList,
    updateFavouriteList: updateFavouriteListHandler,
    deleteCustomList: deleteCustomListHandler,
    editCustomList: editCustomListHandler
  };

  return <MyListsContext.Provider value={context}>
    {props.children}
  </MyListsContext.Provider>
}

export default MyListsContext;
