import React, {FC} from 'react';
import {Link} from "react-router-dom";

import styles from './Header.module.css';
import logo from '../../logo.svg';
import logoWhite from '../../resources/Home/header/Logo White_svg.svg';

import MyMenu from "../MyMenu/MyMenu";
import MyLists from "../MyLists/MyLists";

interface HomeHeaderProps {}

function SimpleMenu() {
  return <div className={`${styles.HomeHeaderMenuLink} TextHeading3`} style={{fontWeight: 600}}>
    <Link to="/about">About</Link>
    <Link to="/learn">Learn</Link>
    <Link to="/meet-experts">Our Experts</Link>
    <Link to="/faq">FAQs</Link>
    <Link to="/contact-us">Contact Us</Link>
  </div>;
}

const HomeHeader: FC<HomeHeaderProps> = () => {
  // drawer state of "My Lists"
  const [drawerState, setDrawerState] = React.useState(false);

  const toggleMyListsDrawer = (newState: boolean) => () => {
    setDrawerState(newState);
  };

  return <div className="Container1920" data-testid="HomeHeader">
    <div className={styles.HomeHeader}>
      {/* Logo */}
      <div className={styles.HeaderLogo}>
        <Link to="/">
          <img src={logoWhite} className={styles.Logo} alt="logo-white" />
          <img src={logo} className={styles.HomeLogoSmall} alt="logo" />
        </Link>
      </div>

      {/* My Menu */}
      <div style={{margin: "auto 0"}}>
        <div className="ShowFor1200BigScreenOnly">
          <div className={styles.HeaderMenu}>
            <SimpleMenu/>
          </div>
        </div>
        <div className="ShowFor1200SmallScreenOnly">
          <div className={styles.HeaderMenu}>
            <MyLists drawerState={drawerState} toggleMyListsDrawer={toggleMyListsDrawer}/>
            <MyMenu toggleMyListsDrawer={toggleMyListsDrawer}/>
          </div>
        </div>
      </div>
    </div>
  </div>
};

export default HomeHeader;
