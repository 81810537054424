import React, {FC, useContext} from 'react';
import styles from './Article.module.css';
import ArticleListContext from "../../services/LearnService";
import {Link, useParams} from "react-router-dom";
import {Alert, AlertTitle, CircularProgress, Grid} from "@mui/material";

import ArticleInfo from "../../components/ArticleInfo/ArticleInfo";
import BackIcon from "../../resources/View product/back icon.svg";

interface ArticleProps {}

const Article: FC<ArticleProps> = () => {
  const { id } = useParams();

  // get the article from context
  const articleListCtx = useContext(ArticleListContext);
  if (!articleListCtx) {
    throw new Error("Context must be used within a Provider");
  }

  if (articleListCtx.isLoading) {
    return <CircularProgress />;
  }

  if (!id) {
    throw new Error("Need the article uuid as URL parameter.");
  }

  // get article data
  const articles = articleListCtx.findArticle(id);
  if (!articles || !articles[0]) {
    return <Alert variant="filled" severity="error" className="FullWindowWidth" style={{textAlign: "left"}}>
      <AlertTitle>Error</AlertTitle>
      The article you requested does not exist. — <strong>check the article id again!</strong>
    </Alert>;
  }

  const article = articles[0];

  // images
  article.content = article.content.replaceAll(`src="/sites/default`,`src="https://prod.nutriweb.auckland.ac.nz/sites/default/`);

  // render it
  return <div className={`${styles.Article} Container1920`} data-testid="Article">
    <br/>
    <div>
      <Link to="/learn" style={{textDecoration: "none"}}>
        <div className="NavigateBackText">
          <img src={BackIcon} alt="backIcon"/>
          <span className={styles.NavigateBackText}>&nbsp;Articles</span>
        </div>
      </Link>
    </div>
    <br/>

    <Grid container spacing={2} alignItems={"center"} justifyContent="center">
      <Grid item xs={12} lg={8}>
        <div className={styles.Title}>
          {article.title}
        </div>
        <br/>
        <div style={{width: "clamp(250px, 8vw + 218px, 370px)"}}>
          <ArticleInfo authorAvatar={article.authorAvatar}
                       authorName={article.authorName}
                       authorDesignation={article.authorDesignation}
                       timeToRead={article.timeToRead}/>
        </div>
        <br/>
        <div>
          <div className={styles.Content} dangerouslySetInnerHTML={{__html: article.content}}/>
        </div>
      </Grid>
    </Grid>
  </div>;
}

export default Article;
