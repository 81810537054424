export const constant = {
};

export const BrandNames = [
  "1 Above",
  "100% Fresh",
  "100% Kiwi",
  "100% Kiwi New Zealand",
  "100% Nutz",
  "18 Degree Souch",
  "18 Degree South",
  "18 Degrees South",
  "18 Degress South",
  "180 de",
  "180 Degree",
  "180 Degrees",
  "180 Degrees South",
  "180 Degres",
  "1839",
  "1839 Honey",
  "28 Balck Classic",
  "28 Black",
  "28 Black Acai",
  "3 Bees",
  "360",
  "360 Degree",
  "360 Degrees",
  "4025946100467",
  "4U",
  "5",
  "58 Base",
  "7 UP",
  "7613036839808",
  "7UP",
  "85",
  "9310015245534",
  "A & W",
  "A Cafe La",
  "A&W",
  "A&W Diet",
  "A2",
  "A2 Platinum",
  "AAAAA",
  "Aah Bisto",
  "Aah Bistp",
  "Aah! Bisto",
  "Aashiayana",
  "Aashiayana Authentic Indian Cuisine",
  "Aashiayana Thali",
  "ABC",
  "ABCD",
  "ABCD Vitamin Water",
  "Abe's",
  "Abe's Bagel",
  "Abe's Bagel Bakery",
  "Abe's Bagels",
  "ABE's Real Bagels",
  "Abella",
  "Abes",
  "Abes Bagel Bakery",
  "Abes's Bagels",
  "Abram Lyle & Sons",
  "Absolute Organic",
  "Abundance",
  "Abundant Earth",
  "ACafela",
  "Accelerade",
  "Acesur",
  "Acetala",
  "Acetala Villa Edda",
  "Acetificio Emiliani",
  "Aceto Balsamico",
  "Aceto Balsamico Di Modena IGP",
  "Acqua Panna",
  "ACT II",
  "Activ-go",
  "Activate",
  "Active Manuka",
  "Addmore",
  "Adella",
  "Adriatic Queen",
  "Adventure Kitchen",
  "Aero",
  "Aeroplane",
  "Agnas Park",
  "Ahia",
  "Ail And Fine Herbes",
  "Ainsley",
  "Ainsley Harriot",
  "Ainsley Harriott",
  "Ainsley Harriott World Kitchen",
  "Airborne",
  "Airborne Honey Guardians",
  "Aji Nori",
  "Ajitas",
  "Ajitsuke Nori",
  "Akitsuya",
  "Al Brown",
  "Al Chipino",
  "Al-Rabih",
  "Alamir Bakery",
  "Alb Gold",
  "Alb-Gold",
  "Albiero",
  "Albo",
  "Alchemy",
  "Alchemy & Tonic",
  "Alderson's",
  "Aldersons",
  "Alejandro",
  "Alexandra's",
  "Alexandra's Spice Bazaar",
  "Alexandras",
  "Alfa One",
  "Alicos",
  "Alimanti Dallari",
  "Alimenti Dallari",
  "Alimenti Dallari Sorbara",
  "Alisan's Pantry Quick Scoop",
  "Alison Holst",
  "Alison Holst Natural Harvest",
  "Alison's Pantry",
  "All Blacks",
  "All Gold",
  "All Good",
  "All Good Organics",
  "All Good Stuff",
  "All-Bran",
  "Allanics",
  "Allegro",
  "Allen's",
  "Allens",
  "Allens, Nestle",
  "Allergy Wise",
  "AllergyWise",
  "Allganics",
  "Almighty",
  "Almighty Organic",
  "Almond gold",
  "Aloe",
  "Aloe Water",
  "Alpine",
  "Alpro",
  "Alpro Soya",
  "Alter Eco",
  "Always Fresh",
  "Alzheimers",
  "Alzheimers New Zealand",
  "Amaize",
  "Amanoya",
  "Amanoya Asian",
  "Amaretti",
  "Amasushoga Pink",
  "Amazonia",
  "Amberlyn",
  "Ambriosa",
  "Ambrosia",
  "Amco",
  "Amino Mantra",
  "Amo's",
  "Amos",
  "Amoy",
  "Amplify",
  "Anathoth",
  "Anathoth Farm",
  "Anchor",
  "Anchor Symbio Probalance",
  "Andre's Kitchen",
  "Andrew Corbet",
  "Andrew Corbett",
  "Angas Park",
  "Angel Bay",
  "Angel Food",
  "Angle Bay",
  "Anglesey Sea Salt",
  "Angostura",
  "Animal Welfare Foods",
  "Anitin",
  "Anmum",
  "Anmum Gold",
  "Annies",
  "Anthon Berg",
  "Antico Borgo",
  "Antionette's",
  "Antiox",
  "Antipode",
  "Antipodes",
  "Antoinette's",
  "Any time Kids Range",
  "Anzac",
  "Anzac Biscuits",
  "ANZCO Foods",
  "Aoi Foods",
  "Aoraki",
  "Aoraki Smoke House",
  "Aoraki Smokehouse",
  "Aotea",
  "Aotea Native Tonics",
  "Aotearoad",
  "Apanthe",
  "Apatu Aqua",
  "Apatu Aqua Enterprises Ltd",
  "Aperifrais",
  "Apetina",
  "Apple Quarters Fine Foods",
  "Appleby Farms",
  "Applemaid",
  "Appletiser",
  "Applewood",
  "Appy Kids Co",
  "Aptamil",
  "Aqa Lite",
  "Aqua",
  "Arahi",
  "Arano",
  "Arano Juices Ltd",
  "Arano Orchards",
  "Arataki",
  "Arataki Honey",
  "Ardennes FarmHouse",
  "Arepa",
  "Aria Farm",
  "Aria Food",
  "Aribar",
  "Aristocrat",
  "Aristocrat Rosella",
  "Arizona",
  "Arkadia",
  "Arluy",
  "Arnold Sorensen",
  "Arnoldi",
  "Arnott's",
  "Arnott's Homebrand",
  "Arnott's Shapes",
  "Arnotts",
  "Arnotts'",
  "Arnotts's",
  "Arobake Bakers & Patissiers",
  "Aroha",
  "Aromalt",
  "Aronott's",
  "Arrott's",
  "Arrowroot",
  "Artisan Bake House",
  "Artisan Bakehouse",
  "Artisan Desserts",
  "Artisan Home Gourmet",
  "Artisano",
  "Artisano Real Soup",
  "Artisse",
  "Artisse Organic",
  "As-Sweet",
  "Asain Home Gourmet",
  "Asameshi Nori",
  "Ascension Kitchen",
  "Aseension Kitchen",
  "Ashoka",
  "Asian Home Gourmet",
  "Asian Tonight",
  "Atkins",
  "Atkins Advantage",
  "Atkins Day Break",
  "Atkins Endulge",
  "Atora",
  "Au'some",
  "Aulando",
  "Aunt Betty's",
  "Aunt Bettys",
  "Aunt Bettys Coconut Roughs",
  "Aunt Jemima",
  "Auntie Dai's",
  "Aunty Betty's",
  "Aunty Von's",
  "Aunty Vons",
  "Ausome",
  "Aussie Bidies",
  "Aussie Bodies",
  "Aussie Bodies ProteinFX",
  "Australia's Own",
  "Australia's Own Organic",
  "Australian Gold",
  "Australias Own Organic",
  "Authentic Asia",
  "Avalanche",
  "Avalanche Cafe",
  "Avalanche Kids",
  "Aviv",
  "Avo Fresh",
  "Avofresh",
  "Awaka",
  "Awesome Foursome",
  "Awesome Naturals",
  "Awesome Value",
  "Axelos",
  "Ayam",
  "Azalea",
  "B-Live",
  "B-Well",
  "B2",
  "Baby Bellies",
  "Baby Lotatoes",
  "Baby Macro",
  "Baby Mum-Mum",
  "Baby Star",
  "Baby Steps",
  "Babybel",
  "Bacon",
  "Bacon Bites",
  "Bad Baker",
  "Bagel Crisps",
  "Bagel Love",
  "Bahlsen",
  "Baileys",
  "Bake",
  "Bake Em Fresh",
  "Bake Shack",
  "Bake Works",
  "Bake'em Fresh",
  "Baked",
  "Baked Fresh",
  "Bakels",
  "Bakels Gold Label",
  "Bakemark",
  "Baker Boy",
  "Baker Boys",
  "Baker Boys Cookies",
  "Baker Halls & Co",
  "Baker Halls & Co.",
  "Baker Halls And Co",
  "Baker's",
  "Baker's Choice",
  "Baker's Finest",
  "Baker's Pantry",
  "Baker's Select",
  "Bakerhalls",
  "Bakers",
  "Bakers Provita",
  "Bakers' Choice",
  "Bakeshack",
  "Bakeworks",
  "Balconi",
  "Balducci",
  "Ballantyne",
  "Balle Bros",
  "Balocco",
  "Banana Joe",
  "Bangers",
  "Barbie",
  "BarBTui",
  "Barchelors",
  "Bard Valley",
  "Bard Valley Natural Delights",
  "Bare Bones",
  "Barill",
  "Barilla",
  "Barista Bros",
  "Barker'e",
  "Barker's",
  "Barker's of Geraldine",
  "Barkers",
  "Barkleys",
  "Baron",
  "Baronie",
  "Barr",
  "Barratt",
  "Barry's Bay",
  "Barrys Bay",
  "Basco",
  "Bassetts",
  "Bastoncini",
  "Batchelors",
  "Batchwell",
  "Batter Handmade Company",
  "Bavarian",
  "Baxter's",
  "Baxters",
  "Bay Harvest",
  "Bayer",
  "Bazaar",
  "Bazzar Breads Of The World",
  "BC",
  "Be Aloevera",
  "Be Kind",
  "Be Kind Plus",
  "Be Natural",
  "Be Natural Organics",
  "Be Natural Trail Bars",
  "Be Nourished",
  "Be Pure",
  "Be Right",
  "Be-Kind Plus",
  "Beacon",
  "Beak & Sons",
  "Beak & Sons Creations",
  "Bean Supreme",
  "Bear Naked",
  "Beard Brothers",
  "Beaver Brand",
  "Bechtle",
  "Becky's",
  "Beech's",
  "Beech's Fine Chocolate",
  "Beef Flavoured Instant Noodles",
  "Beehive",
  "Beekist",
  "Beerenberg",
  "Beerenberg Family Farm",
  "Beerenberg Pty Ltd",
  "Beet-It",
  "Bega",
  "Beghin Say",
  "Beigel",
  "Beigel Beigel",
  "Bel Normande",
  "Belgian",
  "Belgian Chocolate Original Praline",
  "Bell",
  "Bella Napoli",
  "Belladotti",
  "Bellamy's Organic",
  "Bellei",
  "Bellezza Dalla Puglia",
  "Bellich",
  "Belvior",
  "Belvita",
  "BelVita Breakfast",
  "Belvita soft bakes",
  "Belvoir Fruit Farms",
  "Ben & Jerry",
  "Ben & Jerry's",
  "Ben and Jerry's",
  "Ben Shaws",
  "Ben&Jerry's",
  "Ben's",
  "Ben's Original",
  "Bene Fiber",
  "Benedict's",
  "Benefiber",
  "Benlian Food",
  "Bennetts",
  "Bento",
  "Beqa",
  "Bergader",
  "Berhaut",
  "Berkano Foods",
  "Berker's",
  "Berocca",
  "Berocca Forward",
  "Berry Berry Nice",
  "Bertagni",
  "Bertocchi",
  "Bertolli",
  "Beryl's",
  "Best Bones Broth",
  "Best Foods",
  "Best Ugly",
  "Best Ugly Bagels",
  "Beta Bites",
  "Betta",
  "Better Crocker",
  "Betty Crocker",
  "Betty Crocker Super Moist",
  "Beulah",
  "Beulah Apple Products",
  "Beyond Meat",
  "Beyone Meat",
  "Bhana Family Farms",
  "Bhuja",
  "Bicano",
  "Bickford's",
  "Bickfords",
  "Big",
  "Big Bag",
  "Big Bean Classic",
  "Big Ben",
  "Big Ben Classic",
  "Big Paddock",
  "BigBen",
  "Bika Yeko",
  "Bikano",
  "Billington's",
  "Billingtons",
  "Biltong",
  "Biltong Bier Sticks",
  "Binggarae",
  "Binggrae",
  "Binky Bites",
  "Bintang Toedjoe",
  "Bio",
  "Bio Active",
  "Bio Eggs",
  "Bio Farm",
  "Bio Farm Organic",
  "Bio Mountain Fresh",
  "Bio Organic",
  "Bio Yoghurt",
  "Bio-Eggs",
  "BioFarm",
  "Bioglan",
  "Bioglan Healthy Living",
  "Bioglan Super Foods",
  "Bioglan SuperFoods",
  "Biologisch Organic",
  "Bionita",
  "Bird & Barrow",
  "Bird's",
  "Birds",
  "Birds E",
  "Birds Eye",
  "Birdseye",
  "Biscottificio Belli",
  "Biscovit",
  "Biscuitier Des Flanders Eugene Blond",
  "Biscuitier Des Flandres Eugene Blond",
  "Bissin",
  "Bisto",
  "Bisto Aah!",
  "Bite Me",
  "Bite Me Original Foods",
  "Bitemeat",
  "Black Classic",
  "Black Knight",
  "Blackforest Gourmet Butchery",
  "Blackmore",
  "Blackmores",
  "Blatjang",
  "Blazin Hot",
  "Bliss",
  "Bliss Balls",
  "Bliss By Serra",
  "BLN",
  "Blooker",
  "Blouse Foods",
  "Blu",
  "Blue",
  "Blue Bird",
  "Blue Coconut",
  "Blue Diamond",
  "Blue Diamond Almonds",
  "Blue Dinosaur",
  "Blue Dinosaurs",
  "Blue Dragon",
  "Blue Frog",
  "Blue Label",
  "Blue Lotus Foods",
  "Blue Ribbon",
  "Blue River",
  "Bluebell",
  "Bluebird",
  "Bluebird Delicious",
  "Bluebird Delisio",
  "Bluebirds",
  "Boca",
  "Bodega Rubs",
  "Body Trim",
  "Bodytrim",
  "Bokomo",
  "Bolero",
  "Bolletje",
  "Bolthouse Farm",
  "Bolthouse Farms",
  "Bombay Hills",
  "Bon Appetit",
  "Bon Bon",
  "Bon Vit",
  "Bonbons Lokoum",
  "Bongo",
  "Bonne Maman",
  "Bonnie",
  "Bont Snack",
  "Bonta Snack",
  "Bonvit",
  "Book Farm",
  "Boost Juice Bars",
  "Boosta",
  "Boot Leg Jerky Co",
  "Borde",
  "Borde France",
  "Border Family Biscuitiers",
  "Borg's",
  "Borges",
  "Boss",
  "Bostock",
  "Bostock Brothers",
  "Bottleg Jerky Co",
  "Bounce",
  "Bountempo",
  "Bounty",
  "Bourdin",
  "Bourgogne",
  "Bournville",
  "Boursin",
  "Bouton D'or",
  "Boutond'or",
  "Bovril",
  "Bragg",
  "Brancho's",
  "Branco's",
  "Brancos",
  "Brand Chef",
  "Brand Chef Tapas",
  "Brand Valley",
  "Branston",
  "Brazzale",
  "Bread Kind",
  "Break & Sons",
  "Breakfast",
  "Brent And Tobys",
  "Breton",
  "Brewtec",
  "Bricks",
  "Brie",
  "Brie De Meaux",
  "Brights",
  "Brink's",
  "Brink's Chicken",
  "Brink's Premium",
  "Brinks",
  "Brinks Premium",
  "Brinks Premium NZ Chicken",
  "Brioche Gourmet",
  "Bristo",
  "Brookfarm",
  "Brooks",
  "Brooks Deli",
  "Brown & Haley",
  "Brown&Haley",
  "Brownie Bites",
  "Bruns Wick",
  "Brunswick",
  "Bruschetta",
  "Bruschette",
  "Bruschette Maretti",
  "Buchette De Chevre Nostalgie",
  "Budapest",
  "Buderim Ginger",
  "Budget",
  "Budget Extra Slim",
  "Budget Standard",
  "Bufalo",
  "Buiteman",
  "Bulk Buy",
  "Bull's Eye",
  "Bull's-Eye",
  "Bull-Dog",
  "Bulla",
  "Bulla Family Dairy",
  "Bumper",
  "Bumper Bar",
  "Bumper Stuff",
  "Bundaberg",
  "Bundaberg Diet",
  "Bundasberg",
  "Bunderim Ginger",
  "Buon Tempo",
  "Buontempo",
  "Bura Valley",
  "Burgen",
  "Burgen Soy-Lin",
  "Burger Rings",
  "Burtons",
  "Burtons Biscuits",
  "Burtons British",
  "Bush Road",
  "Bushells",
  "Butchery Brinks",
  "Butchery Tony's",
  "Butler's Pantry",
  "Buttermilk",
  "Button Family",
  "Button Family The Original Honeycomb Crumpets",
  "Buzz Bar",
  "By George",
  "By Nature Food Co.",
  "C",
  "C Coconut Water",
  "Cabernet Foods",
  "Cadburry",
  "Cadbury",
  "Cadbury Brunch",
  "Cadbury Bubbly",
  "Cadbury Chocolate",
  "Cadbury Coco",
  "Cadbury Dairy Milk",
  "Cadbury Old Gold",
  "Cafe",
  "Cafe Aurora",
  "Cafe Pronto",
  "Cafe Style",
  "Cafe Time",
  "Caffe Aurora",
  "Caffe DAmore",
  "Caffe L Affare",
  "Caged Eggs",
  "Cahta Bpemop",
  "Cahta Epemop",
  "Cahta Gpemop",
  "Cahta Spemop",
  "Cake Angels",
  "Cake Mark",
  "Cakemark",
  "Calasparra",
  "Calbee",
  "Calci Strong",
  "Calci Yum",
  "Calci-yum",
  "Cali Water",
  "California Avocado Teriyaki Chicken Roll (Brown Rice)",
  "Calippo",
  "Callipo",
  "Caloless",
  "Calpis",
  "Calrose",
  "Calve",
  "Calypso",
  "Cambriland",
  "Cambriland Natural Dairy Products",
  "Camel Dates",
  "Camembert Du Bocage",
  "Camill",
  "Camille Bloch",
  "Campbell's",
  "Campbell's Cafe",
  "Campbell's Country Ladle",
  "Campbells",
  "Canada Dry",
  "Canary",
  "Candrew Corbett",
  "Candy Jewels",
  "Candy Land",
  "Candyland",
  "Candyman",
  "Cane Fields",
  "Cane Fields Natural Sweetness",
  "Canefields",
  "Canned Pork Luncheon Meat",
  "Cannonhill Gourmet",
  "Canter Valley",
  "Canter Valley Farm",
  "Canterbury Biltong",
  "Canterbury Biltong Air-Dried Beef Snacks",
  "Canton Foods",
  "Cantorel",
  "Cantuccini",
  "Cape Cookies",
  "Capelin",
  "Caprice Des Dieux",
  "CapriLac",
  "Captain's Choice",
  "Captains Choice",
  "Captan's Choice",
  "Captian's Choice",
  "Caramello",
  "Carb less crunch",
  "Carb less Deluxe",
  "Carbies",
  "Carbless",
  "Carbonell",
  "Carchelejo",
  "Carletti",
  "Carlino",
  "Carlo Crivellin",
  "Carman's",
  "Carmans",
  "Carmencita",
  "Carnation",
  "Carnival",
  "Caro",
  "Carousel",
  "Carousel Classics",
  "Carr's",
  "Cartel Food Co",
  "Casa Di Oliva",
  "Casa Fresca",
  "Casa Rinaldi",
  "Casademont",
  "Casbah",
  "Casbah Natural Foods",
  "Caseificio Albiero",
  "Casino CA VIENT d'ICI",
  "Castello",
  "Castello Estence",
  "Castello Estense",
  "Castillo De Bayren",
  "Cathedral Cove",
  "Cathedral Cove Macadamias",
  "Cathedral Cove Naturals",
  "Cavendish",
  "Cavendish & Harvey",
  "Cavendish And Harvey",
  "Cavendish Farms",
  "Cavi Art",
  "Cavi-Art",
  "Caviart",
  "Cebu",
  "Cec's",
  "Cecile's",
  "Cecs",
  "Celebrate Health",
  "Celebrate with Hersheys",
  "Celebrating Spring Festival",
  "Celement Faugier",
  "Celsea",
  "Cemoi",
  "Center Valley",
  "Cerbos",
  "Cerebos",
  "Cerebos Gregg's",
  "Cerebs",
  "Ceres",
  "Ceres Naturals",
  "Ceres Organic",
  "Ceres Organiccs",
  "Ceres Organics",
  "Ceres Organics Cacao",
  "CeresOrganics",
  "Ch'i",
  "Ch'i Herbal Rejuvenation Sparkling Refresher",
  "Ch'l",
  "Cha",
  "Cha Cha",
  "Cha Soba",
  "Chacha",
  "Chacheer",
  "Chai Gold",
  "Chajang Mayun",
  "Chalmers Organics LTD",
  "Chaloner",
  "Chamdor",
  "Champignon",
  "Champiland",
  "Champion",
  "Chan's Yum Cha",
  "Chang's",
  "Changs",
  "Chantal",
  "Chantal Natural",
  "Chantal Naturals",
  "Chantal Organics",
  "Chanui",
  "Chanvi",
  "Chaokoh",
  "Charlie's",
  "Charlie's Honest Pressed",
  "Charlies",
  "Charmaine Solomon's",
  "Charmaine Solomons",
  "Charoen Pokphand",
  "Chasers",
  "Chaumes",
  "Chavroux",
  "Check",
  "Cheerios",
  "Cheese Puffs",
  "Cheese Ups",
  "Cheestik Wedges",
  "Cheesy Bacon Style Mac",
  "Cheetos",
  "Cheezels",
  "Chef De Can",
  "Chef Del Mare",
  "Chef Series",
  "Chef Series Everyday",
  "Chef Series Josh Emett",
  "Chef's World",
  "Chefade",
  "Chefs Pride",
  "Chefs World",
  "Chelsa",
  "Chelsea",
  "Chelsea Golden Syrup",
  "Chelsea Natural Cane Sugar",
  "Cherry Ripe",
  "Chesdale",
  "Chevedon Buffalo Co.",
  "Chi",
  "Chi Herbal",
  "Chia",
  "Chia Sisters",
  "Chicken Tonight",
  "Children's Cookies",
  "Chillax",
  "Chilli Brand",
  "Chiostro Di Saronno",
  "Chiostro Di Saronno Specialita",
  "Chip of the Old Block",
  "Chip Off The Old Block",
  "Chiquilin",
  "Chit Chat",
  "Cho Cho",
  "Choc Bar",
  "Choc Chew",
  "Choc Chip",
  "Choc Chop",
  "Chocao",
  "Chocmod Truffettes De France",
  "Chocoland",
  "Chocolate Traders",
  "Chocolatier",
  "Chocolatte Erasmi",
  "Choice",
  "Chokito",
  "Cholula",
  "Cholula Hot Sauce",
  "Chongga",
  "Chonvi",
  "Chop Chop",
  "Chop Chop !",
  "Chop Chop!",
  "Chris'",
  "Chrystal Fresh",
  "Chuka",
  "Chung Jung One",
  "Chunky Pasta Sauce With Tomato & Herbs",
  "Chupa Chups",
  "Ciao Italia",
  "Cicano",
  "Cinderella",
  "Cinderella Baking",
  "Cinderella Better Bakes",
  "Cinderella Snacking",
  "Ciocco Belli",
  "Cioccobelli",
  "Ciresa Formaggi",
  "Citrus Tree",
  "CJ",
  "Cj Foods",
  "Classic",
  "Classic Cakes",
  "Classic Cola",
  "Classic Fresh Up",
  "Classic India",
  "Classic Indian",
  "Clawson",
  "Clean Paleo",
  "Cleanmixes",
  "CleanPaleo",
  "Clear Water Farm",
  "Clear Water's",
  "Clearly Premium",
  "Clearwater Farm",
  "Clearwater's",
  "Clearwater's The Organic Dairy Co.",
  "Clearwaters",
  "Clement Faugier",
  "Clevedon Buffalo",
  "Clevedon Buffalo Co",
  "Clevedon Coast",
  "Clevedon Valley",
  "Clevedon Valley Buffalo",
  "Clevedon Valley Buffalo Company",
  "Cleveland",
  "Clif",
  "Clif Bar",
  "Clif Builders",
  "Cliff Bar",
  "Cloudy Bay Clams",
  "Cloudy Bay Shells",
  "Cloudy Bays Clams",
  "Club Ramen",
  "Cluster Crisp",
  "Co Co Mo",
  "Co Yo",
  "Coaqua",
  "Coast Grown",
  "Cobram Estate",
  "Cobs",
  "Coca Cola",
  "Coca-Coca",
  "Coca-Cola",
  "Coca-Cola Diet",
  "Coca-Cola Life",
  "Coca-Cola no sugar",
  "Coca-Cola Trading Company",
  "Coca-Cola Zero",
  "CocaCola",
  "Cocavo",
  "Coco Coast",
  "Coco Crunchies",
  "Coco Fuzion 100",
  "Coco More",
  "Coco pops",
  "Coco Water",
  "Coco Wow",
  "Coco-Cola",
  "Cocoa-Cola",
  "CocoCoast",
  "Cocoespresso",
  "Cocofuel",
  "Cocomo",
  "Coconial",
  "Coconut Collective",
  "Coconut Dream",
  "Coconut Juicies",
  "Coconut Slab",
  "Coconut Smiles",
  "Cocowow",
  "Coen Bros",
  "Coeur De Lion",
  "Coffee Whitener",
  "Cofresh",
  "Coke",
  "Coke Classic",
  "Coke Zero",
  "Colby",
  "Cold Brew",
  "Cole's",
  "Coliseum",
  "Colman's",
  "Colman's Norwich",
  "Colman's of Norwich",
  "Colmans",
  "Colomba",
  "Colonial",
  "Colonial Bacon & Ham",
  "Colonna",
  "Colonna Arancio",
  "Combe Castle",
  "Complan",
  "Complete Kids",
  "Comvita",
  "Condimentum",
  "Condimentum Hawke's Bay",
  "Confetteria",
  "Connect Foods",
  "Conserverie Quatrefages",
  "Constantia",
  "Continental",
  "Continental Cup-A-Soup",
  "Continental Pasta & Sauce Gourmet",
  "Conutz",
  "Cookie Bake",
  "Cookie Bear",
  "Cookie Dough",
  "Cookie Dough Bites",
  "Cookie Dough Treats",
  "Cookie Time",
  "Cookie Time Limited",
  "Cool Ridge",
  "Coolit",
  "Coombe Castle",
  "Cooper Kettle",
  "Coopers",
  "Coopers Diy Beer",
  "Coopers Lager",
  "Copper Kettle",
  "Copper Kettle Chips",
  "Copper Pot",
  "Coral Colours",
  "Coraltree",
  "Corazon",
  "Corazon Del Sol",
  "Corbies",
  "Corn Chips",
  "Corn Thins",
  "Corner Dairy By Kohu Road",
  "Cornetto",
  "Cortas",
  "Coshin",
  "Coshin Soy Crunch",
  "Costa",
  "Cotswolds",
  "Cottage Delight",
  "Cottage Lane",
  "Cottage Lane Tortilla",
  "Cottee's",
  "Cottees",
  "Cotterill & Rouse",
  "Cottrells",
  "Coulston Hill",
  "Coundown",
  "Coundtown Value",
  "Countdown",
  "Countdown Essentials",
  "Countdown Homebrand",
  "Countdown Kitchen",
  "Countdown Select",
  "Countdown Value",
  "Countdown Woolworths",
  "Country Choice",
  "Country Crumb",
  "Country Culinaire",
  "Country Goodnes",
  "Country Goodness",
  "Country Gulinaire",
  "Country Pride",
  "Country Soft",
  "Country Split",
  "Country Style Small Goods",
  "Country Taste",
  "Country Taste Small Goods",
  "Country Taste Small Goodsz",
  "Countrycrumb",
  "Couscous",
  "Covet",
  "Cowala",
  "Cowell's",
  "Cowell's Genuine Meringue",
  "Cowell's Genuine Pavlova",
  "Cowell's Meringue",
  "Cowell's Meringue Genuine",
  "Cowell's Pavlova",
  "Cowell's Pavlova Kitchens",
  "Cowells",
  "Coyo",
  "Coyo Organic",
  "CP",
  "Cracker Jack",
  "Craddoch Family Farm",
  "Craddock Family Farm",
  "Craft",
  "Craft Farmers",
  "Craft Farmers Co-op",
  "Craft Farmers' Co-Op",
  "Craig's",
  "Craigs",
  "Craisins",
  "Cranberries Westland",
  "Cranberry Westland",
  "Cranbrie",
  "Crankt",
  "Crave Food",
  "Cravers",
  "Crawford's",
  "Crawfords",
  "Create A Treat",
  "Creative Kitchen",
  "Creme De La Creme",
  "Crespo",
  "Cresta Mushrooms",
  "Crispix",
  "Crofters",
  "Croisilles",
  "Crosse & Blackwell",
  "Crosse Blackwell",
  "Crow About",
  "Crown",
  "Crown Brand",
  "Cruisin Cashews",
  "Cruncheese",
  "Crunchie",
  "Crunchy nut corn flakes",
  "Cruskits",
  "Crystal",
  "Cuis Ann",
  "Cuisine Scene",
  "Cuisinescene",
  "Cuisinescene New Zealand",
  "Culley's",
  "Culley's Kitchen",
  "Culley's No 4",
  "Culture",
  "Cup Noodles",
  "Curly Wurly",
  "Cyclops",
  "Cyclops Organic",
  "Cyclopus",
  "Cyrstal",
  "D & P",
  "D'aucy",
  "D'Lite Food",
  "D.E Moccona",
  "D.Y.C",
  "Da Maha",
  "Da Vinci's",
  "Da Vinci's Pizza",
  "Dad's Pies",
  "Daesang",
  "Daily Gourments",
  "Daily Gourmet",
  "Daily Organics",
  "Daim",
  "Dainty",
  "Dairy Free",
  "Dairy Land",
  "Dairy Milk",
  "Dairy Superfood Shake",
  "Dairy Whip",
  "Dairy Works",
  "Dairyland",
  "Dairylea",
  "Dairymaid",
  "Dairyworks",
  "Daisy",
  "Daisy Chain",
  "Dakshin's",
  "Dakshin's Snack To Go",
  "Dakshins",
  "Dali",
  "Dallari",
  "Damascus",
  "Dan Cake",
  "Dancing Elephants",
  "Danesita",
  "Danny's",
  "Danovans",
  "Dansti Seafoods",
  "Danvita",
  "Dany",
  "Darell Lea",
  "Dark Maltesers",
  "Dark Milk",
  "Darrell Lea",
  "Darrrell Lea",
  "Dash",
  "Dashboard Diner",
  "Date Crown",
  "Davincis",
  "Davis",
  "Davis Gelatine",
  "Davoy's",
  "Davoy's New York Bagels",
  "Db",
  "De Bron",
  "De Brood Bakery",
  "De Cecco",
  "De Gustibus",
  "De Ruiter",
  "De Ruiter's Bakkerij",
  "De Ruiter's Banket",
  "De Winkel",
  "De-Lite Foods",
  "Dean's",
  "Decor",
  "Decor Butter Cream",
  "Decor Cake",
  "Decor Cakes",
  "Decor Cakes Ltd",
  "Decor Celebration Cake",
  "Decor Chocolate Fantasy",
  "Deep Cove",
  "Deep South",
  "Deep Spring",
  "Deep Spring Naturals",
  "Del Monte",
  "Delamere Dairy",
  "Delamine Fine Foods",
  "Delba's",
  "Delbo's",
  "Delbos",
  "Deli Cut",
  "Deli Free",
  "Deli Free Divella",
  "Deli Menu",
  "Deli Tasty",
  "Delicato",
  "Delice D' Argental",
  "Delice D'Argental",
  "Delicious Blueberries",
  "Delish",
  "Delisio",
  "Delites",
  "Dellos",
  "Delmaine",
  "Delmaine Deli",
  "Delmaine Fine",
  "Delmaine Fine Foods",
  "Delmine Fine Foods",
  "Delpeyrat",
  "Delverde",
  "Demon",
  "Demon Energy",
  "Denheath",
  "Derlen",
  "Derlen Bakery",
  "Deroni",
  "deSIAM",
  "Despicable Me",
  "Detpak",
  "Deulah",
  "Devon",
  "Devon's",
  "Devondale",
  "Devonport Chocolates",
  "Devour",
  "Dextro",
  "Dextro Energy",
  "Di Carlo",
  "Diamond",
  "Diego's",
  "Diet Coca-Cola",
  "Diet Coke",
  "Diet Lift",
  "Dietex",
  "Dig Me",
  "Diggits",
  "Dinner Rush",
  "Diplomats",
  "Diron",
  "Diron Global Cuisine",
  "Dirone Global Cuisine",
  "Divella",
  "Divinity",
  "Diy Beer",
  "DJ&A",
  "Dlite Food",
  "Doctor's Choice",
  "Doctors Choice",
  "Dodoni",
  "Doen Jang",
  "Dolce",
  "Dolce Gusto",
  "Dolce Pasion",
  "Dolci Frutta",
  "Dolcificio Veneto",
  "Dole",
  "Dole Snackers",
  "Dolee Pasion",
  "Doll",
  "Doll Bowl Noodle",
  "Dollar Delights",
  "Dollar Sweets",
  "Dollarsweets",
  "Dollop",
  "Dolmino",
  "Dolmio",
  "Don Depino",
  "Don Peping",
  "Don Pepino",
  "Donovan's",
  "Donovans",
  "Donovans NZ Chocolate",
  "Doongara",
  "Doritos",
  "Dorset Drum",
  "Dose & Co.",
  "Dot's",
  "Double 'D'",
  "Double D",
  "Double Decker",
  "Double Pagoda",
  "Double Phoenix",
  "Double Rings Brand",
  "Double'D",
  "Double'D'",
  "Dovedale",
  "Dr Bugs",
  "Dr Feel Good",
  "Dr Pepper",
  "Dr. Feelgood",
  "Dr. Oetker",
  "Dr. Pepper",
  "Dr.Feelgood",
  "Dr.Oetker",
  "Dragon & Phoenix",
  "Dragon And Phoenix",
  "Dragon Food",
  "Dragon Foods",
  "Dragon Sushi",
  "Dream",
  "Dressing",
  "Dried Fresh",
  "Dried Fruit Pots",
  "Drink No Evil",
  "Drivers",
  "Du Chocolat",
  "Du Puy",
  "Duc d'O",
  "Duck Island",
  "Due North",
  "Duke's",
  "Duncan Hines",
  "Durello",
  "Dutch Bakery",
  "E Fresco",
  "E Fresso",
  "E Square",
  "E'stel",
  "E2",
  "Earnest Adams",
  "Easio",
  "Easiro",
  "Easiyo",
  "East Bali Cashews",
  "East Dragon",
  "East Emperial",
  "East Imperial",
  "East Imprerial",
  "East Milk",
  "East Mill",
  "Easter Product",
  "Easter Show",
  "Eastern Dragon",
  "Eastern Dragon (NZ) Ltd",
  "Easteron Dragon",
  "Easy Bakers",
  "Easy Fruit",
  "Easy Life",
  "Easy Vege",
  "Eat",
  "Eat Art Living Food",
  "Eat My Dinner",
  "Eat Real",
  "Eat...",
  "Eaty",
  "Ecco",
  "Eclipse",
  "Eclipse Cheese",
  "Eclipse Premium",
  "Ecly",
  "ECLY Importers",
  "Eco Eggs",
  "Eco Organics",
  "Eco-Eggs",
  "Ecology + Co",
  "Ecuagold",
  "Eden Orchards",
  "Edgell",
  "Edglets",
  "Edmond Fallot",
  "Edmonds",
  "Edmonds Classic Recipes",
  "Edmonds Sure To Rise",
  "Efresco",
  "Egell",
  "Egmont Honey",
  "EJ Foods",
  "El Mexicano",
  "El Navarrico",
  "El Pastor",
  "El Pastor De La Polvorosa",
  "El Rancho",
  "El Yucateco",
  "Elit",
  "Elite Food Group",
  "Elite Food Group Ltd",
  "Elite Group Ltd",
  "Elizabeth Shaw",
  "Elody's",
  "Elysian",
  "Elysian Foods",
  "EM's",
  "Em's Power Cookies",
  "Embasa",
  "Emborg",
  "Emma",
  "Emma Jane's",
  "Emma Janes",
  "Emma-Jane's",
  "Empire",
  "Ems Power Cookie",
  "Endeavour",
  "Enduro R 4",
  "Endurox R 4",
  "English Sausages",
  "Enliven",
  "Enoteca Sileno",
  "Enso",
  "Entube",
  "Envirokidz",
  "Epoisses AOP",
  "Epsoisses Berthaut",
  "Equa Gold",
  "EquaGold",
  "Equal",
  "Equal Plus",
  "Erawan",
  "Erawan Brand",
  "Ernest Adames",
  "Ernest Adams",
  "Ernest Hillier's",
  "Ernst Adams",
  "Ersu",
  "Erwan Brand",
  "Escal",
  "Escuminac",
  "Esgir",
  "Eskal",
  "Eskimo Pie",
  "Eskimo's",
  "Essentials",
  "Est San'j",
  "Eta",
  "Eta Cheeseballs",
  "Eta Good Bites",
  "Eto",
  "Etoile Du Vercors",
  "Eugene Blond",
  "Eurea Coop",
  "Eureka",
  "Euro Deli",
  "Euro Patisserie",
  "Euro Pomella",
  "Euro Shopper",
  "Euro Spices",
  "Euro-Deli",
  "Euro-Dell",
  "Europe",
  "European Foods",
  "European Gourmet Glaze",
  "European Gourmet Stock",
  "European Swisse",
  "Europomella",
  "Eva",
  "Every Day",
  "Every Day By Peter Gordon",
  "Every Time",
  "Everybody's",
  "Everybody's Kombucha",
  "Everyday Bagels",
  "Everyday Entertainer",
  "Evil Child Beverage Co",
  "Evil Child Beverage Co.",
  "Exotic",
  "Exotic Coco Water",
  "Exotic Food",
  "Exotic Goji Water",
  "Explore Asian",
  "Explore Asian Authentic Cuisine",
  "Explore Cuisine",
  "Ezi Pop",
  "Ezipop",
  "F&N",
  "F. Whitlock",
  "F. Whitlock & Sons",
  "F. Whitlock and Sons",
  "F.Whitlock & Sons",
  "Fabrique En Aveyron",
  "Fabrique En France",
  "Fabrique En Normandie",
  "Fabulicious",
  "Fally Williams",
  "Falwasser",
  "Family Choice",
  "Family Joy",
  "Fangks",
  "Fanstastic",
  "Fanta",
  "Fantastic",
  "Fantastic Delite",
  "Fantastic Delites",
  "Fantastic Thinner Bite",
  "Farex",
  "Farina Dallari",
  "Farley's",
  "Farm Land",
  "Farmbake",
  "Farmer Bill's",
  "Farmer Bills",
  "Farmer Brown",
  "Farmer Jo",
  "Farmer's Bill",
  "Farmers",
  "Farmers Bill's",
  "Farmers Mill",
  "Farmland",
  "Farmland continental",
  "Farmland Foods",
  "Farrah Wraps",
  "Farrah's",
  "Farrow's",
  "Farrows",
  "Farrows Best Peas",
  "Fast & Fruity Pourable Chunky Fruit",
  "Fast And Fruity",
  "Fastastic",
  "Fattorie Umbre",
  "Faugier Clement",
  "Favourites",
  "Fclement Faugier",
  "Fdi",
  "Fehlbergs",
  "Feijoa",
  "Fentimans",
  "Fern Baby",
  "Fernbaby",
  "Ferndale",
  "Fernz",
  "Ferrero",
  "Ferrero Collection",
  "Ferrero Rocher",
  "Ferron",
  "Ferry's",
  "Festival Relish",
  "Fever Tree",
  "Fever-Tree",
  "Fia Fia",
  "Fibre One",
  "Fibre One 90 Calorie",
  "Fiddes Payne",
  "Fiji",
  "Filippo Berio",
  "Filo Ettes",
  "Final Whistle",
  "Final Whistle!",
  "Findlay Foods",
  "Fine European Makers",
  "Fine Fare",
  "Fine Food",
  "Fine Food Holdings",
  "Fine Foods",
  "Finest Foods",
  "Fini",
  "Finn's",
  "First Light",
  "Firstlight",
  "Fish 4 Ever",
  "Fisherking",
  "Fisherman's Friend",
  "Fitness",
  "Five",
  "Five Brother's",
  "Five Brothers",
  "Five Stars Elephant",
  "Five Tastes",
  "Fix & Fogg",
  "Fizzed e2",
  "Fizzi",
  "Fizzy Fruit Burst",
  "Flake",
  "Flame",
  "Flemings",
  "Flora",
  "Flora Plant",
  "Floradix",
  "Florida's Natural",
  "Floridas Natural",
  "Flott",
  "Flott I Marinati",
  "Fluffies",
  "Flying Goose",
  "Flying Goose Brand",
  "Fmf",
  "FMF Brand",
  "Fodmapped",
  "Fodmapped For You",
  "Fodmapped For You!",
  "Fog Dog",
  "Fogdog",
  "Fol Epi",
  "Follow Your Heart",
  "Fonards",
  "Fonterra",
  "Food Brothers",
  "Food by Chefs",
  "Food Chefs",
  "Food for Health",
  "Food is Fuel",
  "Food Nation",
  "Food Snob",
  "Foodies",
  "Foodies For Food Lovers",
  "Foods Pacific",
  "Foodstuffs",
  "Foodwise",
  "For Every One",
  "For Everyone",
  "For The Love Of Tams",
  "Forbidden",
  "Forbidden Fruit",
  "Formaggi Ciresa",
  "Formaggini",
  "Forno Bonomi",
  "Forno d Angelo",
  "Forno D'Angelo",
  "Forty Thieves",
  "Forum",
  "Forvm",
  "Forvm Cab. Sauvignon",
  "Forvm Chardonnay",
  "Foster Clark's",
  "Foundation Foods",
  "Four'N Twenty",
  "Fox's",
  "Fragata",
  "Fragata Snacking",
  "Fragato",
  "Franjos Kitchen",
  "Frank",
  "Frank Cooper's",
  "Frank Coopers Oxford",
  "Frank Copper's Oxford",
  "Frank's",
  "Frank's Junior",
  "Frank's Sausages",
  "Franklin Foods",
  "Franklins",
  "Fray Bentos",
  "Freckels",
  "Freckles",
  "Freddi",
  "Freddo",
  "Free From Gluten",
  "Free Pops",
  "Freedom",
  "Freedom Fans",
  "Freedom Farms",
  "Freedom Foods",
  "Freefod",
  "Freelicious",
  "Freeze Pops",
  "freh'n fruity",
  "French",
  "French's",
  "Frenz",
  "Frenzy Foods",
  "Fresco",
  "Fresco Nutrition",
  "Fresh",
  "Fresh 'N Fruity",
  "Fresh As",
  "Fresh Bakery Products",
  "Fresh Bite",
  "Fresh Bread",
  "Fresh Fields",
  "Fresh Harvest",
  "Fresh Life",
  "Fresh Life (Australia)",
  "Fresh N Fruity",
  "Fresh N Fruity Lite",
  "Fresh Nuts",
  "Fresh Plus",
  "Fresh Products",
  "Fresh Slaw",
  "Fresh STirfry",
  "Fresh To Go",
  "Fresh Up",
  "Fresh Valley",
  "Fresh Zone",
  "Fresh'n Fruity",
  "Fresh'NFruity",
  "Fresh-Up",
  "Fresha Valley",
  "Fresher",
  "Fresher Potato Chips",
  "Freshers",
  "Freshfields",
  "FreshLife",
  "Freshly Made Today",
  "Freshpak",
  "Freshplus",
  "FreshUp",
  "Freshzone",
  "Fressure",
  "Fressure Foods",
  "Frey",
  "Freya's",
  "Freya's European Style Bread",
  "Freya's Family",
  "Freyas",
  "Friendship",
  "Fritos",
  "Fromagerie Milleret",
  "Fromagers En Bourgogne",
  "Fromi",
  "Fromi Gres D'Alsace",
  "Froot Loops",
  "Frooze Balls",
  "Froze Balls",
  "Fru-Ju",
  "Frubites",
  "Frucor",
  "Fruit + Vege",
  "Fruit Blast",
  "Fruit Burst",
  "Fruit For Life",
  "Fruit Harvest",
  "Fruit Hitz",
  "Fruit Noodles",
  "Fruit O",
  "Fruit Squirtz",
  "Fruit Tails",
  "Fruit Tales",
  "Fruit-O!",
  "Fruit-Tella",
  "Fruiti",
  "Fruitsies",
  "Frut-o",
  "Frutee",
  "Fruto",
  "Fruzio",
  "Fry Family Food Co.",
  "Fry's",
  "Fry's Family",
  "Frys",
  "Frys Family",
  "Frys Vegetarian",
  "Fudgetastic",
  "Fujisho",
  "Fujisho Asian",
  "Fukuizumi",
  "Fukuizumi Sangyo",
  "Fulfil",
  "Full Green",
  "Full of Beanz",
  "Fullo' Grain",
  "Fun Foods For All Ltd",
  "Fun to Share",
  "Fundation Foods",
  "Furniss",
  "Fusian",
  "Future Cuisine",
  "Fuze Tea",
  "Fyna",
  "Fynbos",
  "Fynbos Fine Foods",
  "G Force",
  "G' Nature",
  "G'Nature",
  "Gadbury",
  "Gaea",
  "Galaxy",
  "Galettes",
  "Galettes De Riz",
  "Gardein",
  "Garden of Earth",
  "Garden Of Eatin",
  "Garden of Eatin'",
  "Garlic BBQ Sauce",
  "Garo Falo",
  "Garofalo",
  "Gastone Lago",
  "Gastone Lago Elledi",
  "Gateaux",
  "Gathered Game",
  "Gatorade",
  "Gault's",
  "Gaults",
  "Gaytime",
  "Gears of War Imulsion",
  "Gee Vee",
  "Geevee",
  "Gem",
  "Gem Escap Meals",
  "Gem Escape",
  "Gem Escape Meals",
  "Gen-ji-Mai",
  "General Mills",
  "Geneva",
  "Genevieve Cusine",
  "Genevieve's",
  "Genevieves Cuisine",
  "Genmai",
  "Genmai Tea",
  "Genmaicha",
  "Genoese",
  "Genoese Foods",
  "Genuine Fogdog",
  "Geo Watkins",
  "George",
  "George & Jo's",
  "Geowatkins",
  "Gerry's",
  "Gerry's Wraps",
  "Gerrys Wraps",
  "Get Natural",
  "Get Real Food",
  "Get Real For Kids",
  "GF Treets",
  "GForce",
  "Gftreets",
  "Ghiotti",
  "Ghitto",
  "Gia",
  "Gianni Calogiuri",
  "Gianni Negrini",
  "Giannis",
  "Giant",
  "Giant Panda",
  "Gibbston",
  "Gibbston Valley Cheese",
  "Gigante",
  "Ginelli's Gelato Perfetto",
  "Ginelli's Sorbeto Perfetto",
  "Ginellis",
  "Ginellis Gelato Perfetto",
  "Ginellis Sorbeto Perfetto",
  "Ginger Fresh",
  "Gingerella",
  "Ginggrae",
  "Girolomoni",
  "Giuseppe Giusti",
  "Giuseppe Giusti Modena",
  "Giusti",
  "Giusti Dal",
  "Glaceau",
  "Glaceau Vitamin Water",
  "Glaceau Vitaminwater",
  "Glacia",
  "Glasseye Creek",
  "Glenpark Woodland",
  "Glenrowen Biscuit Co.",
  "Glica",
  "Glico",
  "Global",
  "Global Cuisine",
  "Global Products",
  "Global Village",
  "Gloriously Free",
  "Glory",
  "Gluten Free Choice",
  "Gluten Free Goodies Company",
  "Gluten Free Purebread",
  "Gluten Free Store",
  "Gluten Free Store Ltd",
  "Gluten Free Storee Ltd",
  "Gluten Freedom",
  "Gnaw",
  "Gnaw Chocolate Chompers",
  "Go Bake",
  "Go Breakfast",
  "Go Lightly",
  "Go Milk",
  "Go Native",
  "Go Natural",
  "Go Nuts",
  "Go Nutz",
  "Goan Cuisine",
  "Gobake",
  "Gobbie",
  "Gobble",
  "Gochu Jang",
  "Gohar Saffron",
  "Going Donuts",
  "Gold",
  "Gold Pearl",
  "Gold Pearl Dong Guan",
  "Gold Plum",
  "Gold'n Canola",
  "Golden",
  "Golden Bakery",
  "Golden Bridge",
  "Golden Chef",
  "Golden Circle",
  "Golden Country",
  "Golden Days",
  "Golden Downs",
  "Golden Fern",
  "Golden Fresh",
  "Golden Gate",
  "Golden Juice",
  "Golden Pasta",
  "Golden Roasted",
  "Golden Sun",
  "Goldenfry",
  "Gong",
  "Gonutz",
  "Gonutz Foods",
  "Gonutz Foods Ltd",
  "Gonutz Lunch Box Snacks",
  "Good 'n Tasty Pies",
  "Good Boost Co",
  "Good Boost Co.",
  "Good Buzz",
  "Good Buzz Kombucha",
  "Good Cocktail Co.",
  "Good Food NZ",
  "Good Grab",
  "Good Hemp",
  "Good Morning Juice",
  "Good N Tasty Pies",
  "Good Night",
  "Good Shot",
  "GoodBoost Co.",
  "GoodFood Nz",
  "Goodies Company",
  "Goodman Fielder",
  "Goodness Grains",
  "Goodness Kitchen",
  "Goodness Me",
  "Goodness Superfoods",
  "GoodnessMe",
  "Gopala",
  "Gopala Taste of Goodness",
  "Gore Gruyere",
  "Goulburn Valley",
  "Goulter's",
  "Gourmet",
  "Gourmet Brands NZ Ltd",
  "Gourmet Food Specialists",
  "Gourmet Food Specialities",
  "Gourmet Food Specialties",
  "Gourmet Fresh",
  "Gourmet Garden",
  "Gourmet Guru",
  "Gourmet Konex Foods",
  "Gourmet Pate",
  "Gourmet Pizza",
  "Gourmet Toppings",
  "Grain Health Foods",
  "Grain Healtth Foods",
  "Grain Products",
  "Grain Waves",
  "Grainwaves",
  "Gran Deposito",
  "Gran Moravia",
  "Gran Valle",
  "Grana Padano",
  "Grand Jury",
  "Grandma Wild's",
  "Grandos",
  "Grandpa's",
  "Grandpa's Meat & Bacon Co",
  "Grandpa's Meat & Bacon Co.",
  "Grandpas",
  "Granny Delicious",
  "Granny Faye's",
  "Granny Fayes",
  "Grant's",
  "Grapetiser",
  "Graphite",
  "Grate Britain",
  "Grated Parmesan",
  "Gravy Browing",
  "Graze",
  "Graze Good Snacks",
  "Graze Good Snacks By Cinderella",
  "Graze Good-Snacks",
  "Greater Good",
  "Greater Hummus",
  "Greater!",
  "Greater! Hummmus",
  "Greegg's",
  "Green & Black's",
  "Green & Black's Organic",
  "Green & Blacks",
  "Green & Blacks Organic",
  "Green & Co",
  "Green and Blacks",
  "Green Bottle",
  "Green Meadows",
  "Green Monkey",
  "Green's",
  "Greenleaf Organics",
  "Greens",
  "Greenseas",
  "Greenways",
  "Gregg's",
  "Gregg's Cafe Gold",
  "Greggs",
  "Grenade",
  "Gretel's",
  "Gretel's Gingerbread",
  "Gretel's Nutty",
  "Grifffin's",
  "Griffin's",
  "Griffin's Snax",
  "Griffin's Sultana Pasties Rich and Fruity Delight",
  "Griffins",
  "Grillsteins",
  "Grinning Gecko Cheese Co",
  "Grissol",
  "Grissol Boulangerie",
  "Grove",
  "Grove Nothing But",
  "Grove Squeezed",
  "Growing Naturals",
  "GU",
  "Gu 2",
  "Gu Desserts",
  "Guandy",
  "Guiseppe Giusti",
  "Gullon",
  "Gusto",
  "Gusto Antico",
  "Guy Lian",
  "Guylian",
  "G√º",
  "H S New Zealand Pty Ltd",
  "H2 Coco",
  "H2Coco",
  "H2Go",
  "H2Go Zero",
  "Haagen-Dazs",
  "Hak",
  "Haku",
  "Hakubaku",
  "Halal",
  "Halfords",
  "Halloren",
  "Halloumi",
  "Halo Top",
  "Ham",
  "Hammer Head Foods",
  "Hammerhead Foods",
  "Hamony",
  "Hanabi",
  "Hand Crafted",
  "Handcrafted",
  "Handcrafted by Loaf",
  "Handmade Turkish",
  "Handmade Turkish Bread",
  "Hangsu Heng Shun",
  "Hansells",
  "Hansells Food Group",
  "Hansells Vitafresh",
  "Hansen",
  "Hantz",
  "Hantz Honey",
  "Happy Bee",
  "Happy Belly",
  "Happy Valley",
  "Haproon Cold Brew Coffee",
  "Har Roox",
  "Har Rooz Basmati Rice",
  "Harbour Barn",
  "Haribo",
  "Haribo Mega-Roulette",
  "Haribo Turtles",
  "Harissa",
  "Harmel Foods",
  "Harmless Food Co",
  "Harmony",
  "Harpoon",
  "harpoon Cold Brew",
  "Harraways",
  "Harraways Oat Singles",
  "Harraways Oats",
  "Harraways Scotch Oats",
  "Harrington's",
  "Harris Stock",
  "Hart & Soul",
  "Harvest",
  "Harvest Natural Goodness",
  "Harvest Snaps",
  "Havarti",
  "Haven",
  "Haverland",
  "Hawke's Bay The Apple Press",
  "Hazer Baba",
  "Hazerbaba",
  "Health Culture",
  "Health Discovery",
  "Health Discovery Super Food",
  "Health Food Co",
  "Health Lab",
  "Health Lab Kids",
  "Health Life",
  "Health n Fresh",
  "Healtheries",
  "Healtheries Bircher",
  "Healtheries Kids Care",
  "Healtheries Kidscare",
  "Healtheries Naturally slim",
  "Healtheries Simple",
  "Healthy And Fresh",
  "Healthy Choice",
  "Healthy Fresh",
  "Healthy n Fresh",
  "Healthy Rice",
  "Heards",
  "Heartfield",
  "Heartland",
  "Heartland Potato Chips",
  "Heat & Eat",
  "Heaven and Earth",
  "Heaven Sent",
  "Heavensent",
  "Heidi",
  "Heilala",
  "Heilala Vanilla",
  "Heinz",
  "Heinz Beanz",
  "Heinz Little Kids",
  "Heinz Nurture",
  "Heinz Nurture Cold+",
  "Heinz Organic",
  "Heinz Seriously Good",
  "Heinz [Seriously] Good",
  "Hellaby",
  "Hellaby's",
  "Hellaby's Brand",
  "Hellabys",
  "Hellama",
  "Hellema",
  "Heller's",
  "Hellers",
  "Hellers Craft",
  "Hellers N's Butcher",
  "Hellers Nz Butcher",
  "Hellers NZ's Butcher",
  "Hellers NZs Butcher",
  "Hellmann's",
  "Hellmanns",
  "Hello",
  "Hello Gourmet",
  "Hello Keto",
  "Hello Kitty",
  "Hello Panda",
  "Hello Raw",
  "Hello Seafood Salads",
  "Help Support Uncaged Hens",
  "Hemp Oz",
  "Hen's Choice",
  "Henaff",
  "Henderson's",
  "Henergy",
  "Henergy Cage - Free",
  "Henergy Cage Free",
  "Heng Shun",
  "Hengshun",
  "Henry's Poultry",
  "herbert ad",
  "Herbert Adams",
  "Herdez",
  "Heritage",
  "Heritage Delight",
  "Heron's Flight",
  "Hershey's",
  "Hershey's Hugs",
  "Hershey's Kisses",
  "Hersheys",
  "Heyden Farms",
  "Hi",
  "Hi Tempura",
  "Hi-Chew",
  "Higgins",
  "High Mark",
  "High Mark Family Reserve",
  "High Protein Breakfast Company",
  "Higher Living",
  "Highlander",
  "Highmark",
  "Hikari",
  "Hikari Miso",
  "Hill",
  "Hill St",
  "Hill St Bases",
  "Hillary",
  "Hillier's",
  "Hilliers",
  "Hiltona",
  "Himalaya Harvest",
  "Himalayan Harvest",
  "Hipi",
  "Hirkari",
  "Hiyashi Chuka",
  "hnz",
  "HNZ New Zealand",
  "Ho Mai Yum Cha",
  "Ho Mai Yum Cha Appetisers",
  "Hobson's",
  "Hobson's Choice",
  "Hobson's Cured & Smoked Meats",
  "Hobsons Choice",
  "Hoco",
  "Hogarth",
  "Hogarth Chocolate",
  "Hogarth Chocolate Makers",
  "Hohepa",
  "Hoisin",
  "Holland",
  "Holland Bakehouse",
  "Holland House",
  "Hollands",
  "Hollands Kids Honey",
  "Hollands Premium White Clover",
  "Holy Moly",
  "Holy Moly Ice Cream",
  "Holy Moly!",
  "Homai Appetisers",
  "Homai Yum Cha",
  "Home Brand",
  "Home Gourmet",
  "Home St.",
  "Homebrand",
  "Homegrown",
  "Homel Foods",
  "Hon Dashi",
  "Honest Chocolat",
  "Honest Fizz",
  "Honest Juice",
  "Honey puffs",
  "Honey Soy Chicken Potato Chips",
  "Honlevs",
  "Hopt",
  "Horchani",
  "Horizon Fresh",
  "Horley's",
  "Horleys",
  "Horlicks",
  "Hormel",
  "Hormel Foods",
  "Hot As!",
  "Hot Wheels",
  "House",
  "House Foods",
  "House of Dumplings",
  "Howler Hotdogs",
  "HP",
  "HP Sauce",
  "HSM Fresh Food",
  "Hua Shun",
  "Huang Fei Hong",
  "Hubba Bubba",
  "Hubbards",
  "Hubbards and Courtney's",
  "Hubbards Originals",
  "Huffman's",
  "Hugos",
  "Hula Hoops",
  "Hula Hops",
  "Human Bean Co",
  "Human Bean Co.",
  "Humble",
  "Hummingbird Coffee",
  "HumZinga",
  "Hunan Smallgoods",
  "Hunger Buster",
  "Hungry Topper",
  "Hunter Gatherer Gourmet",
  "Huntley & Palmers",
  "Huntley And Palmers",
  "Huntley Palmers",
  "Huntley&Palmers",
  "Hunts",
  "Hutchinsons",
  "Hutchisons",
  "Hutton's",
  "Huttons",
  "Huva",
  "Huy Fong Foods",
  "Huy Fong Foods Inc.",
  "Huy Fong Foods, Inc.",
  "Hydr8",
  "Hydra8",
  "I am Sauce",
  "I Am Super Grains",
  "I Feel Like",
  "I Love Baking",
  "I Love Breakfast",
  "I Love Food Co",
  "I Love Pies",
  "I Testimoni",
  "I&J",
  "Iamsauce",
  "Ice Blue",
  "Ice Break",
  "Icy LIX",
  "Iddy Biddy",
  "Iddy Biddy Fruity Bits",
  "Ikana",
  "Ikkyusan",
  "Ilchester",
  "Ile de France",
  "Iliaaa",
  "Iliada",
  "Iliada Pdo Kalamata",
  "Illicit",
  "Illovo",
  "Illovo Group",
  "Illy",
  "Imagine",
  "Imagine Natural Creations",
  "Impossible",
  "Impra Tea",
  "In Season Snacks",
  "Inaka",
  "Independent",
  "Independent Fisheries",
  "India Gate",
  "Indian Chicken Tonight",
  "Indian Tonight",
  "Indifish",
  "Indo Food",
  "Indo Me",
  "Indo Mie",
  "Indo Time",
  "Indofood",
  "Indomie",
  "Indulgenz",
  "Ines Rosales",
  "Ingham",
  "Ingham Value Selections",
  "Ingham's",
  "Inghams",
  "Ingland",
  "Inka",
  "Inproba",
  "Inside Out",
  "Instore Bakery",
  "Instore Deli",
  "International Roast?",
  "Iquiche",
  "Irn Bru",
  "Irn.Bru",
  "Iron Brew",
  "Irvines",
  "Irvines Pie Time",
  "Irvines Pietime",
  "Island Style",
  "Iso Cream",
  "IsoCream",
  "Isola Bio",
  "Ital",
  "Italgnocchi",
  "Italian Herb",
  "Italian Parsley",
  "IXL",
  "J C Quality Foods",
  "J C's",
  "J. Friend and Co",
  "J. Friend And Co.",
  "J.C's",
  "J.C's Quality Foods",
  "J.C.'s Quality Food",
  "J.C.'s Quality Foods",
  "J.Friend And Co",
  "J.Friend and Co.",
  "Jabal",
  "Jack Daniel's",
  "Jack Link's",
  "Jack Links",
  "Jacob's",
  "Jacobs",
  "Jacquot",
  "Jade Phoenix",
  "Jade Phoneix",
  "Jaffas",
  "Jala",
  "Jamie Oliver",
  "Jammie Dodgers",
  "Janbo",
  "Jarlsberg",
  "Jarrah",
  "Jasberry",
  "Jasons",
  "Jatz",
  "Javvah",
  "JC Quality Foods",
  "JC's",
  "JC's Quality Foods",
  "JDB",
  "Jean Baton",
  "Jean Brunet",
  "Jed's Coffee Co",
  "Jed's Coffee Co.",
  "Jeeny's",
  "Jeeny's Oriental Foods",
  "Jeenys",
  "Jelly Belly",
  "Jelly Belly Sunkist",
  "Jelly Tip",
  "Jelly Tots",
  "Jelly. B",
  "Jenny Craig Quality Foods",
  "Jeremy's",
  "Jersey Girl Organics",
  "Jess's",
  "Jet Planes",
  "Jeurgens",
  "Jia Duo Bao",
  "JiaDuoBao",
  "Jim Beam",
  "Jim Bean",
  "Jive!",
  "JJ's",
  "Jjl Imports",
  "John West",
  "John West Tempters",
  "John West Tuna Tempters",
  "John Wm. Macy's",
  "Johns Pie",
  "Jok 'N' Al",
  "Jok N Al",
  "Jok' n' Al",
  "Jok'N'Al",
  "Joknal",
  "Jolly Time",
  "Jollys",
  "Jols",
  "Jon Jon",
  "Jon Jon Bakeries",
  "Jon Jon Foods",
  "Jon-Jon",
  "Jongga",
  "Jono V Ben",
  "Jordans",
  "Jos Poell",
  "Jos Poell Sinds 1772",
  "Joshua's",
  "Joshua's Ice Cream",
  "Jubes",
  "Juice Kitchen",
  "Juices",
  "Juicies",
  "Juicy Chinese",
  "Juicy Chinese Dumplings",
  "Juicy Cools",
  "Juicy Juicy Juice Co",
  "Juicy Lucy",
  "Juicy Lucy's Juice",
  "Juicy Lucy's Juice Co",
  "Juicy Lucy's Juice Co.",
  "Juicy Lucys Juice Co",
  "Juk",
  "Jules Destrooper",
  "Jumbo Mallows",
  "Jun",
  "Just",
  "Just Aioli",
  "Just Aioli Dip",
  "Just Caught",
  "Just Caught Quality Sea Food",
  "Just Chili",
  "Just Chilli",
  "Just Cought",
  "Just Foods",
  "Just Garlic",
  "Just Ginger",
  "Just Herbs",
  "Just Hummus",
  "Just Jam",
  "Just Juice",
  "Just Juice Less Sugar",
  "Just Juice Splash",
  "Just Juice Veges",
  "Just Noodles",
  "Just Right",
  "Just Smash",
  "Justfoods",
  "Justin Metcalf World Barista Judge",
  "Justine's",
  "Justines",
  "K & M",
  "K & M Free Range Eggs",
  "K&M",
  "K-Food",
  "Kadoya",
  "Kadoya Brand",
  "Kahu Road",
  "Kaikoura Artisan",
  "Kaikoura Artisan Cheese Company",
  "Kaipara",
  "Kaitahi",
  "Kaitaia",
  "Kaitaia Fire",
  "Kalamata",
  "Kalamata Papadimitriou",
  "Kalamata PDO",
  "Kallo",
  "Kalo",
  "Kan Tong",
  "Kanei Katsuo",
  "KanTong",
  "Kapiti",
  "Kapiti Artisan Bakehouse",
  "Kapiti Candies",
  "Kapiti Candy Co",
  "Kapiti Kitchen",
  "Kara",
  "Karajoz",
  "Karen Martini",
  "Karicare",
  "Karicare +",
  "Karicare Plus",
  "Karikaas",
  "Karikaas Dairy Products Ltd",
  "Karikas",
  "Karma Bites",
  "Karma Cola",
  "Karma Drinks",
  "Kashish",
  "Kastner",
  "Kasugai",
  "Kasugai Asian",
  "Kato",
  "Kato Relish",
  "Katuwonus Pelamis",
  "Kauri Coast",
  "Kauri Coast Kumara",
  "Kauri Gaust Kumara",
  "Kauri Kitchen",
  "Kavli",
  "Kawan",
  "Kaweka",
  "Kaye's",
  "Kaye's Bakery",
  "Kayes",
  "Kayes Bakery",
  "KB's",
  "KCH",
  "Kea Cookies",
  "Keebler",
  "Keebler m&m's",
  "Keep It Cleaner",
  "Kefir Tummy Love",
  "Keiths",
  "Kellloggs",
  "Kellog's",
  "Kellog's Special K",
  "Kellogg's",
  "Kellogg's Special",
  "Kellogg's Special K",
  "Kelloggs",
  "Kellogs",
  "Kemp Roberts",
  "Kenney's",
  "Kenny's",
  "Kennys",
  "Keri",
  "Keri Juice Co",
  "Keri Juice Co.",
  "Keri Juice Company",
  "Kernel Season's",
  "Kernel Seasons",
  "Kernelz",
  "Keto",
  "Keto Naturals",
  "Kettle",
  "Kettle Chip Company",
  "Kettle Korn",
  "Kewpie",
  "Kez's",
  "Kez's Kitchen",
  "Kialla Pure Foods",
  "Kick Energy",
  "Kiddlylicious",
  "Kiddylicious",
  "Kids Can",
  "Kiihne",
  "Kikkoman",
  "Killer Crocs",
  "Killinchy Gold",
  "Killinchy Gold & Whittaker's",
  "Kim Young & Sons",
  "Kim's Kitchen",
  "Kimberley Crossman",
  "Kind",
  "Kind Fruit And Nut",
  "Kind Plus",
  "Kinder",
  "Kinder Surprise",
  "Kindervital",
  "Kindly's",
  "King",
  "King Brewing",
  "King Food",
  "King Island",
  "King Land",
  "King Land Soy Products",
  "King of Kiwi",
  "King Oscar",
  "King Pin BBQ",
  "King Protein",
  "King Soup Mix",
  "King's Choice",
  "Kingfisher",
  "Kingland",
  "Kings Choice",
  "Kiri",
  "Kirin",
  "Kirkfield Family Farm",
  "Kirkfield Farm",
  "Kirspkut",
  "Kit Kat",
  "KitKat",
  "Kiwi",
  "Kiwi Bacon",
  "Kiwi Biltong",
  "Kiwi Blue",
  "Kiwi Crush",
  "Kiwi Crushies",
  "Kiwi Donuts",
  "Kiwi Heritage",
  "Kiwi Ice Cream",
  "Kiwi Lite",
  "Kiwi Middle",
  "Kiwi New Zealand",
  "Kiwi Quinoa",
  "Kiwi Sunshine",
  "Kiwigarden",
  "Kiwilana",
  "Kiwiland",
  "Kiwiland Confectionery",
  "Kiwinuka",
  "Knorr",
  "Ko Fresh",
  "Kochu Road",
  "Kofresh",
  "Koh-Kae",
  "Kohi",
  "Kohi Classics",
  "Kohinoor",
  "Kohinoor Rice Treat",
  "Kohu Road",
  "Kohu Road Fruit Ice Cream",
  "Kohu Road Ice Cream",
  "Kohu Road Sorbet",
  "Koikuchi",
  "Koiwai",
  "Koiwai Family Products",
  "Koka",
  "Koka Signature",
  "Kokako",
  "KoKaleka",
  "Kokoleka",
  "Kokuho Rose",
  "Kombu Tsuyu",
  "Kombucha Bros.",
  "Kombucha Wonder Drink",
  "Komplete",
  "Komplete Ultimate",
  "Konex Foods",
  "Kong Foo Sing",
  "Kool Fruits",
  "Kool Mints",
  "Kopi",
  "Kopiko",
  "Koppers Chocolate",
  "Koufuku Nori",
  "Kraft",
  "Kraft Philadelphia",
  "Kramer Krautmasters",
  "Kramer's Krautboy",
  "Kransky",
  "Kremelta",
  "Krisp Kut",
  "Krispie",
  "Krispkut",
  "Krokos Kozanis PDO",
  "Kuchen Meister",
  "KuchenMeister",
  "Kuhne",
  "Kuki",
  "Kumara Fusion",
  "Kungfood",
  "Kurols",
  "Kurrajong Kitchen",
  "Kwality Streets",
  "Kwangcheon",
  "Kwangcheon Won Kin Food",
  "K√ºhne",
  "L&P",
  "L' Authentique",
  "L'acetaia",
  "L'affare",
  "L'Authentique",
  "La Bagu",
  "La Baguette",
  "La Baguette Caf√© Style",
  "La Baguette Fresh Cuisine",
  "La Bella Italia",
  "La Casa Del Formaggio",
  "La Casa Del Fromaggio",
  "La Chinata",
  "La Costena",
  "La Croix",
  "La Dunkerquoise",
  "La Espanola",
  "La Famiglia",
  "La Famiglia Kitchen",
  "La Fraiche",
  "La Fromageria French Foods Specialists",
  "La Genuina",
  "La Mamma",
  "La Mole",
  "La Morena",
  "La Natural",
  "La Panzanella",
  "La Panzanella Artisan Foods Co.",
  "La Patisserie",
  "La Perruche",
  "La Romanella",
  "La Terra E Il Ceilo",
  "La Terra E Il Cielo",
  "La Terra Il Cielo Heaven And Earth",
  "La Veritable",
  "La Zuppa",
  "Labeyrie",
  "Labneh",
  "Lackerli Huus",
  "Lago",
  "Lamole",
  "Lamorena",
  "Langers",
  "Laoganma",
  "Large Free Range",
  "Larsen",
  "Larsen Pure",
  "Las Palmas",
  "Lasco",
  "Lasco Lean Artisan Smokehouse Co.",
  "Latatoes",
  "Laterra E Il Cielo",
  "Latina Fresh",
  "Latins Fresh",
  "Latteria Sociale Mantova",
  "Laucke",
  "Laucke Flour",
  "Laurel Wreath",
  "Lavarock",
  "Lavosh",
  "Lazeez",
  "Lazeez Products",
  "Lazuppa",
  "LCMs",
  "Le Phare Du Cap Bon",
  "Le Rustique",
  "Le Snack",
  "Le Snak",
  "Le Superbe",
  "Lea & Perrins",
  "Leader",
  "Leader Brand",
  "Leader.",
  "LeaderBrand",
  "Lean Artisan Smoke House Co.",
  "Lean Water",
  "Leaning Tower",
  "Leaning Tower Pizza Pronto",
  "Leannces Kitchen",
  "Leanne's Kitchen",
  "Leannes Kitchen",
  "Learning Tower",
  "Leda",
  "Leda Bakery",
  "Lee Kum Kee",
  "Lee Kum Kee Brand",
  "Lee Kum Kee Hong Kong",
  "Lee Kum Kee Panda Brand",
  "Lees",
  "Leffond",
  "Left Field",
  "Leggo's",
  "Leggos",
  "Lemnos",
  "Lemon & Paeroa",
  "Lemon and Paeroa",
  "Lemon Fresh",
  "Leonard's",
  "Leonard's Simply Superior",
  "Leonards",
  "Lepanto",
  "Leppin Sport",
  "Leppinsport",
  "Les Florentines",
  "Let's Eat",
  "Lewis Road Bakery",
  "Lewis Road Bakery Sourdough 5 Grain",
  "Lewis Road Crea",
  "Lewis Road Creamery",
  "Lewis Road Creamery New Zealand",
  "Lian Huat",
  "Libava",
  "Libby's",
  "Liberate",
  "Liberte",
  "Libertea",
  "Licks",
  "Licorette",
  "Lid Unda",
  "Liddells",
  "Life Foods",
  "Life Health Foods",
  "Life Juice",
  "Life Savers",
  "Lift",
  "Lift +",
  "Lift Plus",
  "Light & Right",
  "Light 'N' Tasty",
  "Like Licks",
  "Lillie's",
  "Lillie's Q",
  "Limbro",
  "Limonata Cheesecake",
  "Lincoln Bakery",
  "Lincoln St.",
  "Linda",
  "Linda Mc Cartney",
  "Linda McCartney",
  "Linda McCartney's",
  "Lindauer",
  "Lindor",
  "Lindt",
  "Lindt Carrots",
  "Lindt Creation",
  "Lindt Easter Egg",
  "Lindt Easter Hen",
  "Lindt Excellence",
  "Lindt Fruit Sensation",
  "Lindt Gold Bunny",
  "Lindt Gold Bunny & Egg",
  "Lindt Gold Bunny Farm",
  "Lindt Lindor",
  "Lindt Mini Chicks",
  "Lindt Nuxor",
  "Line's Knaekbrod",
  "Lingham's",
  "Linghams",
  "Lipton",
  "Lipton Ice Tea",
  "Liquirizia Menozzi De Resa",
  "Lisa's",
  "Lisa's Toppings",
  "Lisas",
  "Lite",
  "Lite & Free",
  "Lite Licks",
  "Litebread",
  "Little Angels",
  "Little Asia",
  "Little Beauties",
  "Little Bellies",
  "Little Bird",
  "Little Bird Organics",
  "Little Chef",
  "Little Diggers",
  "Little Island",
  "Little Paddock",
  "Little Pony",
  "Little Quacker",
  "Little Ripper",
  "Little Rippers",
  "Little Yoghi",
  "Little Zebra Chocolates",
  "Live +",
  "Live Plus",
  "Live+",
  "Live+ Ascend",
  "Live+ Ignite",
  "Lively Desserts",
  "Living Goodness",
  "Living Planet",
  "Lloyd's Organic Harvest",
  "Lloyds Organic Harvest",
  "Lo Bros",
  "Lo Bros Living Drinks",
  "Lo Carb",
  "Lo Salt",
  "Loadded",
  "Loaded",
  "Loaded Energy",
  "Loaded Isotonic Sports Drink",
  "Loaded Recovery",
  "Loaded Recovery Isotonic Drink",
  "Loaded Zero",
  "Loaf",
  "Loaf Hand Crafted Breads",
  "Lobros",
  "Locako",
  "Local Legends",
  "Logicol",
  "Loire Valley",
  "Lokumcuzade",
  "Lol",
  "Lolly Go Round",
  "London Pride",
  "Lone Star",
  "Long Life",
  "Longbush",
  "Longkou",
  "Longkou Vermicelli",
  "LongLife",
  "Lonka",
  "Lorea",
  "Lorea Gourmet",
  "LoSalt",
  "Lot Eight",
  "Lotatoes",
  "Lotcare",
  "Lotte",
  "Lotte Zec",
  "Lotus",
  "Louisiana",
  "Love Beets",
  "Love Cake",
  "Love Dole",
  "Love Organics",
  "Love'em Ingham",
  "Loving Earth",
  "Lovingearth",
  "Low Carb Zone",
  "Lowan",
  "Lowan Whole Foods",
  "Lowan whole foods cocoa bombs",
  "Lowrey's",
  "Lowry Peaks",
  "LSG Sky Chefs",
  "LSG Sky Chefs NZ",
  "Lucky Charms",
  "Lucky Pearl",
  "Lucky Sod",
  "Lucozade",
  "Lucozade Energy",
  "Lulu",
  "Lunch Club",
  "Lungkow",
  "Lupi",
  "Lurpak",
  "Lush Products",
  "Luv Sum",
  "Luv-A-Pie",
  "Lycoplys",
  "Lyle's Golden Syrup",
  "Lyle's Golden Syrup Abram lyle & Sons",
  "Lyles",
  "Lyre's",
  "M & M's",
  "M & M's Chocolates",
  "M&M's",
  "M&M's Brand",
  "M&M's Chocolates",
  "M&M's Mix-Ups",
  "M&Ms",
  "M'Kenzie's",
  "Ma Baker",
  "Ma Se Ca",
  "Mac's",
  "Mac's Brew House",
  "Mac's Brewhouse",
  "Macadamia Supreme",
  "Macandrews",
  "Mackenzie",
  "MacKenzie High Country Bread",
  "Mackintosh's",
  "Macro",
  "Macro Free Range",
  "Macro Organic",
  "Macro Wholefoods",
  "Macro Wholefoods Market",
  "Macro Wholefoods Markets",
  "Macs",
  "Macs Brewhouse",
  "Macy and Tailor",
  "Macy Tailor",
  "Made In NZ",
  "Madewell",
  "Madow Fresh",
  "Mae Ploy",
  "Maeil",
  "Maekrua",
  "Maggi",
  "Maggi Fusian",
  "Maggie",
  "Maggie Beer",
  "Magic Pandas",
  "Magic Stick",
  "Magic Straws",
  "Magills",
  "Magills Butchery",
  "Magnat",
  "Magnesia",
  "Magnum",
  "Magnum Americana",
  "Magnum Mini",
  "Maharajah's Choice",
  "Maille",
  "Mainland",
  "Mainland Galaxy",
  "Mainland Premium Quality",
  "Maison Therese",
  "Maitre Truffout",
  "Majana",
  "Majans",
  "Majans Bhuja Snacks",
  "Majestic",
  "Make My Party!",
  "Makers",
  "Maketu Pies",
  "Maketu Pies Honest To Goodness",
  "Makikihi",
  "Makikihi Fries",
  "Malee",
  "Mallowpuffs",
  "Mally Woppy",
  "Malouf's Spice Mezza",
  "Maloufs Spice Mezza",
  "Malteser",
  "Maltesers",
  "Maltexo",
  "Mama",
  "Mama Cup",
  "Mama San",
  "Mama Sen",
  "Mamee",
  "Mamma Fiorelli's",
  "Mammoth",
  "Mammoth Supply",
  "Mammoth Supply Co",
  "Mammoth Supply Co.",
  "Manawa",
  "Manchego",
  "Mandarin Twist",
  "Mandys",
  "Mandys Horseradish",
  "Mango and Strawberry Tea",
  "Manison Therese",
  "Manly Park Kitchen",
  "Manor House Cuisine",
  "Mantuama",
  "Mantuana",
  "Mantuano",
  "Manuka Boost",
  "Manuka Doctor",
  "Manuka Doctor Apiwellness",
  "Manuka Health",
  "Manukee",
  "Manzanilla",
  "Marcel's",
  "Marcel's Home Made Taste",
  "Marcels",
  "Marco Polo",
  "Maretti",
  "Marie Harel",
  "Marine Harvest International",
  "Mario's",
  "Marion's Kitchen",
  "Marios",
  "Market Lane",
  "Market Lane Wholesome Food",
  "Market Value",
  "Marlborough",
  "Marleys Mellow Mood",
  "Marmite",
  "Marrakech",
  "Mars",
  "Martin Pouret",
  "Martinez",
  "Marufuji",
  "Marufuji Panko",
  "Marukin",
  "Marukin Asian",
  "Marukome",
  "Marusan",
  "Marvellous Creations",
  "Marvellous Creations Spider",
  "Mary Gray",
  "Marzipan",
  "Mas Portell",
  "Maseca",
  "Massel",
  "Massimo's",
  "Massimos",
  "Master Chef",
  "Master Foods",
  "Masterfoods",
  "MasterFoods Perfect For",
  "Matakana Super Foods",
  "Matakana Superfoods",
  "Matapiro",
  "Matapiro Estate Blends",
  "Mathac",
  "Mathura",
  "Maties",
  "Mauri",
  "Maxwells Pastry",
  "Maya Gold",
  "Maycey",
  "Mayceys",
  "Maynards",
  "Maynards Bassetts",
  "Mazafati",
  "Mazzatti",
  "Mazzetti",
  "Mc Cain",
  "Mc Cormick",
  "Mc Kenzie's",
  "Mc Vitie's",
  "Mc.Ilhennyco",
  "Mc.Ilhennyco Tabasco Brand",
  "McCain",
  "McCain Healthy Choice",
  "McCain Pizza Slice",
  "McCain Rustic Chips",
  "McCain Takeaway",
  "McCains",
  "McCam",
  "McClure's",
  "McClure's Pickles",
  "McCormick",
  "McCormick Slow Cookers",
  "Mccoy",
  "McIlhenny Co",
  "McIlhenny Co.",
  "McKenzie's",
  "McKenzies",
  "Mcknzie's",
  "Mcllhenny Co.",
  "McVitie's",
  "McVities",
  "Me Emborg",
  "Meadow",
  "Meadow Fresh",
  "Meadow Fresh Calci trim",
  "Meadow Fresh Goodies",
  "Meadow Fresh Lite",
  "Meadow Lea",
  "Meadow Mushrooms",
  "Meadowlea",
  "Meadows",
  "Meal Maker",
  "Meal Mates",
  "Meal Mates Sesame Crackers",
  "Meaty",
  "Medaglia Doro",
  "Medallion",
  "Mediterranean",
  "Meditrade Ltd",
  "Meditterranean",
  "Medow Fresh",
  "Mega Force",
  "Meggetta Brand",
  "Meho",
  "Meichakobo",
  "Meiji",
  "Meji",
  "Meko",
  "Mel's",
  "Mel-O-Rich",
  "Mel-O-Rich Everyday",
  "Melba Food",
  "Melba Foods",
  "Melegatti",
  "Mello Yello",
  "Mellow Yello",
  "Melona",
  "Melorich",
  "MelORich Everyday",
  "Melrose",
  "Mels",
  "Membrillo",
  "Memphis",
  "Memphis Meltdown",
  "Mendelez",
  "Mentos",
  "Mentos Now Mints",
  "Mentose",
  "Merci Chef",
  "Merci Chef Soligan",
  "Merci Chef!",
  "Meredith Dairy",
  "Mersey Valley",
  "Mesdow Fresh",
  "Mestemacher",
  "Metelliana",
  "Mexicano",
  "Mexicano Corn Chips",
  "Mexico",
  "Meyer",
  "Meyer Cheese",
  "Mezzetta",
  "Mi Sedaap",
  "Mida",
  "Mida's",
  "Midas",
  "Mie Sedaap",
  "Mighty",
  "Mighty Bellies",
  "Mighty Fresh",
  "Mighty Mite",
  "Mike and Lke",
  "Miko Brand",
  "Miko Shinsyu-ichi",
  "Milano",
  "Mild",
  "Milk Duds",
  "Milkies",
  "Milky Bar",
  "Milky Way",
  "Mill Orchard",
  "Miller's Elements",
  "Miller's Elements Earth",
  "Millesimato",
  "Milo",
  "Mingle",
  "Mini Babybel",
  "Mini Fromages Cheese Minis",
  "Mini L.I.X",
  "Mini Mix",
  "Mini MrBeaks",
  "Mini Oreo",
  "Mini Oreo Organic",
  "Minions",
  "Minoo",
  "Minor Figures",
  "Minties",
  "Mirinda",
  "Mission",
  "Mission Wraps",
  "Mister",
  "Mister Potato",
  "Mister Potato Crisps",
  "Mister Sweet",
  "Mister Ton",
  "Misura",
  "Misuzu",
  "Mitake",
  "Mitake-Shokuhin",
  "Mitoku",
  "Mitr Phol",
  "Mix 9",
  "Mizkan",
  "Mizone",
  "Mizone Sports Water",
  "Moana",
  "Moccagatta",
  "Moccona",
  "Mocha Coffee & Chocolate",
  "Mogu Mogu",
  "Moir's",
  "Molenberg",
  "Molenberg Grains Plus",
  "Molloys",
  "Molly Woppy",
  "Mommas",
  "Mommas Foods",
  "Mon Sire",
  "Monari Federation 1912",
  "Mondarra",
  "Mondelez International",
  "Mondo Nougat",
  "Mondovino Bambino",
  "Mone Sire",
  "Monster",
  "Monster Energy",
  "Monster Health Food Co",
  "Montanari & Gruzza",
  "Montanari Gruzza",
  "Montebello Organic",
  "Moo Free",
  "Moo Gurt",
  "Moogurt",
  "Moogurt Suckies",
  "Moonbar",
  "Moondarra",
  "Moondarra Cheese",
  "Moondarra Club",
  "Moosies",
  "Mor & Mor",
  "More 4 Less",
  "More4Less",
  "Moretti Linea Alimentare",
  "Mori-Nu",
  "Morianaga",
  "Morinaga",
  "Morish",
  "Morita",
  "Morlife",
  "Morlife Functional Foods",
  "Morning Harvest",
  "Morning Harvest Family Farm Fresh",
  "Morning Hravest Family Farm Fresh",
  "Morning Star Farms",
  "Moro",
  "Moro Gold",
  "Mossop's",
  "Most",
  "Motel",
  "Mother",
  "Mother Apricot",
  "Mother Earth",
  "Mother Earth Nuts About",
  "Mother Eatrth",
  "Mother Energy Drink",
  "Mother Nature",
  "Mother Nature's",
  "Mother Natures",
  "Motueka Creamery",
  "Mount Somers",
  "Mountain Beef",
  "Mountain Blast",
  "Mountain Bread",
  "Mountain Dew",
  "Mountain Fresh",
  "Moven Pick",
  "Movenpick",
  "MPK",
  "MPK Artisan Kitchen",
  "MPro",
  "Mr Beak's",
  "Mr Beaks",
  "Mr Chips",
  "Mr Fishi",
  "Mr Ham Freys",
  "Mr J",
  "Mr Kipling",
  "Mr Mallow",
  "Mr Munchies",
  "Mr Number One",
  "Mr, Beak's",
  "mr, Chips",
  "Mr. Beak's",
  "Mr. Beaks",
  "Mr. Brownie",
  "Mr. Fish",
  "Mr. Ham Freys",
  "Mr. Kipling",
  "Mr. Mallow",
  "Mr. Number One",
  "Mr. Porky",
  "Mr.Beak's",
  "Mr.Beaks",
  "Mr.J",
  "Mr.Mallow",
  "Mr.Munchies",
  "Mr.Munchies Scooby-Doo",
  "Mr.Munchies The Simpsons",
  "Mr.Number One",
  "Mr.Porky",
  "MrBeak's",
  "Mrs Balls",
  "Mrs Bridges",
  "Mrs Darlington's",
  "Mrs Darlingtons",
  "Mrs H.S. Ball's",
  "Mrs H.S.Ball's",
  "Mrs Higgins",
  "Mrs Higgins Cookies",
  "Mrs Higgins Oven Baked Cookies",
  "Mrs Higgins Oven Fresh Cookies",
  "Mrs HS Balls",
  "Mrs Mac's",
  "Mrs Rogers",
  "Mrs Rogers Naturals",
  "Mrs Wagstaffs",
  "Mrs. Bridges",
  "Mrs. Butter-Worth's",
  "Mrs. Green's",
  "Mrs. Higgins",
  "Mrs. Rogers",
  "Mrs.Bridges",
  "Mrs.H.S.Ball's",
  "MTR",
  "Much Moore",
  "Much Moore Awesome",
  "Much Moore Ice Cream",
  "Much Moore Marvels",
  "Much Moore Wonders",
  "Much More",
  "Muesli and Co",
  "Muffin Bakes",
  "Muffin Splits",
  "Mummy's Yummys Homebaking",
  "Munch",
  "Munchies",
  "Munchme",
  "Murdoch's",
  "Murphy's",
  "Musashi",
  "Musashi Shred & Burn",
  "Musclepharm",
  "Muscletech",
  "Mushroom & Brandy",
  "Musical Knives Food",
  "Mustard Makers",
  "Mutti",
  "Mutti Parma",
  "Mutti Simply Sugo",
  "Mutti Solo Pomodoro",
  "My Goodness",
  "My Little Pony",
  "My Main Course:",
  "My Pasta Box",
  "Mylk",
  "N Joy",
  "N Nairn's",
  "Nabisco",
  "Nagatanien",
  "Nairn's",
  "Nairn's Organic",
  "Nairns",
  "Naked",
  "Naked As Nature Intended",
  "Naked Chicken",
  "Naked Kitchen",
  "Naked Locals",
  "Nakod Locals",
  "Nalrn's",
  "Nama",
  "Nando's",
  "Nando's Peri-Peri",
  "Nandos",
  "Nanna's",
  "Nannas",
  "Nanric Road",
  "Nanric Road Exquisite Fine Foods",
  "Nanric Road Fine Foods",
  "Narrie Road Exquisite Fine Foods",
  "Natava",
  "Natava Superfoods",
  "Natcha",
  "Natives Oliven",
  "Natural Abundance",
  "Natural Beverage",
  "Natural Beverage Company",
  "Natural Delights",
  "Natural Delights Coconut",
  "Natural Fields",
  "Natural Goodness",
  "Natural Green",
  "Natural Valley",
  "Naturalea",
  "Naturalea Acidophilus",
  "Naturalea Organic",
  "Naturally Good",
  "Naturally Nood",
  "Naturally Tasti",
  "Naturals",
  "Nature Fields",
  "Nature Food Co.",
  "Nature Land",
  "Nature Valley",
  "Nature Vally",
  "Nature Zone",
  "Nature's Charm",
  "Nature's Corner",
  "Nature's Fresh",
  "Nature's Harvest",
  "Nature's Own",
  "Nature's Path",
  "Nature's Path Organic",
  "Nature's Protein",
  "Nature's Way",
  "Natureland",
  "Natures Corner",
  "Natures Fresh",
  "Natures Path",
  "Natures Path Organic",
  "Natures Valley",
  "Naturezone",
  "Naturli",
  "Natvia",
  "Navarrico",
  "Neenish",
  "Neesa",
  "Nekta",
  "Nendorf Dairy",
  "Neo Pro",
  "Neo's",
  "Neoguri",
  "Neptune",
  "Nerada",
  "Nerada Organics",
  "Nerds",
  "Nereda Organics",
  "Nescafe",
  "Nescafe Cafe Menu",
  "Nescafe Dolce Gusto",
  "Nescafe Gold",
  "Nesher Malt",
  "Nespresso",
  "Nesquik",
  "Nestea",
  "Nestle",
  "Nestle Baker's Choice",
  "Nestle Bakers' Choice",
  "Nestle Carnation",
  "Nestle KitKat",
  "Nestle Maggi",
  "Nestle Maggi Soup for a Cup",
  "Nestle Milo",
  "Nestle Soothers",
  "Neudorf Dairy",
  "New Day",
  "New Fresh Farms",
  "New Hemisphere",
  "New Nordic",
  "New Season",
  "New Way",
  "New World",
  "New World(sabato)",
  "New York Bagels",
  "New York Cocktail Infusion",
  "New York Delhi",
  "New Zealand",
  "New Zealand Apple Products",
  "New Zealand Biltong Company",
  "New Zealand Freeze Dried Products",
  "New Zealand Honey Co.",
  "New Zealand Kettle Korn",
  "New Zealand Natural",
  "New Zealand Natural Premium Ice Cream",
  "New Zealand Natural Premium Sorbet",
  "New Zealand Trading Co.",
  "Newzealand Apple Products",
  "NewZealand Sheep Milk Co",
  "Next!",
  "Nibblish",
  "Nice & Natural",
  "Nice @ Natural",
  "Nice And Natural",
  "Nice Blocks",
  "Nice Blocks By Tommy & James",
  "Nice Blocks Made By Tommy & James",
  "Nice Cream",
  "Nice Cream Made By Tommy & James",
  "Nice&Natural",
  "Nickelodeon",
  "Nicola's Organics",
  "Nicolas Organics",
  "Niederegger Lubeck",
  "Nihon Shokken",
  "Nihonshokken",
  "Nimkish",
  "Ninety Nine",
  "Ninety Nine Street",
  "Nippy's",
  "Nirvana",
  "Nishaan",
  "Nishin",
  "Nisshin Seifun Group Inc.",
  "Nissin",
  "Nissin Asian",
  "Nissin Cup",
  "Njoy",
  "NKPA",
  "No 1 Food",
  "No 1 Foods",
  "No Brand",
  "No Shorcuts",
  "No Shortcuts",
  "No Ugly",
  "No.1 Foods",
  "Noah's",
  "Noan's",
  "Noble",
  "Noble Tonic",
  "Nobu",
  "Nobu Brand",
  "Nograin-Ola",
  "Nola",
  "Nom",
  "Nong Shim",
  "Nongshim",
  "Norbu",
  "Nori",
  "Norse Fitness",
  "Northlands Butcher",
  "Norths",
  "Norths Natural Choice",
  "Nos",
  "NoShortcuts",
  "Noshu",
  "Nostalgie",
  "Nougat Limar",
  "Nougat Limer",
  "Nourish",
  "Nu",
  "Nuenfant Gold",
  "Nuju",
  "Nulac Foods",
  "Nuoc Cham Ga",
  "Nurture Gold +",
  "Nut Brothers",
  "Nut Dream",
  "Nut Pots",
  "Nutella",
  "Nutella Ferrero",
  "Nutino",
  "Nutra Organics",
  "Nutrella",
  "Nutri Boost",
  "Nutri-Grain",
  "Nutrica",
  "Nutricia",
  "Nuttelex",
  "Nutter",
  "Nutters",
  "Nuttvia",
  "Nutty Bruce",
  "Nuttz",
  "Nutz",
  "Nuzest",
  "NW Pizza Gourmet Fresh",
  "NZ Deli",
  "NZ Drinks",
  "NZ Hot House",
  "NZ Natural",
  "NZ Natural Juice Company",
  "NZ Orchard Gate",
  "NZ Orcharrd Gate",
  "Nz's Finest Boca",
  "O & G",
  "O Pure New Zealand",
  "O&G",
  "O' Canada",
  "O'Canada",
  "O.Qua",
  "Oak",
  "Oakley's",
  "Oakwood",
  "Oat-ly!",
  "Oatie's",
  "Oatilicious",
  "Ob Fiinest",
  "Ob Finest",
  "Obap",
  "Obela",
  "Obento",
  "Obfinest",
  "Ocanada",
  "Ocean Blue",
  "Ocean Catch",
  "Ocean Kai",
  "Ocean Monarch",
  "Ocean Pearl",
  "Ocean Spray",
  "Ochre Baby",
  "Ochre Bar",
  "Oddfellows",
  "Oh Yeah",
  "Oh Yeah!",
  "Oh!",
  "Oinggrae",
  "Oke",
  "Oki Doki",
  "Oki-Doki",
  "Okidoki",
  "Okiwi Greenshell",
  "Okonomi",
  "Ol' Smokey",
  "Old Country Food",
  "Old Dominion",
  "Old Dominion Peanut Company",
  "Old El Paso",
  "Old Gold",
  "OldElPaso",
  "Olina's",
  "Olina's Bakehouse",
  "Olina's Bakerhouse",
  "Olitalia",
  "Olivado",
  "Olivado Gourmet Foods",
  "Olivado Natural Nutrition",
  "Olivani",
  "Olive Grove",
  "Olive Lady",
  "Oliveira Da Serra",
  "Olivia",
  "Olliff Farm",
  "Omega Sea Food",
  "Omega Seafood",
  "Omega Seafoods",
  "Onassis",
  "One Dish Asia",
  "One Night In",
  "One Night In Mexico",
  "One Pure",
  "One Sguare Meal",
  "One Square Meal",
  "Ong's",
  "Only Organic",
  "OOB",
  "Oob Organic",
  "Open Happiness",
  "Opies",
  "Options",
  "Optislim",
  "Optislim VLCD",
  "Oqua",
  "Orandos",
  "Orangina",
  "Orchard",
  "Orchard Fresh",
  "Orchard Gate",
  "Orchard Gold",
  "Orchard Gold Organics",
  "Orchid",
  "Oreo",
  "Organ",
  "Organ Gluten Free",
  "Organic",
  "Organic Bakery Breadman",
  "Organic Dinos",
  "Organic Eggs",
  "Organic Farm",
  "Organic India",
  "Organic Isola Bio",
  "Organic Pure Bread",
  "Organic Purebread",
  "Organic Times",
  "Organics by Redbulls",
  "Organics Red Bull",
  "Orgran",
  "Oriental Soy",
  "Oriental Yummy",
  "Original",
  "Original Donuts",
  "Original Foods",
  "Original Foods Baking Co",
  "Original Foods Baking Co.",
  "Original Foods Bite Me",
  "Original Foods Countdown",
  "Original Foods Nz Ltd",
  "Original Red",
  "Ornelle",
  "Ortega",
  "Ortiz",
  "Oryan's Thick Cut",
  "OSM",
  "OSM Nutritional Balance",
  "Osom",
  "Osteria Italia",
  "Ostrich",
  "Otafuku",
  "Otaika Valley",
  "Otaika Valley Free Range",
  "Otonano Furikake",
  "Ottogi",
  "Ottogi Corporation",
  "Ouma",
  "Our Mate",
  "Ovaltine",
  "Over The Moon",
  "Ovi",
  "Ovi Hydration",
  "Ox Brand",
  "Oxford Pies",
  "Oxo",
  "P'tit Grey",
  "P.K",
  "Pacific",
  "Pacific Brand",
  "Pacific Crown",
  "Pacific Food Industries",
  "Pacific Foods",
  "Pacific Fresh",
  "Pacific Valley",
  "Pacific Value",
  "Pacific West",
  "Pack 'n' Save",
  "Pack'n Save",
  "Paddle Pop",
  "Padrazzoli",
  "Paellero",
  "Pak 'n Save",
  "Pak 'n' Save",
  "Pak N Save",
  "Pak'n Save",
  "Pak'n'Save",
  "Pak'nSave",
  "Paketu",
  "Pakn'Save",
  "Paknsave",
  "Palace Poultry",
  "Palace Poultry Jumbo Free Range",
  "Palcarsa",
  "Paleo Four Seeds",
  "Palermo",
  "Palm",
  "Palm Island",
  "Palm' Frutt",
  "Pam",
  "Pama",
  "Pama Fresh",
  "Pamd Super Foods",
  "Pams",
  "Pams Finest",
  "Pams Free Range",
  "Pams Fresh",
  "Pams Fresh Express",
  "Pams Functional Foods",
  "Pams Gluten Free",
  "Pams Organic",
  "Pams Super Foods",
  "Pams Superfoods",
  "Pamsz",
  "Pan Tosto",
  "Panda Brand",
  "Panda Produce",
  "Pandaro",
  "Pandaro Panetteria",
  "Pandoro",
  "Pandoro Panetteria",
  "Panealba",
  "Paneton",
  "Paneton Bakery",
  "Paneton Our French Bakery",
  "Papa Gino's",
  "Papagino's",
  "Papillon",
  "Park Avenue",
  "Park Avenue Disney Frozen",
  "Parkers",
  "Parmareggio",
  "Parmigiano Reggiano",
  "Party Mix",
  "Pascal",
  "Pascal's",
  "Pascall",
  "Passage Foods",
  "Passage to Asia",
  "Passage To China",
  "Passage To India",
  "Passage To Morocco",
  "Pasta Auror",
  "Pasta Aurora",
  "Pasta Aurora Medaglia Doro",
  "Pasta D'Oro",
  "Pasta Di Gragnano IGP",
  "Pasta Mia",
  "Pasta Nostra",
  "Pasta Pronto",
  "Pasta Reggia",
  "Pastamia",
  "Pastificio",
  "Pastificio Riscossa",
  "Pastor",
  "Pastry Kitchen",
  "Pasture Poultry",
  "Patak's",
  "Patak's Original",
  "Patak's Premium",
  "Pataka's Original",
  "Pataks",
  "Pataks Original",
  "Patties",
  "Paul Nemans Own",
  "Paul Newman's Own",
  "Paul Newmans Own",
  "Pave D'Affinois",
  "Pavillion",
  "Pavillion Gluten Free Select",
  "Paw Patrol",
  "Paxo",
  "Paysan Breton",
  "Paysange",
  "Peak",
  "Peak New Zealand",
  "Pearl River Bridge",
  "Pechish",
  "Peck's Anchovette",
  "Peckish",
  "Peg",
  "Pelion",
  "Pelion Brand",
  "Pelmeni",
  "Pema",
  "Penati",
  "Penta",
  "Peppa Pig",
  "Peppadew",
  "Pepper & Me",
  "Pepperidge Farm",
  "Pepsi",
  "Pepsi Co.",
  "Pepsi Max",
  "PepsiCo",
  "Perail",
  "Perfect for Dunking",
  "Perfect Italiano",
  "Perfect Pork",
  "Perfecto Italiano",
  "Perinaise",
  "Perkii",
  "Perky Nana",
  "Perlas",
  "Pernigotti Rustego",
  "Perrier",
  "Perrier Source",
  "Perrys Berrys",
  "Petal",
  "Pete's Natural",
  "Peter Chaplin's",
  "Peter Chaplin's Musical Knives Food",
  "Peter Chaplins Muscial Knives Food",
  "Peter Gordon",
  "Peters",
  "Petit Camembert",
  "Petit Camembert Bocage",
  "Petit miam",
  "Petit Munster",
  "Petit Normand",
  "Petite",
  "Pettinice",
  "Pez",
  "Pfanni",
  "Pfizer",
  "Pheonix",
  "Pheonix Organic",
  "Philadelphia",
  "Philippine",
  "Phoenix",
  "Phoenix New Zealand",
  "Phoenix Organic",
  "Phoric",
  "Piacelli",
  "Piako",
  "Piako Gourmet Yoghurt",
  "Pic's",
  "Pic's Peanut Butter",
  "Picante Bufalo",
  "Piccolos",
  "Picnic",
  "Pico",
  "Pics",
  "Pierogy",
  "Pierogy Dumplings",
  "Pies",
  "Pieter's",
  "Pieter's Natural Wood Smoked",
  "Pieters",
  "Pimp My Salad",
  "Pine Ridge",
  "Pineapple Lumps",
  "Pink Lady",
  "Pinky",
  "Pinoli",
  "Pipi's Bakery",
  "Pipz Seeds",
  "Piranha",
  "Pirmo",
  "Pitango",
  "Pixie",
  "PJ Masks",
  "Plako",
  "Plan*t",
  "Plan-t",
  "Plant Culture",
  "Plant Life Food Co.",
  "Plant Power",
  "Plantasy Foods",
  "Plantry",
  "Plate Me Nutrition",
  "Platinum +",
  "Ploughman",
  "Ploughmans",
  "Ploughmans Bakery",
  "Plumrose",
  "Plumrose Premium",
  "PNZTC Premium New Zealand Trading Co.",
  "Pocky",
  "Podista",
  "Podravka",
  "Pods",
  "Pods Mars",
  "Poesy Bread",
  "Pokka",
  "Polygold",
  "Pom",
  "Pom Wonderful",
  "Pom-Poms",
  "Pons",
  "Ponsonby",
  "Ponsonby Gourmet Pies",
  "Ponsonby Pies",
  "Poonsin",
  "Pop",
  "Pop 'n' Good",
  "Pop N Good",
  "Pop n Good Sensations",
  "Pop Tarts",
  "Pop'n'Good",
  "Poppa Bob's",
  "Poppa Bobs",
  "Poppy + Almond",
  "Poppy + Olive",
  "Popsicle",
  "Pork Shank",
  "Port Salut",
  "Posh Poppas",
  "Postall",
  "Potato Chewda",
  "Powerade",
  "Powerade Ion4",
  "Powerade Isotonic",
  "Powerade Zero",
  "Praise",
  "Pran",
  "Prato Belli",
  "Premier",
  "Premier Beehive",
  "Premier Beehive NZ Ltd",
  "Premium Fresh",
  "Premium New Zealand Trading CO",
  "Premium New Zealand Trading Co.",
  "Premium Seafoods",
  "President",
  "Pressed Purity",
  "Pretzel Crisps",
  "Prima Taste",
  "Primal Bites",
  "Primal Future",
  "Primary",
  "Prime",
  "Prime Angus",
  "Prime New Zealand",
  "Prime Smoke",
  "Prime Smoke Salmon",
  "Primo",
  "Primo Small Goods",
  "Primo SmallGoods",
  "Pringles",
  "Privita",
  "Pro Chef",
  "Pro-Yo",
  "Probiotic",
  "Prochef",
  "Prodotti D'italia",
  "Profood",
  "Profood Cebu",
  "Progressive",
  "Progressive Enterprises",
  "Promite",
  "ProNutro",
  "Proper",
  "Proper Crips",
  "Proper Crisps",
  "Proper Crunch Corn",
  "Proper Hand Cooked Crisps",
  "Protein Bar",
  "Protein FX",
  "ProteinFx",
  "Providore",
  "Provita",
  "Pru Food Cebu",
  "Prunte Marken",
  "Pucka",
  "Puff-O's",
  "Puhoi Valley",
  "Pump",
  "Pumped",
  "Pumpernickel",
  "Punjas",
  "Pure",
  "Pure As",
  "Pure Bread",
  "Pure Canadian Brunswick",
  "Pure Chocolate",
  "Pure Coco",
  "Pure Delish",
  "Pure Dew",
  "Pure Energy",
  "Pure Harvest",
  "Pure Living Water",
  "Pure New Zealand",
  "Pure New Zealand Ice Cream",
  "Pure NZ",
  "Pure Red",
  "Pure Sports Nutrition",
  "Purebread",
  "Purebread Organic",
  "PureCoco",
  "Puredelish",
  "PureDew",
  "Puree De Marrons Bio",
  "Pureharvest",
  "Purely",
  "Puria",
  "QP",
  "Quadriatic Queen",
  "Quaker",
  "Quality & Freshness",
  "Quality Baker",
  "Quality Bakers",
  "Quality Bakers Nature's Fresh",
  "Quality Foods",
  "Quarter Past",
  "Quarterpast",
  "Quattro Colli",
  "Queen",
  "Queen Anne",
  "Queen Quality Decorations",
  "Queens",
  "Queso Manchego",
  "Quest",
  "Quest Bar",
  "Quest Protein Bar",
  "Quina-Fina",
  "Quorn",
  "Radiance",
  "Radiatori",
  "Raffaello",
  "Rafferty's Garden",
  "Raglan",
  "Raglan Coconut Yoghurt",
  "Raglan Food Co",
  "Raguletto",
  "Rainbow",
  "Rainbow Confectionery",
  "Raj's",
  "Rakaia",
  "Rakaia Salmon",
  "Ramano's",
  "Ramen Bokki",
  "Ramune",
  "Rana",
  "Rancitikei",
  "Rangitikei",
  "Raptor Naturals",
  "Rapunzel",
  "Raro",
  "Raro Favourites",
  "Rashuns",
  "Raspberry",
  "Ravin",
  "Raw C",
  "Raw Earth Sweetener Co.",
  "Razor Back",
  "Ready To Eat",
  "Real Cake",
  "Real Earth",
  "Real Foods",
  "Real Good Food",
  "Real Indian",
  "Real McCoy",
  "Real Organic",
  "Real Rad Food",
  "Real Rice",
  "RealBIO Organic",
  "Rebel",
  "Rebel Bake",
  "Rebel Bakehouse",
  "Rebel Kitchen",
  "Red Bell",
  "Red Bull",
  "Red Bull Energy Drink",
  "Red Bull Zero",
  "Red Iced Hearts",
  "Red Island",
  "Red Island Australia",
  "Red Rock",
  "Red Rock Deli",
  "Red Seal",
  "Red Vines",
  "RedBull",
  "Redeem",
  "Redvines",
  "Reese's",
  "Reese's Pieces",
  "Reese's Puffs",
  "Refferty's Garden",
  "Refresh",
  "Regal",
  "Regal Artisan Range",
  "Regel",
  "Reggia",
  "Reggie",
  "Regina",
  "Rejuva",
  "Relish",
  "Relish The Thought",
  "Relish the Thought",
  "Remarkable",
  "Remarkable Tortillas",
  "Remedy",
  "Remedy Kombucha",
  "Rempah",
  "Replace",
  "Resealable Zipper",
  "Resse's",
  "Retro Organics",
  "Reuben Solomon's",
  "Revive",
  "Revive Cafe",
  "Rhodes",
  "Rhodes Quality",
  "Ribena",
  "Ribena Light",
  "Ricci's Bikkies",
  "Rice Bubbles",
  "Rice Dream",
  "Rice Dream Organic",
  "Rice Dream Original",
  "Rice Nibbles",
  "Rice Thins",
  "Rice Wheels",
  "Rich Garden",
  "Richfields",
  "Richter",
  "Richy",
  "Ricies",
  "Rico",
  "Riegelein Confiserie",
  "Rieme",
  "Riga Gold",
  "Rindless",
  "Ringawera",
  "Ringawera Waiheke Island",
  "Rio Dolores",
  "Rio Gold Juice Co",
  "Rio Gold Juice Co.",
  "Riscossa",
  "Rise 'N Shine",
  "Rise 'N' Shine",
  "Riso Scotti",
  "Ritz",
  "Ritz in a Biskit",
  "Ritz Snack",
  "Ritz Snackz",
  "Riverland",
  "Riversea",
  "Riversea Trading Co.",
  "Riverside Farm",
  "Riviana",
  "RJ's",
  "RJ's Licorice",
  "RJs",
  "Roadie Protein Bar",
  "Roasted & Salted Cashews",
  "Robert Harris",
  "Robert Harris Coffee Roasters",
  "Robert Timms",
  "Robertson's",
  "Robinsons",
  "Roccas Deli",
  "Roccasdeli",
  "Rockbill",
  "Rocket",
  "Rocket Boost",
  "Rocket Foods",
  "Rocket Fuel",
  "Rocket Kitchen",
  "Rockstar",
  "Rockstar Super Sours",
  "Rodolfi Parma",
  "Roi Thai",
  "Roka",
  "Roll Ups",
  "Rolling Hills",
  "Rolling Meadow",
  "Rolling Meadow Dairy Works",
  "Rolls",
  "Rollups",
  "Rolo",
  "Romagna",
  "Romano",
  "Romano's",
  "Romanos",
  "Romanzini",
  "Romulo",
  "Roquefort",
  "Roquefort D' Argental",
  "Roquefort Dargental",
  "Rosa",
  "Rosa Foods",
  "Rosa Real Good Food",
  "Rose's",
  "Rose's Lemon",
  "Rose's Lime",
  "Rosedale",
  "Rosella",
  "Rosenborg",
  "Roses",
  "Rosie's",
  "Rosie's Kitchen",
  "Roucoulons",
  "Round Wine",
  "Rowntree's",
  "Rowntree's Peppermint Crisp",
  "Rowntrees",
  "Royal",
  "Royal Crown",
  "Royal Crown Draft",
  "Royal Estate",
  "Royal India",
  "Royalty",
  "Roza's",
  "RSA",
  "Ruby Grove",
  "Rufus Teague",
  "Rummo",
  "Runaway Spoon",
  "Rush Munro's",
  "Rush Munro's Hawke' Bay",
  "Rush Munro's Hawke's Bay",
  "Rush Munro's Hawkes Bay",
  "Rush Munros",
  "RushMunro's Hawke's Bay",
  "Rustichella",
  "Rustichella D'Abruzzo",
  "Rustichella Pomella",
  "Rustico",
  "Rusticone",
  "Rutehrford & Mayer",
  "Rutherford & Mayer",
  "Rutherford & Meyer",
  "Rutherford and Meyer",
  "Ryans",
  "Ryans Bacon",
  "Ryvita",
  "S & B",
  "S&B",
  "S&B Foods Inc.",
  "S&B Wasabi",
  "S-26",
  "S-26 Gold",
  "S-26 Original",
  "S.Pellegrino",
  "Sabarot",
  "Sabato",
  "Sachie's Kitchen",
  "Sacla",
  "Sacla Italia",
  "Saco",
  "Safari",
  "Saint - Luc",
  "Saint Agur",
  "Saint-Luc",
  "Sakata",
  "Sakuma",
  "Salad Pack",
  "Salad Toppers",
  "Salada",
  "Saladds",
  "Salba Traditional",
  "Salisbury",
  "Salisbury Farm",
  "Sally Williams",
  "Salmon Sensations",
  "Salsa Brava",
  "Salty Dog",
  "Salumi Villani",
  "Salvagno",
  "Sam Agri",
  "Sam's Pantry",
  "Sam's Pelmeni",
  "Sam's Pierogi",
  "Samayang",
  "Sambal of the Gods",
  "Sambucol",
  "Sammi",
  "Samyang",
  "San",
  "San - J",
  "San Elk",
  "San J",
  "San Pellecrino",
  "San Pellegrino",
  "San Pellegrino Terme",
  "San Remo",
  "San Remo La Pasta",
  "San'j",
  "San-Bran",
  "San-J",
  "Sancho",
  "Sand Hurst",
  "Sandhu's",
  "Sandhu's Premium",
  "Sandhurst",
  "Sandhurst Fine Foods",
  "Sandhurt",
  "Sandhus",
  "Sanford",
  "Sanford and Sons",
  "Sanford and Sons Fishmonger",
  "Sangaria",
  "Sanitariium",
  "Sanitarium",
  "Sanitarium Health & Wellbeing",
  "Sanitarium Health & Wellbeing So Good",
  "Sanitarium Health & Wellbeing Up & Go",
  "Sanitarium Health & Wellbeing Up&Go",
  "Sanitarium So Good",
  "Sanitarium Up & GO",
  "Sanpellegrino",
  "Sans Noyau",
  "Santa Bremor",
  "Santa Bremor Maties",
  "Santa Rosa",
  "Santa Rose",
  "Santa Vittoria",
  "Sante Rose",
  "Sanuki",
  "Sanuki Udon",
  "Sanuku",
  "Sapori",
  "Sapori Siena",
  "Sara Lee",
  "Sarah Darlington's",
  "Sargam Foods",
  "Sargram Foods",
  "Sashimi",
  "Saucer",
  "Saveur",
  "Saveur Duck",
  "Savoiardi Chef",
  "Savour",
  "Savour & Spice",
  "Savrano",
  "Saxbys",
  "Sazbys",
  "SB",
  "Scalini's",
  "Scalini's at Home",
  "Scarborough Fair",
  "Schlunder",
  "Schweppes",
  "Schweppes Dry Ginger Ale",
  "Sci MX Nutrition",
  "Sci MX Whey Protein",
  "Sci-Mx Nutrition",
  "Science In Sport",
  "Scoff'rs",
  "Scooby-Doo",
  "Scoop Sea Food",
  "Scoop Seafood",
  "Scoop Seafoods",
  "Scotti Riso",
  "Scroggin",
  "Sculpt",
  "Sea Breeze",
  "Sea Crown",
  "Sea Cuisine",
  "Sea Lord",
  "Sea Lord Hoki",
  "Sea Salt",
  "Sea Smoke",
  "Sea Star",
  "Sea Treasure",
  "Sea Treasure Sea Foods Ltd",
  "Seah's Spices",
  "Sealord",
  "Sealors",
  "Seas Cuisine",
  "Season Gourmet",
  "Seasoned Foods",
  "Seasons",
  "Seasons Gourmet",
  "Seasons Gourmet Dips",
  "Seasons Gourmet Soups",
  "Seastar",
  "Seaweed Snack",
  "Seawood Downs",
  "Secret Kiwi Kitchen",
  "Seedlip",
  "Select",
  "Select Harvest",
  "Select Woolworths",
  "Sempio",
  "Sencha",
  "Senz Master Chocolatier",
  "Ser!ous Smoothies",
  "Serious",
  "Serious Cookies",
  "Serious Food Co Ltd",
  "Serious Fruit",
  "Serious Popcorn",
  "Serious Smoothies",
  "Seriously Good",
  "Serpis",
  "Sesameal",
  "Seven-Up",
  "Sf",
  "Shan",
  "Shana",
  "Shanghai Maling B2",
  "Shanyuan",
  "Shao Hsing",
  "Shapes",
  "Shaws",
  "Shaws Huddersfield",
  "Sheep Milk Co.",
  "Shendan",
  "Shi Wan Pai",
  "Shikar",
  "Shimaya",
  "Shin - Mirin",
  "Shin Cup",
  "Shin Mirin",
  "Shinayu-Ichi Mike Brand",
  "Shinshu Soba",
  "Shinshuichi",
  "Shinsyu-ichi Miko Brand",
  "Shirakiku",
  "Shirvan's",
  "Shirvan's Product Of Azerbaijan",
  "Shirvans",
  "Shiryan's",
  "Shock",
  "Shore Marinar",
  "Shore Mariner",
  "Short Bread House of Edinburgh",
  "Shortbread House",
  "Shortbread House of Edinburgh",
  "Shot",
  "Shott",
  "Shrewsbury",
  "Shultz",
  "Shunhe",
  "Shuz",
  "Shweppes",
  "Sicilia",
  "Sier",
  "Sier Chocoteam",
  "Signature Juice",
  "Signature Range",
  "Signature Rice",
  "Sigol",
  "Sileni Epicurean",
  "Silk Road",
  "Silver Fern",
  "Silver Fern Farms",
  "Silver Fernt Farms",
  "Silverbeet",
  "Silverleaf",
  "Simba",
  "Simon Gault",
  "Simon Gault Home Cuisine",
  "Simon Piper",
  "Simple Bakes",
  "Simply",
  "Simply 7",
  "Simply Delish",
  "Simply Dinner",
  "Simply Milk",
  "Simply Natural",
  "Simply New Zealand",
  "Simply Organic",
  "Simply Squeezed",
  "Simply Squeezed Super Juice",
  "Simply The Best",
  "Simunovich",
  "Simunovich Olive Estate",
  "Sinsin",
  "Sipahh",
  "SiS",
  "Six Barrel Soda Co",
  "Six Barrel Soda Co.",
  "Six Star",
  "Six Star Pro Nutrition",
  "Six Stars Pro Nutrition",
  "Sizzlers",
  "Skazka",
  "Skinny Fizz",
  "Skippy",
  "Skittles",
  "Slavica Bakery",
  "Slendier",
  "Slendier Organic",
  "Slim Fruits",
  "Slim Secrets",
  "Slimmer's Choice",
  "Slimmers Choice",
  "Sma",
  "Smarties",
  "Smint",
  "Smiths",
  "Smooze",
  "Smucker's",
  "Smugglers",
  "Smuker's",
  "Snack Factory",
  "Snack Factory Pretzel Crisps",
  "Snack Palace",
  "Snack Pot",
  "Snack Pot Entertainers",
  "Snack Pots",
  "Snack Quiche",
  "Snack Tacular",
  "Snack World",
  "Snacka Changi Chips",
  "Snackaballs",
  "Snacker",
  "Snak Logs",
  "Snakes Alive",
  "Snappz",
  "Snatt's",
  "Snax",
  "Snax Crunches",
  "Snickers",
  "Sniks",
  "Snowdon",
  "So Good",
  "Sobato",
  "Soda Pops",
  "Soda Press Co",
  "Soda Press Co.",
  "Soda Stream",
  "Sodastream",
  "Soha Brown",
  "Sohao Brown",
  "Soho Brown",
  "Sohoo Brown",
  "Soignon",
  "Sola",
  "Solander",
  "Solander Gourmet Food",
  "Solay",
  "Solly's",
  "Solo",
  "Solo Pomodoro Mutti Parma",
  "Solo's Choice",
  "Somerdale",
  "Somerset Farms",
  "Something To Crow About",
  "Sonnie's Sensations",
  "Sonnies's Sensations",
  "Soodie Goodie Grape",
  "Soreen",
  "Sorini",
  "SOS",
  "Soul",
  "Soul Russian",
  "Soup Singles",
  "Sour Patch",
  "South Cape",
  "South Ocean",
  "South Pacific Brands Ltd",
  "Southeast Asian",
  "Southern Kitchen",
  "Southern Ocean",
  "Southland Sweetness",
  "Sovereign",
  "Sovereign King Salmon",
  "Sovrano",
  "Soyco Tempeh",
  "Soyfresh",
  "Spam",
  "Spar Letta",
  "Spar-Letta",
  "Sparkling Duet",
  "Sparkling Oh!",
  "Sparkling Pumped",
  "SPC",
  "Spca",
  "Special Anitin Bread",
  "Special K",
  "Special K Nourish",
  "Specialita Pugliesi Dal",
  "Speciality Cheeses",
  "Specialty Cheeses",
  "Speirs",
  "Speirs The NZ Salad Company",
  "Speirs The NZ Salad Company with an Authentic",
  "Speirs The Salad Company",
  "Speris Nz Salad Company",
  "Spice N Easy",
  "Spirit of Koiwai",
  "Spirite",
  "Splenda",
  "Spoilt For Choice",
  "Sponge Bob Square Pants",
  "Spongebob",
  "SpongeBob Square Pants",
  "SpongeBob SquarePants",
  "Spoonge Bob",
  "Sport",
  "Spree",
  "Spring Blue",
  "Spring Home",
  "Spring Sheep Milk Co",
  "Spring Sheep Milk Co.",
  "Springbok",
  "Springbrook",
  "SpringFresh",
  "Sprite",
  "Sprite Zero",
  "Sproutman",
  "Spuds",
  "Spur",
  "Squid Brand",
  "Squiggles",
  "SR Signature Range",
  "Sriracha",
  "Srpis",
  "Ssam Jang",
  "St Andrews Limes",
  "St Dalfour",
  "St Michel",
  "St Pierre's Sushi",
  "St. Dalfour",
  "St.Dalfour",
  "Stagg",
  "Stagg Chili",
  "Stagg Chilli",
  "Star",
  "Star Class",
  "Star Wars",
  "Starbucks",
  "Starburst",
  "Starz",
  "Steak & Cheese",
  "Steam Fresh",
  "Steers",
  "Steeves Maples",
  "Stevia",
  "Stir",
  "Stir it up",
  "Stir.",
  "StMichel",
  "Stockan's",
  "Stoke",
  "Stone Oven",
  "Stonewall Kitchen",
  "Stoney",
  "Storck",
  "Straight Up",
  "Strange Brew",
  "StrangeLove",
  "Streamland",
  "Street",
  "Street Foodie",
  "Street Kitchen",
  "Streets",
  "Strength Meals Co",
  "Stringers",
  "Stroganoff Tonight",
  "Strognaoff Tonight",
  "Stromboli",
  "Stubb's",
  "Studholme",
  "Styx Apiaries",
  "Sucarly",
  "Sucaryl",
  "Sucaryl Sweetener",
  "Suckies",
  "Sugro Max",
  "Sugromax",
  "Suimin",
  "Suimin Origins",
  "Sujon",
  "Sultana Bran",
  "Sum Mills",
  "Sumin",
  "Summer Harvest",
  "Sun Bites",
  "Sun Country",
  "Sun Harvest",
  "Sun Latte",
  "Sun Maid",
  "Sun Rice",
  "Sun Rice Mini Bites",
  "Sun Rise",
  "Sun Sweet",
  "Sun Valley",
  "Sun Valley Food",
  "Sun Valley Foods",
  "Sun-Maid",
  "Sunaoshi",
  "Sunbeam",
  "Sunbites",
  "Sunburst",
  "Sundaes",
  "Sunday Best",
  "Sunfeast",
  "Sunfed",
  "Sunfield",
  "Sunfield Oils",
  "Sung Gyung",
  "Sungold",
  "Sungold Organic",
  "Suni Brite",
  "Sunkist",
  "Sunlite",
  "Sunny Crust",
  "Sunny Hill",
  "Sunny Hills",
  "SunPork",
  "SunPork Fresh Foods",
  "Sunquick",
  "Sunraysia",
  "Sunreal",
  "Sunreal Naturally Good",
  "SunRice",
  "Sunrise",
  "Sunset Free Range Farms",
  "Sunset Free Range Poultry",
  "Sunshine Food",
  "Sunshine Foods",
  "Sunsweet",
  "Sunsweet 70 Smart Calorie Packs",
  "Suntory",
  "Sunvalley Foods",
  "Sunview",
  "Sunview Organically Grown",
  "Supa",
  "Super",
  "Super AVO's",
  "Super Juice",
  "Super Mario",
  "Super Mi",
  "Super Nature",
  "Super Slice",
  "Super Snack",
  "Super Snack Gold",
  "Superba",
  "Superfood",
  "Superfoods",
  "Superior Liquorice Co",
  "Supermi",
  "SuperSnack",
  "Supersoft",
  "Supreme",
  "Supreme Country Style",
  "Supremely Gourmet",
  "Sure As Eggs",
  "Suree",
  "Suriny",
  "Surprise",
  "Surti",
  "Surti Indian Samosa",
  "Sushi",
  "Swad",
  "Sway",
  "Sweet & Easy",
  "Sweet As",
  "Sweet As Popcorn",
  "Sweet Baby Ray's",
  "Sweet Chilli Philly",
  "Sweet Meadow",
  "Sweet Meadow New Zealand",
  "Sweet Thai",
  "Sweet William",
  "Sweet Williams",
  "Sweetas!",
  "Sweetworld",
  "Swiss Deli",
  "Swiss Deli And Small Goods",
  "Swiss Miss",
  "Swizzels",
  "Swwet Baby Ray's",
  "Sylva",
  "Sylva France",
  "Symbio",
  "Symbio Probalance",
  "T Best",
  "T Grand",
  "T&G",
  "T'best",
  "T. Grand",
  "T.A.S",
  "T.A.S. Brand",
  "T2",
  "Tabasco",
  "Tabasco Brand",
  "Tabisco",
  "Tabisco Brand",
  "Table Lands",
  "Tafu Koshunen",
  "Tagel",
  "Tagiliatelle Nido",
  "Taha",
  "Tahi",
  "Tai-Yo Brand",
  "Taj Roti",
  "Taj Roti Co",
  "Taj Roti Co.",
  "Tajo",
  "Tajo Ostrich",
  "Takaokaya",
  "Takaokaya Sushi",
  "Takdaneh",
  "Takis",
  "Talatta",
  "Talatta Brand",
  "Talbot Forest",
  "Talbot Forest Cheese",
  "Talbot Forest Cheese Co.",
  "Talley's",
  "Talleys",
  "Tamari",
  "Tambura",
  "Tandaco",
  "Tandoori Palace",
  "Tango",
  "Tao Kae Noi",
  "Tao-Kae-Noi",
  "Taokaenoi",
  "Tapatio",
  "Tarall'oro",
  "Taralloro",
  "Tararua",
  "Tararua Dairy Co",
  "Tararua Dairy Co.",
  "Tararua Dairy-Co",
  "Tararya Dairy Co",
  "Tartare",
  "Tartare Aperifrias",
  "Tarts",
  "Tas Brand",
  "Tasco Brand",
  "Tasman Bay Food Group",
  "Tasman Bay Herbs",
  "Taste Greece Food",
  "Taste Maker",
  "Taste Nirvana",
  "Taste Of India",
  "Taste Of Nature",
  "Taste Of Rice",
  "Tastee Pantry",
  "TasteMaker",
  "Tasti",
  "Tasti Made Simple",
  "Tasti Products Ltd",
  "Tastnirvana",
  "Tasty",
  "Tasty Bite",
  "Tasty Bites",
  "Tasty Pot",
  "Tasty Pot Co",
  "Tasty Pot Co Meal",
  "Tasty Pot Co Meal For One",
  "Tasty Pot Co Soup",
  "Tasty Pot Co.",
  "Tatall'oro",
  "Tatua",
  "Taupo Pure",
  "Taveners",
  "Taverna",
  "Taylor",
  "Taylor Farms",
  "Taylor's",
  "Taylored Foods",
  "Taylors",
  "TBest",
  "Tcc",
  "Te Atatu",
  "Te Horo",
  "Te Horo Brand",
  "Te Mata Figs",
  "Tegal",
  "Tegel",
  "Tegel Deli Fresh",
  "Tegel Deli-Fresh",
  "Tegel Meal Maker",
  "Tegel Smokehouse",
  "TeHoro",
  "Teirra Brava",
  "Telegraph H",
  "Telegraph Hill",
  "TeMata Figs",
  "Tender Basted",
  "Tennis Balls",
  "Teriyaki Marinade & Sauce",
  "Terra Sancta",
  "Terry's",
  "Test",
  "Tetley",
  "Texas BBQ Foods",
  "Teza",
  "TFC",
  "Thai Choice",
  "Thai Coco",
  "Thai Gourmet",
  "Thai Hom Mali",
  "Thai Legend Chefs",
  "Thai May",
  "Thank Goodness",
  "The A2 Milk Company",
  "The Alternative Meat Co",
  "The Alternative Meat Co.",
  "The Apple Press",
  "The Baker's So",
  "The Baker's Son",
  "The Bakers Son",
  "The Baron",
  "The Bay Smokehouse",
  "The Belgian",
  "The Bell Tea Co.",
  "The Berry Fix",
  "The Big Chill",
  "The Biggest Loser",
  "The Caker",
  "The Carob Kitchen",
  "The Catch Kingfisher",
  "The Catch of King Fisher",
  "The Catch of Kingfisher",
  "The Cath of King Fisher",
  "The Cheese Barn",
  "The Chia Co",
  "The Chicago Pizza",
  "The Chicago Pizza Company",
  "The Chookery",
  "The Coca Cola Company",
  "The Coca-Cola Company",
  "The Coconut Collaborative",
  "The Coconut Collective",
  "The Collective",
  "The Collective Dairy",
  "The Collective Great Dairy",
  "The Collective Great Diary",
  "The Collective Kefir",
  "The Collective Plant Based",
  "The Cookie Time",
  "The Cool Gardener",
  "the Coolective Great Dairy",
  "The Craft Meat Co.",
  "The Crafty Weka",
  "The Crafty Weka Bar",
  "The Crafty Weka Bite",
  "The Cuisine Canteen",
  "The Dallop Kitchen",
  "The Damn Good Food Company",
  "The Damsom Collection",
  "The Damson Collection",
  "The Devon Cream Company",
  "The Dollop Kitchen",
  "The Drunken Nanny",
  "The Empanada Kitchen",
  "The English Sausages",
  "The English Sausages Ltd",
  "The Fine Cheese Co.",
  "The Food Company",
  "The Foraging Fox",
  "The Four Sauce Men",
  "The Free Range Egg Co",
  "The Full 80",
  "The Full 81",
  "The Ginger People",
  "The Gluten Free Co.",
  "The Gluten Free Food Co.",
  "The Gluten Free Goodies Company",
  "The Good Oil",
  "The Good Taste",
  "The Good Taste Co",
  "The Good Taste Co,",
  "The Good Taste Co.",
  "The Goods",
  "The Grate Kiwi Cheese Company",
  "The Great Dairy Collective",
  "The Greater Food",
  "The Greater Good",
  "The Happy Chewy Cookie Co.",
  "The Happy Snack Company",
  "The Homegrown",
  "The Homegrown Juice Company",
  "The Horo Auckland",
  "The Horo Brand",
  "The House of India",
  "The Ice Bar Co",
  "The Icebar Co",
  "The Icebar Co.",
  "The Jelly Bean",
  "The Jelly Bean Factory",
  "The Jellyologist",
  "The Kaweka Food Co.",
  "The Larder Project",
  "The Laughing Cow",
  "The Limery",
  "The Longest",
  "The Longest Drink In Town",
  "The Lucky Taco",
  "The Meatless Farm Co.",
  "The Mighty Bakery",
  "The Mighty Bakery Ltd",
  "The Mighty Food Kitchen",
  "The Monday Food Co.",
  "The Naked Kernel",
  "The Natural",
  "The Natural Beverage Company",
  "The Natural Chip Co.",
  "The Natural Confectionary Co",
  "The Natural Confectionery",
  "The Natural Confectionery Co",
  "The Natural Confectionery Co.",
  "The Natural Confectionery Co. Berry Bliss",
  "The Natural Food Co",
  "The Natural Food Co.",
  "The Natural Food Company",
  "The Natural Free Range Eggs Co",
  "The Natural Free-Range Co",
  "The Natural Free-Range Co.",
  "The New Zealand Natural Co",
  "The New Zealand Quinoa Co.",
  "The NZ Salad Company",
  "The Olive Lady",
  "The Original",
  "The Original Craft Bar",
  "The Original Gourmet Jelly Bean",
  "The Original Original",
  "The Pate People",
  "The People's Pasta",
  "The Protein Bakery",
  "The Protein Cookie",
  "The Pungency",
  "The Real McCoy",
  "The Remarkable Chocolate Co",
  "The Richmond Food Co.",
  "The Rocky Road House",
  "The Sauce Republic",
  "The Seasons",
  "The Simpsons",
  "The Smoke House",
  "The Smokehouse",
  "The Smoothie Collection",
  "The Soy Works",
  "The Speedy Fish co",
  "The Speedy Fish Co.",
  "The Sport Food",
  "The Sport Food Company",
  "The Sportfood Company",
  "The Storehouse",
  "The Sweet Pie Company",
  "The Sweet Pie Company Ltd",
  "The Ultimate Egg Co",
  "The Ultimate English",
  "The Village Press",
  "The Vineco",
  "The Vineco Verijuice",
  "The Vital Bowl",
  "The Waimata Cheese Co.",
  "The Wcky Taco",
  "The Whole Mix",
  "The Wholesome Food Company",
  "The Wrappery",
  "Theresa's Kitchen",
  "Theresas Kitchen",
  "thevineco",
  "Thexton's",
  "Thextons",
  "Thick & Creamy Raspberry And Cream",
  "Think Food",
  "Thinkfood",
  "Thirst",
  "Thirst Saver",
  "Thoma's",
  "Thomas",
  "Thomas & Friends",
  "Thomas Chipman",
  "Thomas Ice Cream",
  "Thomas'",
  "Thomas' Ice Cream",
  "Thorntons",
  "Thorvald",
  "Thriftee",
  "Thrifty Pak",
  "Thyme",
  "Ti Ora",
  "Ti Tonics",
  "Tian Tan",
  "Tiantan",
  "Tiantan Brand",
  "Tic Tac",
  "Tierra Brava",
  "Tika",
  "Tilda",
  "Tim Tam",
  "Tim Tim",
  "Time Out",
  "Timos",
  "Tiny Foragers",
  "Tiny Teddy",
  "Tio Pablo",
  "Tiora",
  "Tip Top",
  "Tip Top Bakery",
  "Tip Top Bakery Goodness Grains",
  "Tip Top by Whittaker's",
  "Tip Top Crave",
  "Tip Top Real NZ",
  "Tip Top Whittaker's",
  "Tipco",
  "TipTop",
  "TNT",
  "Toblerone",
  "Toddler Formula Premium",
  "Toffee Milk",
  "Toffee Pops",
  "Tokai",
  "Tokuyo",
  "Tokyo Food",
  "Tokyo Food Co",
  "Tokyo Food Co Ltd",
  "Tokyo Food Com",
  "Tokyo Takuantaro",
  "Tolbot Forest",
  "Tom & Luke",
  "Tomato + Mushroom Thick & Chunky Pasta",
  "Tomette",
  "Tommy & James",
  "Tomocky",
  "Tomorrow's Meals",
  "Tomorrows Meal",
  "Tomorrows Meals",
  "Tongariro",
  "Tonics",
  "Tonight Sausages",
  "Tony's",
  "Tony's Chocolonely",
  "Tony's Tucka",
  "Tonys Tucka",
  "Tonzu",
  "Top Cook",
  "Top Grade",
  "Top Hat",
  "Top N Otch",
  "Top Notch",
  "Top Of The Pop",
  "Top Paddock",
  "Top Shelf",
  "Top Taste Company",
  "Top*Hat",
  "Top-Hat",
  "Topper",
  "TopShelf",
  "Torr Organic",
  "Torr Toscano",
  "Torres Selecta",
  "Tortas De Aceite",
  "Toscano",
  "Toscano Authentic Italian",
  "Toscano by La Pieve",
  "Tostitos",
  "Tot's Pantry",
  "Totally Devoted",
  "Trade Aid",
  "Trade Aid Fair Trade",
  "Trader Tom's",
  "Trader Toms",
  "Tradition",
  "Traditional Brazilian Foods",
  "Traditional Dry",
  "Traditional English",
  "Traditional English Sausages",
  "Traditional Free Range",
  "Traditonal Shreddo",
  "Travama Free Range",
  "Trdent",
  "Tree Dimensions",
  "Tree to Me",
  "Treedimensions",
  "Treedimensions Certified Organic",
  "Trianon",
  "Trident",
  "Triple Churned",
  "Triple Delight",
  "Trolli",
  "Tropical",
  "Tropical Fields",
  "Trout Hall",
  "True Organic",
  "True Value",
  "Truffettes",
  "Truffettes de France",
  "Trumpet",
  "Trutaste",
  "Truvia",
  "Tucker's",
  "Tucker's Natural",
  "Tuckers",
  "Tuckers Natural",
  "Tui",
  "Tuimato",
  "Tulip",
  "Tulsi",
  "Tulsi Express",
  "Tuna Sensations",
  "Tunnock's",
  "Tuong Ot Sriracha",
  "Tuong Ot Toi Viet-Nam",
  "Turci",
  "Turk's",
  "Turk's Corn-Fed Chicken",
  "Turkish",
  "Turkish Bread",
  "Turkish Delight Ltd",
  "Turkish Kitchen",
  "Turkrish",
  "Turkrish Bread",
  "Turks",
  "Turmeric Fresh",
  "Turmeric Merchant",
  "Tuxford & Tebbutt",
  "Tuxford & Terbutt",
  "TVI",
  "TVI Brand",
  "Twinings",
  "Twinings Of London",
  "Twininings",
  "Twirl",
  "Twist",
  "Twisties",
  "Twix",
  "Twizzlers",
  "Two Feijoas",
  "Two Hands",
  "Two Worlds Paella",
  "Tyrrell's",
  "Tyrrells",
  "Uchibori",
  "UFC",
  "Uji Sencha",
  "Ujinotsuyu",
  "Ultima",
  "Ultimate Nutrition",
  "Umf 10+",
  "Uncaged Hens",
  "Uncle Beans",
  "Uncle Ben's",
  "Uncle Bens",
  "Uncle Joe's",
  "Uncle Joes",
  "Uncle Larry's",
  "Uncle Roy's",
  "Uncle Tobys",
  "Uncle Tobys Fleming's",
  "Uncle Tobys Oats",
  "Uncle Tobys Plus",
  "Unda",
  "Unibic",
  "United",
  "United Brand",
  "United Fish Co",
  "United Fish Co.",
  "United Fisheries",
  "United Fisheries LTD",
  "United Food Co",
  "United Food Co.",
  "Unknown",
  "Unknown Brand",
  "Up & Go",
  "Upcycled Grain Project",
  "Uppercuts",
  "Urban Appetite",
  "Urban Bakery",
  "Urban Chef",
  "Urchard Gold",
  "Urja",
  "V",
  "V Blue",
  "V Energy",
  "V Energy Drink",
  "V Gnarly",
  "V Graphite",
  "V8",
  "Vaalia",
  "Valcom",
  "Valette",
  "Vallette",
  "Valley Produce Co",
  "Valley Produce Co.",
  "Valley Produce Company",
  "Valley Produce Valley",
  "Valrhona",
  "Vals",
  "Vals Home Made Preserves",
  "Vals Homemade Preserves",
  "Value",
  "Value Bag",
  "Value Meat",
  "Value Pack",
  "Value Pack Bakery",
  "Valumetric",
  "Van Diermen",
  "Van Diermen Masterbakers",
  "Van Holsum",
  "Vanden Bulcke",
  "Vandy's",
  "Vanrooy",
  "Vareniki",
  "Vedrenne",
  "Veesey",
  "Veetee",
  "Vege",
  "Vegel's",
  "Vegemite",
  "Vegeta",
  "Vegie Delights",
  "Vencat Curry",
  "Venerdi",
  "Verde",
  "Verduijn's",
  "Vergani",
  "Veri Peri",
  "Verjuice",
  "Verkerks",
  "Verkers",
  "Ververks",
  "Vetta",
  "Vgood",
  "Viavio",
  "Vibe",
  "Viberi",
  "Vicks",
  "Victoria's Grandma",
  "Viennetta",
  "Vietnamiennes",
  "Vigueur",
  "Villa Mediterranean",
  "Village Green",
  "Village Press",
  "Villars",
  "Vilux",
  "Vimto",
  "Vincotto",
  "Vintage Harvest",
  "ViP Nuts",
  "Vista",
  "Vista Drinks",
  "Vita Beez",
  "Vita Brits",
  "Vita Coco",
  "Vita Fresh",
  "Vita Soy",
  "Vita Sport",
  "Vita-Lite",
  "Vita-Weat",
  "Vitabeez",
  "Vitafresh",
  "Vital Vegetables",
  "Vital Zing",
  "Vital Zing Water Drops",
  "Vitalheart",
  "Vitalvegetables",
  "Vitamin Gum",
  "Vitaplan",
  "Vitarium",
  "Vitasoy",
  "Vitasoy Soy Milky",
  "Vitasport",
  "Vitasport Isotonic",
  "Vitasport Water Booster",
  "Vitizmo",
  "Vittoria",
  "Vivaldi",
  "Vlasic",
  "Voegel's",
  "Vogel",
  "Vogel's",
  "Vogel's All Good",
  "Vogels",
  "Vogels All Good",
  "Voortman",
  "Voortman Bakery",
  "Vp",
  "VPC Yarra Valley",
  "Wafer Crackers",
  "Waffle On",
  "Waha Wera",
  "WaHiki",
  "Wahiki Creamery",
  "Wai Wai",
  "Waiheke Herbs",
  "Waiheke Island",
  "Waikare Kitchen",
  "Waikato Cakes",
  "Waimak",
  "Waimata",
  "Waimata Gizzy Gold",
  "Waimate",
  "Wairere Creamery",
  "Wairere Goat Creamery",
  "Waitoa",
  "Waitoa Free Range",
  "Waitoa Free Range chicken",
  "Waiwera",
  "Waiwera Still",
  "Wakame",
  "Walker's",
  "Walkers",
  "Walkers of London",
  "Walkerswood",
  "Wallaby",
  "Wallaby bites",
  "Walsh's Meats",
  "Walshe's Meats",
  "Walter & Wild",
  "Walters",
  "Want Want",
  "Want-Want",
  "Water For Every One",
  "Waterthins",
  "Watte's",
  "Wattie V/Special",
  "Wattie's",
  "Wattie's Bit On The Side",
  "Wattie's For Baby",
  "Wattie's for Kids",
  "Wattie's Go Fruity",
  "Wattie's Little Kids",
  "Wattie's Organic",
  "Wattie's Pick of the Crop",
  "Wattie's Soup of the Day",
  "Wattie's Very Special",
  "Wattie's Wok Creations",
  "Watties",
  "Watties's",
  "Wattle Valley",
  "Watttie's",
  "Wave",
  "Wdom",
  "We Emborg",
  "Websters",
  "Weet-Bix",
  "Weet-Bix Bites",
  "Weet-Bix Go",
  "Weider",
  "Weight Watchers",
  "WeightWatchers",
  "Weis",
  "Weka",
  "Welch's",
  "Welchs",
  "Well Naturally",
  "Well-Being",
  "Wellaby's",
  "Wellbeeing",
  "Wellington Chocolate Factory",
  "Wellington's",
  "Wellness",
  "Wernli",
  "Werther's Original",
  "Werthers Original",
  "Western Harvest",
  "Westgold",
  "Westie",
  "Westie Family Favourites",
  "Westie Nation",
  "Westie Pies",
  "Westle",
  "Westpac",
  "Whaiora",
  "Wheelies",
  "Whenua Honey",
  "White Stone Cheese Co",
  "Whiteaker's",
  "Whitelock's",
  "Whiteson Cheese Co",
  "Whitestone",
  "Whitestone Cheese Co",
  "Whitestone Selection",
  "Whitlock's",
  "Whitlocks",
  "Whittaker's",
  "Whittaker's & Griffin's",
  "Whittaker's Creamy Milk",
  "Whittaker's Peanut Slab",
  "Whittakers",
  "Who Ate All The Pies?",
  "Whole Earth",
  "Whole Earth Sweetener Co.",
  "Whole Foods",
  "Whole Harry",
  "Whole Kids",
  "Wholesom Hen's Choice",
  "Wholesome",
  "Wholesome Barn",
  "Wholesome New Zealand",
  "Wholesome Sweeteners",
  "Wholesome Sweetness",
  "Wicked",
  "Wicked Sister",
  "Wicked Sister Desserts",
  "Wicked Sister Fine Woods",
  "Wilcox",
  "Wilcox Dig me",
  "Wild Appelite",
  "Wild Appetite",
  "Wild Bunch",
  "Wild Candy",
  "Wild Fennel Co.",
  "Wild Hibiscus",
  "Wild Oceans",
  "Wild Pacific",
  "Wildly Good",
  "Wildy Good",
  "Wilkin & Sons Ltd",
  "Willowmere Organics",
  "Wilmarchelsea",
  "Wilson Hellaby",
  "Wilsons",
  "Windmill",
  "Wing-Time",
  "Wings Food",
  "WingsFood",
  "Winter Warmer",
  "Wise Boys",
  "Wishbone",
  "Wispa",
  "Wobblies",
  "Woh Hup",
  "Wonder Drink",
  "Wonder Brand",
  "Wonder Rose",
  "Wonder Rose Rice",
  "Wonderful Copenhagen",
  "Wonka",
  "Woodburn",
  "Woodburn Venison",
  "Woodburn Vension",
  "Wooden Spoon",
  "Wooden Spoon Boutique Freezery",
  "Woodhaven Gardens Co.",
  "Woodland",
  "Woolowrths",
  "Woolowrths Essentials",
  "Woolsworth Countdown",
  "Woolsworth Select",
  "Woolsworths",
  "Woolwoorths Essentials",
  "Woolwor",
  "Woolworth Coundown",
  "Woolworth Countdown",
  "Woolworth Homebrand",
  "Woolworth Select",
  "Woolworth's",
  "Woolworth's Gold",
  "Woolworth's Homebrand",
  "Woolworth's Select",
  "Woolworths",
  "Woolworths Bbq",
  "Woolworths Cook",
  "Woolworths Countdown",
  "Woolworths Countdown Value",
  "Woolworths Essentials",
  "Woolworths Finest",
  "Woolworths Free From Gluten",
  "Woolworths Gold",
  "Woolworths Hombrand",
  "Woolworths Home Brand",
  "Woolworths Homebrand",
  "Woolworths Macro",
  "Woolworths NZ",
  "Woolworths Select",
  "Woolwortrhs",
  "Woolwortsh Essentials",
  "Woolwoth's Countdown",
  "Woolwoths",
  "Wooworths",
  "Wooworths Select",
  "Wow",
  "Wow Confectionery",
  "Wow Naturals",
  "Wrighley's",
  "Wright Sprouts",
  "Wrigley",
  "Wrigley's",
  "Wrigley's 5",
  "Wrigley's Airwaves",
  "Wrigley's Eclipse",
  "Wrigley's Extra",
  "Wrigley's Hubba Bubba",
  "Wrigley's Starbrust",
  "Wrigleys",
  "Wrigleys Extra",
  "Wunderbites",
  "WW",
  "Wxyha",
  "Wyeth",
  "Wyke Farms",
  "Xin Yuan Zhai",
  "XL",
  "Yaki Nori- Green",
  "Yaki Nori-Gold",
  "Yakitori",
  "Yakult",
  "Yamasa",
  "Yamashiroya",
  "Yamshiroya",
  "Yan Yan choco snack",
  "Yanco",
  "Yarrows",
  "Yarrows 2011",
  "Yeko",
  "Yeo's",
  "Yeos",
  "Yes You Can",
  "Yocomo",
  "Yogo",
  "Yok 'N' Al",
  "Yolo",
  "Yoosh",
  "Yoplair",
  "Yoplait",
  "Yopolait",
  "Yucatan",
  "Yucatan Guacamole",
  "Yuki No Yado Cracker",
  "Yum",
  "Yum Yum",
  "Yum.",
  "Yumba",
  "Yummy",
  "Yummy Bites",
  "Yummy Foods",
  "Yummy Paldo",
  "Zafarelli",
  "Zafferoni",
  "Zamora",
  "Zan Zeus",
  "Zandy Zeus",
  "Zany Zeus",
  "Zappz",
  "Zea Gold",
  "Zeagold",
  "Zebra International",
  "Zenzo",
  "Zenzo It's All Good",
  "Zero",
  "Zero Slim & Healthy",
  "Zero Slim & Healthy Noodles",
  "Zero Water",
  "Zerowater",
  "Zesetel",
  "Zest",
  "Zesti",
  "Zico",
  "Zilch",
  "Zilch!",
  "Zing",
  "Zito",
  "Zoaties",
  "Zombie",
  "Zoodles",
];

export const Categories = [
  {
    full_name: "Alcohol",
    food_group_code: "18",
  },
  {
    full_name: "Bread and bakery products",
    food_group_code: "01",
  },
  {
    full_name: "Cereal and cereal products",
    food_group_code: "02",
  },
  {
    full_name: "Confectionery",
    food_group_code: "03",
  },
  {
    full_name: "Convenience foods",
    food_group_code: "04",
  },
  {
    full_name: "Dairy",
    food_group_code: "05",
  },
  {
    full_name: "Edible oils and oil emulsions",
    food_group_code: "06",
  },
  {
    full_name: "Eggs",
    food_group_code: "07",
  },
  {
    full_name: "Fish and seafood products",
    food_group_code: "08",
  },
  {
    full_name: "Fruit and vegetables",
    food_group_code: "09",
  },
  {
    full_name: "Meat and meat products",
    food_group_code: "10",
  },
  {
    full_name: "Non-alcoholic beverages",
    food_group_code: "11",
  },
  {
    full_name: "Sauces and spreads",
    food_group_code: "12",
  },
  {
    full_name: "Snackfoods",
    food_group_code: "13",
  },
  {
    full_name: "Sugars, honey and related products",
    food_group_code: "14",
  },
  {
    full_name: "Special foods",
    food_group_code: "15",
  },
  {
    full_name: "Unable to be categorised",
    food_group_code: "16",
  },
  {
    full_name: "Vitamins and supplements",
    food_group_code: "17",
  }
];
