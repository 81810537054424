import React, {FC, useEffect} from 'react';
import styles from './AddProductToList.module.css';
import {Box, Button, List, ListItem, Modal, styled, TextField} from "@mui/material";
import { get, set } from "idb-keyval";

import AddIcon from "../../resources/View product/add icon.svg";


interface AddProductToListProps {
  saveInMyList: any,
  checkCurrentProductInLists: any,
  saveInFavouriteList: any
}

const Add2ListButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  lineHeight: 1.5,
  font: 'normal normal 600 20px/24px Montserrat, sans-serif',
  color: '#29A26E',
  width: '200px',
  justifyContent: 'space-between',
  borderRadius: '12px',
  border: '2px solid #008F46',
});

const AddProductToList: FC<AddProductToListProps> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [isTyping, setIsTyping] = React.useState(false);
  const [newListName, setNewListName] = React.useState('');
  const [myListNames, setMyListNames] = React.useState<string[]>([]);
  const [currentProductListNames, setCurrentProductListNames] = React.useState<string[]>([]);
  const isShortScreen = window.innerHeight <= 900;

  // init myListNames from local storage
  useEffect(() => {
    const { checkCurrentProductInLists } = props;
    get<string[]>("myListNames").then(async (listNames) => {
      if (listNames) {
        setMyListNames(listNames);
        setCurrentProductListNames(await checkCurrentProductInLists([...listNames, 'Favourites']));
      }
    });
  }, [props]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleListItemClick = (listName: string) => {
    if (listName === 'createNewList') {
      setIsTyping(true);
    } else {
      // add product into the corresponding list
      props.saveInMyList(listName);
      const updatedListNames = currentProductListNames.includes(listName)
          ? currentProductListNames.filter((name) => name !== listName)
          : [...currentProductListNames, listName];

      setCurrentProductListNames(updatedListNames);
    }
  };

  const handleFavouriteClick = () => {
    const updatedListNames = currentProductListNames.includes('Favourites')
        ? currentProductListNames.filter((listname) => listname !== 'Favourites')
        : [...currentProductListNames, 'Favourites'];

    setCurrentProductListNames(updatedListNames);
    props.saveInFavouriteList();
  };


  const createNewList = (event: any) => {
    event.preventDefault();

    // if newListName not exist in myListNames and it's not "Favourites", add it to local storage
    if (myListNames.indexOf(newListName) < 0 && newListName !== "Favourites") {
      set('myListNames', myListNames.concat(newListName)).then(() => {
        // then add this product into the new list
        props.saveInMyList(newListName);
        window.location.reload();
      }).catch((err) => {
        console.error('Set myListNames failed!', err);
        alert('Set myList Names failed, please contact us so we can fix it for you.');
      });
    } else {
      alert("list name '" + newListName + "' exist already.");
    }
  };

  const style = {
    position: 'absolute',
    top: anchorEl ? anchorEl.getBoundingClientRect().bottom + 10 + 'px' : '50%',
    left: anchorEl ? anchorEl.getBoundingClientRect().left - 8 + 'px' : '50%',
    bgcolor: 'background.paper',
    borderRadius: '15px',
    boxShadow: '0px 10px 30px #00000029',
    p: 2,
    '@media (max-width: 900px), (max-height: 900px)': {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    },
  };

  return <div className={styles.AddProductToList} data-testid="AddProductToList">
    <div className="ShowFor900SmallScreenOnly">
      <Button onClick={handleClick}>
        <img src={AddIcon} alt="add2list" style={{width: "clamp(27px,1.85vw + 20px, 55px)"}}/>
      </Button>
    </div>
    <div className="ShowFor900BigScreenOnly">
      <Add2ListButton className={styles.Add2ListButton}>
        <span className={styles.Add2ListText} onClick={handleClick} style={{cursor: "pointer"}}>Add to list</span>
        <div className={styles.AddFavouriteIcon} onClick={handleFavouriteClick}>
          <div className={currentProductListNames.includes('Favourites') ? styles.SelectedFavouriteIcon : styles.FavouriteIcon}/>
        </div>
      </Add2ListButton>
      <Modal
          open={open}
          aria-labelledby="modal-add-to-list"
          aria-describedby="modal-to-add-item-to-your-list"
      >
        {
          isTyping ? <Box component="form"
                          onSubmit={createNewList}
                          sx={{ ...style,
                            m: 1, width: '30ch',
                            top: `calc(${style.top} - 10px)`,
                            left: `calc(${style.left} - 8px)` }}
                          autoComplete="off"
              >
                <div style={{padding: "10px 20px"}}>
                  <TextField id="standard-basic" variant="standard" label="Type list name" fullWidth={true}
                             value={newListName} onChange={e => setNewListName(e.target.value)} inputProps={{ maxLength: 18 }} required/>
                </div>
                <div className={styles.SaveCancelButtonsRow}>
                  <button type="submit" className={styles.SaveCancelButton}>Save</button>
                  <button className={styles.SaveCancelButton} onClick={() => setIsTyping(false)}>Cancel</button>
                </div>
              </Box>
              :
              <List sx={style}>
                <div className={styles.ClosePanel} onClick={handleClose}>
                  <div className={styles.Add2ListOptionText} style={{color: '#29A26E'}}>Add to List</div>
                  <div className={styles.CloseIcon}/>
                </div>
                {myListNames.map((listName) => (
                    <ListItem button dense={isShortScreen} className={styles.ListItem} onClick={() => handleListItemClick(listName)} key={listName}>
                      <div className={currentProductListNames.includes(listName) ? styles.AddedIcon : styles.AddIcon}/>
                      <div className={styles.HoverIcon}/>
                      <div className={styles.Add2ListOptionText}>{listName}</div>
                    </ListItem>
                ))}
                <button className={styles.CreateListButton} onClick={() => handleListItemClick('createNewList')}>
                  Create new list
                </button>
              </List>
        }
      </Modal>
    </div>
  </div>;
}

export default AddProductToList;
