export const test=()=>{

}

// Clamp number between two values with the following line:
export const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);

export const getHeaderHeight = () => {
  return clamp(78, 0.08 * window.innerWidth + 50, 181);
}

// scroll to element by id
export const scrollToElement = (id: string) => {
  const element = document.getElementById(id);

  if (element) {
    window.scrollTo(0, element.offsetTop);
  }
}

// scroll to element - headerHeight
export const scrollToElementConsiderHeader = (id: string) => {
  const element = document.getElementById(id);

  if (element) {
    window.scrollTo(0, element.offsetTop - getHeaderHeight());
  }
}

export const getUniqueItems = (array: any[]) => {
  return array.filter((item, pos) => array.indexOf(item) === pos);
}

export const fetchWithTimeout = (resource: string, options= {}, timeout = 5000) => {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  return new Promise<Response>((resolve, reject) => {
    fetch(resource, {
      ...options,
      signal: controller.signal
    }).then((resp) => {
      resolve(resp);
    }).catch((err) => {
      reject(err);
    }).finally(() => {
      clearTimeout(id);
    })
  });
}

export const errorMessage = "Try it later. If it happens again, please contact us by email (starsearch@auckland.ac.nz), so we can fix it for you.";
