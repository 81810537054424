import React, {FC, useContext, useEffect} from 'react';
import styles from './SearchBar.module.css';
import {
  Autocomplete,
  Fade,
  Divider,
  Grid,
  Grow,
  Paper,
  CircularProgress,
  Select,
  MenuItem,
  Tooltip
} from "@mui/material";

import { get, set } from "idb-keyval";
import {useNavigate} from "react-router-dom";

import SearchIcon from "../../resources/Home/header/search icon.svg";

import SearchRelatedDataContext from "../../services/SearchRelatedDataService";

interface SearchBarProps {
  isHeaderSearchBar? : boolean;
}

export interface SearchResultsRequestBody {
  product_name: string,
  brand_name: string,
  food_group_code: string
}

export const QuestionIcon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm169.8-90.7c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/></svg>`;

export const enableSearch = (foodItemInput: string, brandInput: string, categorySelection: string) => {
  // new decision: only foodItemInput is required
  return foodItemInput !== null && foodItemInput.length > 1;
};

function FoodInputAutoComplete(props: {isHeader: boolean, value: string, handleInputChange: any, isSmallScreenHeader?: boolean}) {
  const [open, setOpen] = React.useState(false);

  // get the popularItem, brandName and category lists from context
  const searchRelatedDataCtx = useContext(SearchRelatedDataContext);
  if (!searchRelatedDataCtx) {
    throw new Error("Context must be used within a Provider");
  }

  if (searchRelatedDataCtx.isLoading) {
    return <CircularProgress />;
  }

  return <Autocomplete
    freeSolo
    options={searchRelatedDataCtx.searchList}
    getOptionLabel={(option) => option || ""}
    isOptionEqualToValue={(option, value) =>
      value === undefined || value === "" || option === value
    }
    open={open}
    onInputChange={(event, value) => {
      props.handleInputChange(event);
      if (value.length < 2) {
        if (open) setOpen(false);
      } else {
        if (event && !open) setOpen(true);
      }
    }}
    onClose={() => setOpen(false)}
    autoComplete={true}
    sx={{ width: '100%' }}
    onChange={props.handleInputChange}
    value={props.value}
    className={props.isHeader ? styles.HeaderInputPlaceholder : styles.HomeInputPlaceholder}
    renderInput={(params) =>
      <div ref={params.InputProps.ref}>
        <input type="text" {...params.inputProps}
               placeholder={props.isSmallScreenHeader ? "Food item" : "Any"}
               className={props.isHeader ? styles.HeaderSearchBarSectionBrandInput : styles.SearchBarSectionBrandInput}/>
      </div>
    }
  />;
}

function BrandInputAutoComplete(props: {isHeader: boolean, value: string, handleInputChange: any, isSmallScreenHeader?: boolean}) {
  // get the brandName and category lists from context
  const searchRelatedDataCtx = useContext(SearchRelatedDataContext);
  if (!searchRelatedDataCtx) {
    throw new Error("Context must be used within a Provider");
  }

  if (searchRelatedDataCtx.isLoading) {
    return <CircularProgress />;
  }

  return <Autocomplete
    options={searchRelatedDataCtx.brandNames}
    isOptionEqualToValue={(option, value) =>
      value === undefined || value === "" || option === value
    }
    sx={{ width: '100%' }}
    onChange={props.handleInputChange}
    value={props.value}
    className={props.isHeader ? styles.HeaderInputPlaceholder : styles.HomeInputPlaceholder}
    renderInput={(params) =>
      <div ref={params.InputProps.ref}>
        <input type="text" {...params.inputProps}
               placeholder={props.isSmallScreenHeader ? "Add brand" : "Any"}
               className={props.isHeader ? styles.HeaderSearchBarSectionBrandInput : styles.SearchBarSectionBrandInput}/>
      </div>
    }
  />;
}

function CategorySelect(props: {isHeader: boolean, value: string, handleChange: any, addCategory?: boolean}) {
  // get the brandName and category lists from context
  const searchRelatedDataCtx = useContext(SearchRelatedDataContext);
  if (!searchRelatedDataCtx) {
    throw new Error("Context must be used within a Provider");
  }

  if (searchRelatedDataCtx.isLoading) {
    return <CircularProgress />;
  }

  const DownwardArrowIcon = (props: any) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="Group_3760" data-name="Group 3760" transform="scale(0.8) translate(-156 -8.07)">
        <path id="Path_1906" data-name="Path 1906" d="M615.213,72.046l4.136,4.646,4.136-4.646" transform="translate(-448.713 -50.475)" fill="none" stroke="#454545" strokeLinecap="round" strokeWidth="2"/>
        <g id="Ellipse_29" data-name="Ellipse 29" transform="translate(156 8.07)" fill="none" stroke="#b1b1b1" strokeWidth="1">
          <circle cx="15" cy="15" r="14.5" fill="none"/>
        </g>
      </g>
    </svg>
  );

  return <Select
    displayEmpty
    disableUnderline
    value={props.value}
    onChange={props.handleChange}
    variant="standard"
    IconComponent={DownwardArrowIcon}
    sx={{
      "& .MuiSelect-icon": {
        top: "calc(50% - 0.7em)"
      },
      "& .MuiInputBase-input": {
        '&:focus': {
          backgroundColor: 'transparent'
        }
      }
    }}
    inputProps={{
      'aria-label': 'Without label',
      sx: {p: 0}
    }}
    className={props.isHeader? styles.HeaderSearchBarSectionCategorySelect: styles.SearchBarSectionSelectInput}
  >
    <MenuItem value="">
      <em className={props.isHeader ? styles.HeaderSelectDefaultOption : styles.HomeSelectDefaultOption}>{props.addCategory ? "Add Category" : "Any"}</em>
    </MenuItem>
    {searchRelatedDataCtx.categories.map((category, i) => (
      <MenuItem key={i} value={category.food_group_code}>
        {category.full_name}
      </MenuItem>
    ))}
  </Select>
}

function HeaderSearchBarForBigScreen(props:
                                       { handleSubmit: any,
                                         foodItemInput: string, handleFoodItemInputChange: any,
                                         brandInput: string, handleBrandInputChange: any,
                                         categorySelection: string, handleCategorySelectionChange: any
                                       }) {
  return <div className={styles.SearchBar}>
    <Paper component="form"
           onSubmit={(event: any) => props.handleSubmit(event, true)}
           sx={{ p: '0px',
             display: 'flex',
             alignItems: 'center',
             background: '#FFFFFF 0% 0% no-repeat padding-box',
             boxShadow: '0px 10px 30px #4F4F4F17',
             borderRadius: '44px',
             border: '0.5px solid #70707080' }}
    >
      <Grid container spacing={1}>
        {/* food item */}
        <Grid item xs={4}>
          <div className={styles.SearchBarSection}>
            <div className={styles.SearchBarFindFoodSection}>
              <div className={styles.SearchBarSectionLabel} style={{paddingTop: "2px"}}>Food item</div>
              <FoodInputAutoComplete isHeader={true} value={props.foodItemInput} handleInputChange={props.handleFoodItemInputChange}/>
            </div>
            <Divider sx={{ height: 60, borderColor: 'rgba(0, 0, 0, 0.25)', ml: 1 }} orientation="vertical" />
          </div>
        </Grid>

        {/* brand */}
        <Grid item xs={3.5}>
          <div className={styles.SearchBarSection}>
            <div style={{width: '100%'}}>
              <div className={styles.SearchBarSectionLabel}>Brand</div>
              <BrandInputAutoComplete isHeader={true} value={props.brandInput} handleInputChange={props.handleBrandInputChange}/>
            </div>
            <Divider sx={{ height: 60, borderColor: 'rgba(0, 0, 0, 0.25)', ml: 1 }} orientation="vertical" />
          </div>
        </Grid>

        {/* category */}
        <Grid item xs={3}>
          <div className={styles.SearchBarSection} style={{flexDirection: "row"}}>
            <div style={{width: '100%', paddingTop: "4px"}}>
              <div className={styles.SearchBarSectionLabel}>Category
                {/*<span hidden={props.foodItemInput === '' || (props.brandInput !== null && props.brandInput !== '')} style={{color: "red"}}> *</span>*/}
              </div>
              <CategorySelect isHeader={true} value={props.categorySelection} handleChange={props.handleCategorySelectionChange}/>
            </div>
          </div>
        </Grid>

        {/* search icon */}
        <Grid item xs={1.5}>
          <button className={`${styles.SearchBarSectionIcon} ${!enableSearch(props.foodItemInput, props.brandInput, props.categorySelection) ? styles.SearchBarDisabled : ''}`}
                  type={"submit"} disabled={!enableSearch(props.foodItemInput, props.brandInput, props.categorySelection)}>
            <img src={SearchIcon} alt="search" style={{ margin: "2px 0 0 2px" }}/>
          </button>
        </Grid>
      </Grid>
    </Paper>
  </div>;
}

function HeaderSearchBarForSmallScreen(props:
                                         { handleSubmit: any,
                                           foodItemInput: string, handleFoodItemInputChange: any,
                                           brandInput: string, handleBrandInputChange: any,
                                           categorySelection: string, handleCategorySelectionChange: any
                                         }) {
  // header SearchBar mobile open state
  const [mobileHeaderOpenState, setMobileHeaderOpenState] = React.useState(false);

  const filterPanel = (
    <Paper component="form"
           onSubmit={(event: any) => {setMobileHeaderOpenState(false); props.handleSubmit(event, true);}}
           elevation={4}
           sx={{ p: '0',
             display: 'flex',
             flexDirection: 'column',
             alignItems: 'left',
             background: '#FFFFFF 0% 0% no-repeat padding-box',
             boxShadow: 'none',
             borderRadius: '8px',
             border: '0.5px solid rgba(0, 0, 0, 0.25)'}}>

      {/* food item */}
      <div style={{display: "flex", padding: "10px 20px"}}>
        <button className={`${styles.SearchBarSectionIconSmall} ${!enableSearch(props.foodItemInput, props.brandInput, props.categorySelection) ? styles.SearchBarDisabled : ''}`}
                type={"submit"} disabled={!enableSearch(props.foodItemInput, props.brandInput, props.categorySelection)}>
          <img src={SearchIcon} alt="search" style={{ margin: "2px 0 0 2px", width: "90%", height: "90%" }}/>
        </button>

        <FoodInputAutoComplete isHeader={true} isSmallScreenHeader={true} value={props.foodItemInput} handleInputChange={props.handleFoodItemInputChange}/>
      </div>

      <Divider sx={{borderColor: 'rgba(0, 0, 0, 0.25)'}} />

      <div style={{display: "flex", padding: "0px 10px"}}>
        {/* brand */}
        <div className={styles.HeaderSearchBarSection} style={{ flex: 1, padding: "10px 0"}}>
          <BrandInputAutoComplete isHeader={true} isSmallScreenHeader={true} value={props.brandInput} handleInputChange={props.handleBrandInputChange}/>
        </div>

        <Divider sx={{ height: 'auto', borderColor: 'rgba(0, 0, 0, 0.25)', ml: 1 }} orientation="vertical" />

        {/* category */}
        <div className={styles.SearchBarSection} style={{flexDirection: "row", flex: 1, padding: "10px 10px"}}>
          {/*<div hidden={props.foodItemInput === '' || (props.brandInput !== null && props.brandInput !== '')} style={{color: "red"}}>*</div>*/}
          <CategorySelect isHeader={true} value={props.categorySelection} handleChange={props.handleCategorySelectionChange} addCategory={true}/>
        </div>
      </div>
    </Paper>
  );

  return <div className={styles.SearchBar}>
    { mobileHeaderOpenState ?
      <Fade
        in={mobileHeaderOpenState}
        style={{ transformOrigin: '0 0 0' }}
        timeout={{ appear: 0, enter: 800, exit: 300 }}
      >
        <div className={styles.HeaderSearchBarFadeText} onClick={() => {setMobileHeaderOpenState(false)}}>
          Filter your search
        </div>
      </Fade> :
      <Paper onClick={() => {setMobileHeaderOpenState(true)}}
             component="form"
             sx={{ p: '10px',
               display: 'flex',
               alignItems: 'center',
               background: '#F0F0F0 0% 0% no-repeat padding-box',
               boxShadow: 'none',
               borderRadius: '24px',
               border: 'none' }}
      >
        {/* food item */}
        <div className={styles.HeaderSearchBarSection}>
          <FoodInputAutoComplete isHeader={true} value={props.foodItemInput} handleInputChange={props.handleFoodItemInputChange}/>
        </div>

        <div style={{ flex: 0.5}}>
          <div className={styles.Splitter}/>
        </div>

        {/* brand */}
        <div className={styles.HeaderSearchBarSection} style={{ flex: 3 }}>
          <BrandInputAutoComplete isHeader={true} value={props.brandInput} handleInputChange={props.handleBrandInputChange}/>
        </div>
      </Paper> }
    <div className={styles.HeaderSearchBarPopper}>
      <Grow
        in={mobileHeaderOpenState}
        style={{ transformOrigin: '0 0 0' }}
        timeout={{ appear: 100, enter: 500, exit: 300 }}
      >
        {filterPanel}
      </Grow>
    </div>
  </div>;
}

const SearchBar: FC<SearchBarProps> = (props) => {
  const navigate = useNavigate();

  const [foodItemInput, setFoodItemInput] = React.useState("");
  const [brandInput, setBrandInput] = React.useState("");
  const [categorySelection, setCategorySelection] = React.useState("");

  // get the search food item text
  useEffect(() => {
    get<SearchResultsRequestBody>("searchResultsRequestBody").then(value => {
      if (value) {
        setFoodItemInput(value.product_name);
        setBrandInput(value.brand_name);
        setCategorySelection(value.food_group_code);
      }
    });
  }, []);

  const handleFoodItemInputChange = (event: any) => {
    if (event && event.target) {
      setFoodItemInput(event.target.value ? event.target.value : event.target.textContent);
    }
  }

  const handleBrandInputChange = (event: any, values: any) => {
    setBrandInput(values);
  }

  const handleCategorySelectionChange = (event: any) => {
    setCategorySelection(event.target.value);
  }

  const handleSubmit = (event: any, isHeader?: boolean) => {
    if (!isHeader) {
      event.preventDefault();
    }
    event.preventDefault();

    set('searchResultsRequestBody', {
      product_name: foodItemInput,
      brand_name: brandInput,
      food_group_code: categorySelection
    }).then(() => {
      navigate(`/products/${foodItemInput ? foodItemInput : brandInput}`);
    }).catch((err) => {
      console.error('Set searchResultsRequestBody failed!', err);
      alert('Set search input parameters failed, please contact us so we can fix it for you.');
    });
  }

  // header SearchBar
  if (props.isHeaderSearchBar) {
    return <div className={styles.SearchBar}>
      <div className="ShowFor900BigScreenOnly">
        <HeaderSearchBarForBigScreen handleSubmit={handleSubmit}
                                     foodItemInput={foodItemInput} handleFoodItemInputChange={handleFoodItemInputChange}
                                     brandInput={brandInput} handleBrandInputChange={handleBrandInputChange}
                                     categorySelection={categorySelection} handleCategorySelectionChange={handleCategorySelectionChange}/>
      </div>
      <div className="ShowFor900SmallScreenOnly">
        <HeaderSearchBarForSmallScreen handleSubmit={handleSubmit}
                                       foodItemInput={foodItemInput} handleFoodItemInputChange={handleFoodItemInputChange}
                                       brandInput={brandInput} handleBrandInputChange={handleBrandInputChange}
                                       categorySelection={categorySelection} handleCategorySelectionChange={handleCategorySelectionChange}/>
      </div>
    </div>
  }

  // home search bar
  return <div className={styles.SearchBar} data-testid="SearchBar">
    <Paper component="form"
           onSubmit={handleSubmit}
           sx={{ p: {xs: '6px', md: '13px'},
             display: 'flex',
             alignItems: 'center',
             background: {xs: '#E7EFEB 50% 40%', md: '#FFFFFF 0% 0%'},
             boxShadow: '0px 10px 30px #4F4F4F17',
             borderRadius: {xs: '32px', md: '60px'},
             border: '0.5px solid #70707080' }}
    >
      <Grid container spacing={2}>
        {/* food item */}
        <Grid item xs={12} md={4.2}>
          <div className={styles.SearchBarSection}>
            <Tooltip title="Food item is required" placement="top" enterTouchDelay={0}>
              <div className={styles.SearchBarFindFoodSection}>
                <div className={styles.SearchBarSectionLabel}>Food item
                  <span>&nbsp;&nbsp;
                      <img src={`data:image/svg+xml;base64,${btoa(QuestionIcon)}`} alt="Item requires Category" style={{height: "12px"}}/>
                  </span>
                </div>
                <FoodInputAutoComplete isHeader={false} value={foodItemInput} handleInputChange={handleFoodItemInputChange}/>
              </div>
            </Tooltip>
            <Divider className="ShowFor900BigScreenOnly" sx={{ height: 70, borderColor: 'rgba(0, 0, 0, 0.25)', ml: 1 }} orientation="vertical" />
            <Divider className="ShowFor900SmallScreenOnly" sx={{ width: '100%', margin: '10px 0', borderColor: 'rgba(0, 0, 0, 0.15)'}} orientation="horizontal" />
          </div>
        </Grid>

        {/* brand */}
        <Grid item xs={12} md={2.5}>
          <div className={styles.SearchBarSection}>
            <div style={{width: '100%'}}>
              <div className={styles.SearchBarSectionLabel}>Brand</div>
              <BrandInputAutoComplete isHeader={false} value={brandInput} handleInputChange={handleBrandInputChange}/>
            </div>
            <Divider className="ShowFor900BigScreenOnly" sx={{ height: 70, borderColor: 'rgba(0, 0, 0, 0.25)', ml: 1 }} orientation="vertical" />
            <Divider className="ShowFor900SmallScreenOnly" sx={{ width: '100%', margin: '10px 0', borderColor: 'rgba(0, 0, 0, 0.15)'}} orientation="horizontal" />
          </div>
        </Grid>

        {/* category */}
        <Grid item xs={12} md={2.5}>
          <div className={styles.SearchBarSection} style={{flexDirection: "row", paddingTop: "12px"}}>
            <div className={styles.SearchBarCategorySection}>
              <div className={styles.SearchBarSectionLabel}>Category
                {/*<span hidden={foodItemInput === '' || (brandInput !== null && brandInput !== '')} style={{color: "red"}}> *</span>*/}
              </div>
              <CategorySelect isHeader={false} value={categorySelection} handleChange={handleCategorySelectionChange}/>
            </div>
          </div>
        </Grid>

        {/* search icon */}
        <Grid item xs={12} md={2.8}>
          <button className={`${styles.SearchBarSectionButton} ${!enableSearch(foodItemInput, brandInput, categorySelection) ? styles.SearchBarDisabled : ''}`}
                  type={"submit"} disabled={!enableSearch(foodItemInput, brandInput, categorySelection)}>Search</button>
        </Grid>
      </Grid>

    </Paper>
  </div>;
}

export default SearchBar;
