import React, { FC } from 'react';

import {
  Box,
  Divider,
  Menu,
  MenuItem,
  SwipeableDrawer
} from "@mui/material";
import {Link} from "react-router-dom";

import styles from './MyMenu.module.css';
import menuIcon from "../../resources/Home/header/menu icon.svg";
import logo from "../../logo.svg";

interface MyMenuProps {
  toggleMyListsDrawer: any;
}

const MyMenu: FC<MyMenuProps> = (props) => {
  // Menu state for desktop view
  const [anchorMenu, setAnchorMenu] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorMenu);
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorMenu(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorMenu(null);
  };

  // drawer for mobile view
  const [menuDrawerState, setMenuDrawerState] = React.useState(false);
  const toggleMenuDrawer = (newState: boolean) => () => {
    setMenuDrawerState(newState);
  };

  return <div className={styles.MyMenu} data-testid="Menu">
    <img src={menuIcon} className={`${styles.MenuIcon} ShowFor1200BigScreenOnly`} alt="icon" onClick={handleOpenMenu}/>
    <img src={menuIcon} className={`${styles.MenuIcon} ShowFor1200SmallScreenOnly`} alt="icon" onClick={toggleMenuDrawer(true)}/>

    <Menu
      anchorEl={anchorMenu}
      id="account-menu"
      open={openMenu}
      onClose={handleCloseMenu}
      onClick={handleCloseMenu}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 12px 30px #00000017)',
          mt: '-40px',
          borderRadius: '24px',
          padding: '10px'
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <MenuItemList toggleMyListsDrawer={props.toggleMyListsDrawer} triggerType={"menu"}/>
    </Menu>

    <SwipeableDrawer
      anchor='right'
      open={menuDrawerState}
      onClose={toggleMenuDrawer(false)}
      onOpen={toggleMenuDrawer(true)}
      BackdropProps={{ style: { opacity: 0.1 } }}
    >
      <Box
        sx={{ width: 270, height: "100%" }}
        role="presentation"
        onClick={toggleMenuDrawer(false)}
        onKeyDown={toggleMenuDrawer(false)}
      >
        <MenuItemList toggleMyListsDrawer={props.toggleMyListsDrawer} triggerType={"drawer"}/>
      </Box>
    </SwipeableDrawer>
  </div>
};

function MenuItemList(props: {toggleMyListsDrawer: any, triggerType: string}) {
  return <div>
    {/*Logos*/}
    {
      (props.triggerType === 'drawer') &&
      <div className={styles.MenuItemListHead} >
          <img src={logo} className={styles.MenuLogo} alt="logo"/>
          <img src={menuIcon} className={styles.MenuIcon} alt="icon"/>
      </div>
    }
    {/*My Lists*/}
    <div className="ShowFor1200SmallScreenOnly">
      <MenuItem onClick={props.toggleMyListsDrawer(true)}>
        <div className={styles.MenuItemBold}>My Lists</div>
      </MenuItem>
    </div>
    {/*Other pages*/}
    <MenuItem component={Link} to="/about"><div className={styles.MenuItemBold}>About</div></MenuItem>
    <MenuItem component={Link} to="/learn"><div className={styles.MenuItemBold}>Learn</div></MenuItem>
    <MenuItem component={Link} to="/meet-experts"><div className={styles.MenuItemBold}>Our Experts</div></MenuItem>
    <Divider />
    <MenuItem component={Link} to="/faq"><div className={styles.MenuItem}>FAQs</div></MenuItem>
    <MenuItem component={Link} to="/contact-us"><div className={styles.MenuItem}>Contact Us</div></MenuItem>
    <MenuItem component={Link} to="/terms-of-use"><div className={styles.MenuItem}>Terms Of Use</div></MenuItem>
  </div>;
}

export default MyMenu;
