import React from 'react';
import styles from './FAQ.module.css';
import {Box, Grid, Link} from "@mui/material";

import gettingStartedIcon from "../../resources/FAQs/getting started icon.svg";
import usingStarSearchIcon from "../../resources/FAQs/using star search icon.svg";
import healthStarRatingIcon from "../../resources/FAQs/HSR icon.svg";
import troubleShootingIcon from "../../resources/FAQs/trouble shooting icon.svg";
import openIcon from "../../resources/FAQs/open icon.svg";
import closeIcon from "../../resources/FAQs/close icon.svg";
import ExampleImg from "../../resources/FAQs/example.png";

export default function FAQ() {
  const [selectedPanelName, setSelectedPanelName] = React.useState<string>("Getting Started");
  const [selectedParagraphSummary, setSelectedParagraphSummary] = React.useState<string>("");

  function handleDisplay(newPanelName: string) {
    setSelectedPanelName(newPanelName === selectedPanelName ? "" : newPanelName);
  }

  function handleExpand(newSummaryName: string) {
    setSelectedParagraphSummary(newSummaryName === selectedParagraphSummary ? "" : newSummaryName);
  }

  const panels = [
    {
      title: "Getting Started",
      icon: gettingStartedIcon,
      cardText: "Lorem ipsum dolor sit amet, consetetur ipsum dolor sit amet",
      paragraphs: [
        {
          summary: "What is Star Search?",
          details: `<div><p>Star Search was developed by the National Institute for Health Innovation (NIHI) to help New Zealand shoppers make healthier food choices. Users can find the Health Star Rating and nutrition information for most packaged food or non-alcoholic drinks. Star Search was developed as part of the Dietary Interventions Evidence & Transition (DIET) programme, a five-year programme of research generating information on the most effective and cost-efficient ways to improve population diets and health.</p></div>`,
        },
        {
          summary: "How are Star Search data collected?",
          details: `<div>Star Search includes food and beverage products from a database (Nutritrack) managed and maintained by the <a href="https://www.nihi.auckland.ac.nz/nutrition" target='_blank'>National Institute for Health Innovation (NIHI)</a>. The database is updated once each year with all the latest nutrition information for foods and drinks sold at popular supermarkets in New Zealand. Data are collected for ~15,000 packaged foods and non-alcoholic drinks on sale at four major supermarket retailers. photographs are taken of all packaged products sold across four stores. The photos provide a record of package, labelling, and nutritional information, which are stored in the Nutritrack database. Quality checks and reports are used to pick up common data entry and food categorisation errors. The Star Search website is updated once annually, usually mid-year when the annual Nutritrack data collection is finished. This means that nutrition information and the Health Star Ratings in Star Search might need updating if something changes between data collection periods. Don’t worry – we will update all information after each annual data collection.</div>`,
        },
        {
          summary: "What else is Nutritrack used for?",
          details: `<div>
                      <p>Nutritrack is a rich database of information on the nutrition content, ingredients, and labelling of New Zealand packaged foods and beverages. Nutritrack is used to address important research questions including:</p>
                      <ul>
                        <li>How is the nutritional composition of New Zealand foods changing over time?</li>
                        <li>What dietary interventions and programmes improve the nutritional quality of New Zealand packaged foods?</li>
                        <li>What are the potential health impacts of certain dietary patterns and nutrition interventions?</li>
                      </ul>
                      <p>For more detailed information on Nutritrack-related research and publications visit the DIET website.</p>
                    </div>`,
        },
        {
          summary: "Who is the National Institute for Health Innovation?",
          details: `<div><p>The <a href="https://www.nihi.auckland.ac.nz/nutrition" target='_blank'>National Institute for Health Innovation (NIHI)</a> is a leading New Zealand research institute at The University of Auckland. NIHI’s research focuses on the prevention and management of common serious diseases and effective healthcare. Research areas include nutrition, eHealth & mHealth, Tobacco & Addictions, , Physical Activity and Cardiovascular research. To learn more about the NIHI nutrition team and programmes, including DIET </p></div>`,
        },
      ]
    },
    {
      title: "Using Star Search",
      icon: usingStarSearchIcon,
      cardText: "Lorem ipsum dolor sit amet, consetetur ipsum dolor sit amet",
      paragraphs: [
        {
          summary: "How do I use Star Search?",
          details: `<div>Type the name of a product into the search field. You can make your search more specific by entering both the product name and the brand name. The search results will display up to 10 products that are most relevant to your search terms.</div>`,
        },
        {
          summary: "What if I can’t find a product?",
          details: `<div>
                      <p>If your product is not included in the search results, first try changing the words you enter into the search bar. You can make your search more specific by including both brand name and product name. If you still can’t find your product, <span style="color: #29A26E"> let us know </span>. As Star Search is updated annually with products from a sample of New Zealand supermarkets, it is possible that the product you are searching for has not been added yet to Star Search. Your feedback is appreciated, however we cannot guarantee we will be able to add all suggested products to Star Search. Please note that Health Star Rating information is not available in Star Search for some product types:</p>
                      <ul>
                        <li>Multipacks, for example, yoghurt packs with multiple flavours, and food kits</li>
                        <li>Alcoholic drinks</li>
                        <li>Products for which the Health Star Rating system is not suitable such as tea, coffee and unpackaged foods.</li>
                      </ul>
                      <p>For more detailed information on Nutritrack-related research and publications visit the DIET website.</p>
                    </div>`,
        },
      ]
    },
    {
      title: "Health Star Rating",
      icon: healthStarRatingIcon,
      cardText: "Lorem ipsum dolor sit amet, consetetur ipsum dolor sit amet",
      paragraphs: [
        {
          summary: "What is the Health Star Rating system?",
          details: `<div>The Health Star Rating system is a government-led nutrition labelling system. It rates the overall nutrition content and healthiness of packaged foods and drinks from half a star to 5 stars, with 5 stars being the healthiest. To find out more, visit the website.</div>`,
        },
        {
          summary: "How are Health Star Ratings calculated?",
          details: `<div>Health Star Ratings are calculated using a standard method involving a number of steps which result in an overall score for each product. The score take account of the levels of nutrients of concern (energy, saturated fat, sugars, and sodium) and positive components such as fibre and protein. The fruit and vegetable content of a product is also a part of the score. The  has detailed information on how Health Star Ratings are calculated, and an online calculator for manufacturers is available. The estimated Health Star Ratings displayed in Star Search are calculated using the same methods manufacturers use. However, the rating is an estimate because some of the required nutrition information (e.g., fruit and vegetable content) may not be available on-pack and therefore need to be estimated.</div>`,
        },
        {
          summary: "What is the difference between an actual and an estimated Health Star Rating?",
          details: `<div>Some products in Star Search display an actual Health Star Rating while for others the rating is estimated. As the Health Star Rating system is voluntary, not on all packaged foods and beverages in New Zealand display the Health Star Rating on the package. In Star Search, an actual Health Star Rating is shown for products if the Health Star Rating label is displayed on-pack by the manufacturer. You will see a Health Star Rating on the packaging of these products while shopping. An estimated Health Star Rating is displayed for products which do not display the Health Star Rating label on the package. Estimated Health Star Ratings are calculated by our nutrition team using the same method used by manufacturers. However, the rating is an estimate because some of the required nutrition information may not be available on-pack and therefore need to be estimated. For more information on how Health Star Ratings are calculated see the FAQ “How are Health Star Ratings Calculated”?</div>`,
        },
        {
          summary: "Are there different formats of the Health Star Rating label?",
          details: `<div>
                      <p>Yes, Health Star Ratings can appear on food packages in different forms. Some foods only carry the overall Health Star Rating, whilst others have the Health Star Rating plus information about specific nutrients.</p>
                      <p>Source: this information was sourced from the <a href="https://www.mpi.govt.nz/food-business/labelling-composition-food-drinks/health-star-ratings-food-labelling/calculating-and-applying-health-star-ratings/" target="_blank">Ministry for Primary Industries website.</a></p>
                      <p>Star Search results show:</p>
                      <ul>
                        <li>The overall Health Star Rating (0.5, 1.0, 1.5, 2.0 ... 5.0)</li>
                        <li>The amount of energy, saturated fat, total sugars, and sodium. All nutrients are displayed per 100g/mL
                          <p><img src=${ExampleImg} alt="example" style="width: 80%"/></p>
                          <p><b>Note</b>: the Health Star Rating format displayed in Star Search may differ slightly from that used by the manufacturer.</p>
                        </li>
                      </ul>
                    </div>`,
        },
        {
          summary: "Why don’t all foods have a Health Star Rating?",
          details: `<div>
                      <p>Health Star Ratings are suitable for most packaged foods but are not required on foods that:</p>
                      <ul>
                        <li>Do not need a Nutrition Information Panel, e.g., tea, coffee or single ingredient products like flour</li>
                        <li>Are not packaged, e.g., fresh fruits and vegetables</li>
                      </ul>
                      <p>In Star Search, Health Star Rating information is not available for some product types:</p>
                      <ul>
                        <li>Multipacks, for example, yoghurt packs with multiple flavours, and food kits</li>
                        <li>Alcoholic drinks</li>
                      </ul>
                      <p>The Ministry for Primary Industries has also said that the Health Star Rating system is not intended to be used on some specific products. For more information</p>
                      <p><b>Source</b>: this information was taken from the <a href="https://www.mpi.govt.nz/food-business/labelling-composition-food-drinks/health-star-ratings-food-labelling/calculating-and-applying-health-star-ratings/" target="_blank">Ministry for Primary Industries website.</a></p>
                    </div>`,
        },
        {
          summary: "Why don’t fresh fruit and vegetables have a Health Star Rating?",
          details: `<div>
                      <p>Health Star Ratings aren't intended for use on unpackaged foods, like fresh fruits and vegetables. It is important to remember however that fresh, whole foods are the basis of a healthy diet.</p>
                      <p><b>Source</b>: this information was taken from the <a href="https://www.mpi.govt.nz/food-business/labelling-composition-food-drinks/health-star-ratings-food-labelling/calculating-and-applying-health-star-ratings/" target="_blank">Ministry for Primary Industries website.</a></p>
                    </div>`,
        },
      ]
    },
    {
      title: "Trouble Shooting",
      icon: troubleShootingIcon,
      cardText: "Lorem ipsum dolor sit amet, consetetur ipsum dolor sit amet",
      paragraphs: [
        {
          summary: "What if I can’t find a product?",
          details: `<div>
                      <p> If your product is not included in the search results, first try changing the words you enter into the search bar. You can make your search more specific by including both brand and product name. If you still can’t find your product, As Star Search is updated annually with products from a sample of New Zealand supermarkets, it is possible that the product you are searching for has not yet been added to Star Search. Your feedback is appreciated, however, we cannot guarantee we will be able to add all suggested products to Star Search. Please note that Health Star Rating information is not available in Star Search for some product types:</p>
                      <ul>
                        <li>Multipacks, for example, yoghurt packs with multiple flavours, and food kits</li>
                        <li>Alcoholic drinks</li>
                        <li>Products for which the Health Star Rating system is not suitable.</li>
                      </ul>
                      <p>For more detailed information on Nutritrack-related research and publications visit the DIET website.</p>
                    </div>`,
        },
        {
          summary: "I’ve noticed that some product information is incorrect? What should I do?",
          details: `<div>Star Search sources data from a database (Nutritrack) managed and maintained by the. NIHI has strict data quality control processes, however errors are always a possibility. If you identify an error please let us know using the “Info Incorrect” button next to the product in question. We will then recheck the product and make changes where appropriate. Star Search is updated once a year , once the annual Nutritrack data collection has been completed. This means that if a product’s nutritional composition and labelling (including the presence of a Health Star Rating) has changed between data collection periods, it will not be reflected in Star Search until the next annual data collection is done and Star Search is updated.</div>`,
        },
      ]
    },
  ];

  return (
    <div className={styles.FAQ} data-testid="FAQ">
        {/* title */}
        <br/>
        <div className="TextTitle">FAQs</div>
        <br/>
        {/* content */}
        <Grid container columnSpacing={10} rowSpacing={4}>
          {/* 4 cards */}
          <Grid item xs={12} lg={7}>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
              }}
            >
              {
                panels.map((panel, i) => (
                  <Card key={i} title={panel.title} icon={panel.icon} content={panel.cardText} selectedPanelName={selectedPanelName} handleDisplay={handleDisplay} />
                ))
              }
            </Box>
          </Grid>
          {/* accordion list */}
          <Grid item xs={12} lg={5}>
            <div style={{textAlign: "left"}}>
              {
                panels.map((panel, i) => (
                  (panel.title === selectedPanelName) &&
                  <div key={i}>
                      <div className="TextHeading1" style={{paddingBottom: "26px"}}>{panel.title}</div>
                    {
                      panel.paragraphs.map((paragraph, j) => (
                        <Details key={j} title={panel.title} summary={paragraph.summary} text={paragraph.details} selectedParagraphSummary={selectedParagraphSummary} handleExpand={handleExpand}/>
                      ))
                    }
                  </div>
                ))
              }
              <br/>
            </div>
          </Grid>
        </Grid>
    </div>
  );
}

function Card(props: {title: string; icon: string; content: string; selectedPanelName: string; handleDisplay: any}) {
  return <Box className={props.title === props.selectedPanelName ? styles.CardSelected : styles.Card}
              onClick={() => props.handleDisplay(props.title)}>
    <Link href={"#" + props.title} underline="none">
      <div style={{padding: "30px 20px"}}>
        <img className={props.title === props.selectedPanelName ? styles.CardSelectedIcon : styles.CardIcon} src={props.icon} alt="icon"/>
        <br/>
        <div>
          <div className={`TextHeading2 ${styles.CardTitle}`}>{props.title}</div>
          <div className={`TextParagraph4 ${styles.CardContent}`} dangerouslySetInnerHTML={{__html: props.content}}/>
        </div>
      </div>
    </Link>
  </Box>;
}

function Details(props: {title: string; summary: string; text: string; selectedParagraphSummary: string; handleExpand: any}) {
  return <div id={props.title}>
    <div className={`TextParagraph2 ${styles.Summary}`} onClick={() => props.handleExpand(props.summary)}>
      <span>{props.summary}</span>
      <img src={props.summary === props.selectedParagraphSummary ? closeIcon: openIcon} alt="expand icon"/>
    </div>
    { props.summary === props.selectedParagraphSummary && <div className={`TextParagraph4 ${styles.Detail}`} dangerouslySetInnerHTML={{__html: props.text}}/> }
  </div>
}
