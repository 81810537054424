import React, { FC } from 'react';
import styles from './ExpertChip.module.css';
import {Avatar} from "@mui/material";

interface ExpertChipProps {
  avatar: string;
  name: string;
  designation: string;
  small?: boolean;
}

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string, small?: boolean) {
  return {
    sx: {
      bgcolor: small ? stringToColor(name) : "#FFF",
      width: small ? "clamp(35px, 1.62vw + 29px, 60px)" : "clamp(68px, 4.53vw + 51px, 138px)",
      height: small ? "clamp(35px, 1.62vw + 29px, 60px)" : "clamp(68px, 4.53vw + 51px, 138px)",
      padding: small ? 0 : "clamp(16px, 0.65vw + 13.6px, 18px)",
      marginLeft: small ? 0 : "clamp(5px, 0.39vw + 3.5px, 11px)"
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

const ExpertChip: FC<ExpertChipProps> = (props) => (
  <div className={props.small ? styles.ExpertChipSmall: styles.ExpertChip} data-testid="ExpertChip">
    <div style={{margin: "auto 0"}}>
      <Avatar src={props.avatar} alt={props.name} {...stringAvatar(props.name, props.small)}
      />
    </div>

    <div className={props.small ? styles.ChipTextSmall: styles.ChipText}>
      <div className={styles.ChipName}>{props.name}</div>
      <div className={styles.ChipTitle}>{props.designation}</div>
    </div>
  </div>
);

export default ExpertChip;
