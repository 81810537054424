import React, {FC, useContext} from 'react';
import {useParams} from "react-router-dom";
import styles from './SearchResults.module.css';

import {Alert, AlertTitle, Box, CircularProgress, FormControl, Grid, MenuItem, Select} from "@mui/material";
import ProductBriefPanel, {ProductBriefProps} from "../../components/ProductBriefPanel/ProductBriefPanel";
import SearchResultsContext from "../../services/ProductSearchResultsService";
import PopularSearches from "../../components/PopularSearches/PopularSearches";

import NavLeftIcon from "../../resources/Search Results/navigation left icon.svg";
import NavRightIcon from "../../resources/Search Results/navigation right icon.svg";
import ScrollTopIcon from "../../resources/Search Results/scroll to top.svg";

interface SearchResultsProps {}

const DownwardArrowIcon = (props: any) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="Group_3760" data-name="Group 3760" transform="scale(0.8) translate(-156 -8.07)">
        <path id="Path_1906" data-name="Path 1906" d="M615.213,72.046l4.136,4.646,4.136-4.646" transform="translate(-448.713 -50.475)" fill="none" stroke="#29A26E" strokeLinecap="round" strokeWidth="2"/>
        <g id="Ellipse_29" data-name="Ellipse 29" transform="translate(156 8.07)" fill="none" stroke="#29A26E" strokeWidth="1">
          <circle cx="15" cy="15" r="14.5" fill="none"/>
        </g>
      </g>
    </svg>
);

const SearchResults: FC<SearchResultsProps> = () => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const pageSize = 12;

  const [sortBy, setSortBy] = React.useState('Relevant');
  const { searchText } = useParams();

  if (!searchText) {
    throw new Error("Need the search text as URL parameter.");
  }

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleSortByChange = (event: any) => {
    setSortBy(event.target.value.toString());
  };

  const scrollToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  const mySort = (products: ProductBriefProps[]) => {
    const sortedProducts: ProductBriefProps[] = JSON.parse(JSON.stringify(products));
    if (sortBy === 'H2L') {
      return sortedProducts.sort((a, b) => (a.product_hsr_score < b.product_hsr_score ? 1 : -1));
    } else if (sortBy === 'L2H') {
      return sortedProducts.sort((a, b) => (a.product_hsr_score > b.product_hsr_score ? 1 : -1));
    }
    return sortedProducts;
  }

  // get data from context
  const searchResultsCtx = useContext(SearchResultsContext);
  if (!searchResultsCtx) {
    throw new Error("Context must be used within a Provider");
  }

  if (searchResultsCtx.isLoading) {
    return <CircularProgress />;
  }

  if (searchResultsCtx.receiveError) {
    return <Alert variant="filled" severity="error" className="FullWindowWidth" style={{textAlign: "left"}}>
      <AlertTitle>Error</AlertTitle>
      Fetch products failed. — <strong>Try it again later!</strong>
      <div>If it happens again, please contact us by email (starsearch@auckland.ac.nz) so we can fix it for you.</div>
    </Alert>;
  }

  // sort products then slice for the currentPage
  const relevantProductList = searchResultsCtx.products;
  const sortedProductList = mySort(relevantProductList);
  const totalPages = Math.ceil(sortedProductList.length / pageSize);

  // for current page only
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentProducts = sortedProductList.slice(startIndex, endIndex);

  return <div className="FullWindowWidth" data-testid="SearchResults">
    <div style={{background: "#FAFAFA", marginTop: "3px"}}>
      <div className="Page">
        <div className="Container1920">
          <br/><br/>
          {/* Title & Sort */}
          <div className={styles.TitleSortContainer}>
            <div className="TextBigHeading" style={{ marginTop: "10px" }}>
              {relevantProductList.length} Results { searchText && ` for "${searchText}" `}
              <div className={styles.RefineSearchText}>Refine your search criteria for more tailored results.</div>
            </div>

            <div className={styles.SortContainer}>
              <div className={styles.SortText} style={{fontWeight: "500", marginRight: "clamp(5px, 0.5vw + 3px, 12px)"}}>Sort by</div>
              <FormControl variant="standard" sx={{
                minWidth: "clamp(250px, 7vw + 223px, 350px)",
                textAlign: "left",
                '& .MuiInput-underline:before': {
                  border: 'none !important'
                },
                '& .MuiInput-underline:after': {
                  border: 'none !important'
                }
              }}>
                <Select
                  value={sortBy}
                  onChange={handleSortByChange}
                  IconComponent={DownwardArrowIcon}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  sx={{
                    "& .MuiSelect-icon": {
                      top: "calc(50% - 0.7em)",
                      right: "7px",
                    },
                    "& .MuiInputBase-input": {
                      padding: '10px 26px 10px 12px',
                      paddingRight: '34px !important',
                      borderRadius: "6px",
                      position: 'relative',
                      border: '1px solid rgba(112, 112, 112, 0.32)',
                      font: 'normal normal normal clamp(12px, 0.52vw + 10px, 20px)/clamp(17px, 0.65vw + 15px, 27px) Noto Sans, sans-serif !important',
                      '&:focus': {
                        backgroundColor: 'transparent'
                      }
                    }
                  }}
                >
                  <MenuItem value={"Relevant"}>Relevant</MenuItem>
                  <MenuItem value={"H2L"}>Health Star Rating:&nbsp;<span style={{fontWeight: "700"}}>High to Low</span></MenuItem>
                  <MenuItem value={"L2H"}>Health Star Rating:&nbsp;<span style={{fontWeight: "700"}}>Low to High</span></MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <br/>
          {/* Product Results */}
          <Grid container spacing={3} rowSpacing={{xs: 2, md: 3}}>
            {
              currentProducts.map((product, i) => (
                <Grid item xs={12} md={6} lg={4} xl={3} key={i}>
                  <Box sx={{alignItems: "center", justifyContent: 'space-between'}}>
                    <ProductBriefPanel item={product}/>
                  </Box>
                </Grid>
              ))
            }
          </Grid>

          {/* Page navigation text */}
          <Box sx={{ marginTop: 2, textAlign: 'center' }} hidden={totalPages < 2}>
            <div className={styles.PageNavText}>
              <span style={{ cursor: currentPage === 1 ? 'not-allowed' : 'pointer', opacity: currentPage === 1 ? 0.5 : 1 }}
                  onClick={() => handlePageChange(currentPage - 1)} >
                <img src={NavLeftIcon} alt="nav to prev"/>&nbsp;
                Prev
              </span>
              {Array.from({ length: totalPages }, (_, i) => (
                  <span key={i} style={{ cursor: currentPage === i + 1 ? 'not-allowed' : 'pointer',
                    color: currentPage === i + 1 ? '#29A26E' : 'rgba(65, 65, 65, 0.60)',
                    textDecorationLine: currentPage === i + 1 ? 'underline' : 'unset'}}
                        onClick={() => handlePageChange(i + 1)}>
                    {i + 1}
                  </span>
              ))}
              <span style={{ cursor: currentPage === totalPages ? 'not-allowed' : 'pointer', opacity: currentPage === totalPages ? 0.5 : 1 }}
                    onClick={() => handlePageChange(currentPage + 1)}>
                Next
                &nbsp;<img src={NavRightIcon} alt="nav to next"/>
              </span>
            </div>
          </Box>

          <img src={ScrollTopIcon} alt="scroll to top" className={styles.FloatingBtn} onClick={scrollToTop}/>
        </div>
      </div>

      <br/><br/>
    </div>

    {/* Popular Search */}
    <PopularSearches/>
  </div>;
}

export default SearchResults;
