import React, {FC, useRef} from 'react';
import styles from './ImageWithParagraphs.module.css';
import {Grid, Link, Slider, styled} from "@mui/material";
import { Scrollspy } from "@makotot/ghostui";

import closeIcon from "../../resources/Home/What is the Health Star Rating/closeIcon.svg";
import expandIcon from "../../resources/Home/What is the Health Star Rating/expandIcon.svg";
import {Link as RouterLink} from "react-router-dom";

interface ImageWithParagraphProps {
  id: string;
  navLinkText: string;
  image: string;
  paragraphTitle: string;
  paragraphTitleSmallScreen?: string;
  paragraphContent: string;
}

interface ImageWithParagraphsProps {
  paragraphs: ImageWithParagraphProps[];
}

function ImageWithParagraph (props: {paragraph: ImageWithParagraphProps, index: number}) {
  return <div className={`Page ${styles.ParagraphView}`}>
    <br/><br/><br/>
    <Grid container style={{textAlign: "left"}}>
      <Grid item xs={12} md={6}>
        {/*eslint-disable-next-line*/}
        <img className={styles.Image} src={props.paragraph.image} alt="`${paragraph.id} Img`"/>
      </Grid>
      <Grid item xs={12} md={6}>
        <br/><br/>
        <div className={styles.ParagraphTitle} dangerouslySetInnerHTML={{__html: props.paragraph.paragraphTitle}}/>
        <br/><br/><br/>
        <div className={styles.ParagraphText} dangerouslySetInnerHTML={{__html: props.paragraph.paragraphContent}}/>
      </Grid>
    </Grid>
  </div>;
}

function Details(props: {paragraph: ImageWithParagraphProps, index: number, selectedParagraphIndex: number, handleSelect: any}) {
  const [showLess, setShowLess] = React.useState(true);
  const selected = props.index === props.selectedParagraphIndex;

  return <div className="Page">
    { selected && <div>
        <br/>
        <div className={styles.ParagraphTitle} dangerouslySetInnerHTML={{__html: props.paragraph.paragraphTitleSmallScreen ? props.paragraph.paragraphTitleSmallScreen: props.paragraph.paragraphTitle}}/>
        <br/><br/>
    </div>
    }
    <div className={styles.ParagraphSummary} onClick={() => props.handleSelect(props.index)}>
      <span>{props.paragraph.navLinkText}</span>
      <img src={ selected ? closeIcon: expandIcon } alt="expand icon"/>
    </div>
    { selected &&
      <div>
        <div className={styles.ParagraphText} dangerouslySetInnerHTML={{
          __html: showLess ? `${props.paragraph.paragraphContent.slice(0, 150)}...` : props.paragraph.paragraphContent
        }}/>
        <div style={{textAlign: "left"}}>
            <div className={styles.ParagraphTextReadMore} onClick={() => setShowLess(!showLess)}>
              {showLess ? "Read More" : "Read Less"}
            </div>
        </div>
        <br/>
        {/*eslint-disable-next-line*/}
        <img className={styles.Image} src={props.paragraph.image} alt="`${paragraph.id} Img`"/>
        <br/><br/>
      </div>
    }
    <hr style={{color: "#F6FBF8"}}/>
  </div>;
}

function ReadOutMore() {
  return <div className="Page" style={{textAlign: "right", paddingBottom: "20px"}}>
    <RouterLink to="/learn"><button className="GreenButton">Find out more</button></RouterLink>
  </div>;
}

const ImageWithParagraphs: FC<ImageWithParagraphsProps> = (props) => {
  const [paragraphIndex, setParagraphIndex] = React.useState(0);

  function handleSelect(newIndex: number) {
    setParagraphIndex(newIndex === paragraphIndex ? -1 : newIndex);
  }

  const sectionRefs = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null)
  ];

  const MySlider = styled(Slider)({
    height: '33vh',
    color: '#D8D8D8 !important',
    '& .MuiSlider-rail': {
      background: "#E8E8E8",
      borderRadius: 12,
      opacity: 0.62,
      width: 14,
      padding: 0,
    },
    '& .MuiSlider-track': {
      background: '#D8D8D8',
      boxShadow: '0px 3px 6px #0000000D',
      borderRadius: 12,
      opacity: 10,
      padding: 0,
      width: 12,
    },
    '& .MuiSlider-thumb': {
      display: "none",
    },
    '& .MuiSlider-valueLabel': {
      display: "none"
    },
  });

  return <div className={`${styles.ImageWithParagraphs} Container1920`} data-testid="ImageWithParagraphs">
    {/* big screen */}
    <div className="ShowFor1200BigScreenOnly">
      {/*TODO 8 on some width, can't scroll accurately*/}
      <Scrollspy sectionRefs={sectionRefs} rootSelector="#innerScroller">
        {({ currentElementIndexInViewport }) => (
          <div id="innerScroller" style={{height: "90vh", overflowY: "scroll", marginRight: "-15px"}}>
            { /* img with text */
              props.paragraphs.map((paragraph, i) => (
                <div key={i} ref={sectionRefs[i]} id={`paragraph-${i}`}>
                  <ImageWithParagraph paragraph={paragraph} index={i}/>
                </div>))
            }
            {/* vertical slider */}
            <div className={styles.ParagraphSlider}>
              <MySlider
                getAriaLabel={() => 'Paragraph Indicator'}
                orientation="vertical"
                valueLabelDisplay="auto"
                step={1}
                min={0}
                max={3}
                value={[2 - currentElementIndexInViewport, 2 - currentElementIndexInViewport + 1]}
                disabled
              />
            </div>
            {/* nav links */}
            <div className={styles.NavLinksContainer}>
              <div className={styles.NavLinksContainerBg} />
              <div className={styles.NavLinks}>
                {props.paragraphs.map((paragraphLink, j) => (
                  <div className={currentElementIndexInViewport === j ? styles.SelectedNavLink : styles.NavLink} key={j}>
                    <Link href={`#paragraph-${j}`}>{paragraphLink.navLinkText}</Link>
                  </div>
                ))}
              </div>
            </div>
            <div style={{position: "relative", top: "-50px"}}>
              <ReadOutMore/>
            </div>
          </div>
        )}
      </Scrollspy>
    </div>

    {/* small screens */}
    <div className="ShowFor1200SmallScreenOnly">
      <br/>
      {props.paragraphs.map((paragraph, i) => (
        <Details paragraph={paragraph} index={i} selectedParagraphIndex={paragraphIndex} handleSelect={handleSelect} key={i}/>
      ))}
      <div>
        <br/>
        <ReadOutMore/>
        <br/>
      </div>
    </div>
  </div>;
}

export default ImageWithParagraphs;
