import React, {FC, useEffect, useState} from 'react';
import styles from './Home.module.css';

import HomeHeader from "../../components/Header/HomeHeader";
import SearchBar from "../../components/SearchBar/SearchBar";
import Carousel from "../../components/Carousel/Carousel";
import ImageWithParagraphs from "../../components/ImageWithParagraphs/ImageWithParagraphs";
import PopularSearches from "../../components/PopularSearches/PopularSearches";

import {Box, Grid} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";

import AccentImg from "../../resources/Home/accent_under_you_.png";
import DownwardArrowIcon from "../../resources/Home/downward arrow.svg";
import SettingsIcon from "../../resources/Home/Star Search to Mobile/settings icon.svg";
import ShareIcon from "../../resources/Home/Star Search to Mobile/share icon.svg";

import timerIcon from "../../resources/Home/What is Star Search/timer-icon.svg";
import badgeIcon from "../../resources/Home/What is Star Search/badge_icon.svg";
import starIcon from "../../resources/Home/What is Star Search/star_icon.svg";

import basicsImg from "../../resources/Home/What is the Health Star Rating/the_basics_image.png";
import calculateImg from "../../resources/Home/What is the Health Star Rating/how_its_calculated_image.png";
import whoImg from "../../resources/Home/What is the Health Star Rating/who_made_it_image.png";
import {scrollToElementConsiderHeader} from "../../modules/helper";


interface HomeProps {}

function FindFoodSection() {
  const isShortScreen = window.innerHeight <= 900;
  // when scroll down over 87px, sticky header use another style
  const [headerClassName, setHeaderClassName] = useState("HomeHeader");

  const listenScrollEvent = () => {
    window.scrollY < 87 ? setHeaderClassName("HomeHeader") : setHeaderClassName("HomeHeaderGreen");
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  return <section id="find" className={styles.FindFoodSection}>
    {
      /* Home Header for small screen */
      <div className="ShowFor1200SmallScreenOnly">
        <div className={styles.HomeHeaderSpaceTaken}/>
        <div className={styles.HomeHeader}>
          <HomeHeader/>
        </div>
      </div>
    }
    <div className={styles.HomeFindFoodBg}>
      {
        /* Home Header for big screen */
        <div className="ShowFor1200BigScreenOnly">
          <div className={styles.HomeHeaderSpaceTaken}/>
          <div className={headerClassName === "HomeHeader" ? styles.HomeHeader : styles.HomeHeaderGreen}>
            <HomeHeader/>
          </div>
        </div>
      }
      <br/>
      <div className={`${styles.HomeFindFoodContent} Container1920`}
           style={{paddingTop: isShortScreen ? '20px' : 'calc(5.5vw - 20px)'}}>
        <div className="ShowFor900BigScreenOnly">
          <br hidden={isShortScreen}/>
          <br hidden={isShortScreen}/>
        </div>
        {/* Title */}
        <div className={styles.HomeTextTitle}>
          <span style={{marginRight: "calc(-1 * clamp(60px, 5.83vw + 38px, 150px))"}}>Find the foods that suit you</span>
          <span><img src={AccentImg} alt="accentImg" className={styles.HomeTextTitleAccentImg}/></span>
        </div>
        <br hidden={isShortScreen}/>
        <br hidden={isShortScreen}/>
        <br/>
        <Grid container alignItems="center" justifyContent="center" spacing={2}>
          <Grid item xs={11} md={10} xl={8}>
            <SearchBar />
          </Grid>
        </Grid>
        <br/>
        {/* Popular Searches */}
        <PopularSearches isHome={true}/>
      </div>
      <div className={styles.HomeFindFoodBgContainer}>
        {/* Scroll down button */}
        <div onClick={() => { scrollToElementConsiderHeader("save-to-mobile")} }>
          <img src={DownwardArrowIcon} alt="downward arrow icon" className={styles.HomeDownwardArrow}/>
        </div>
      </div>
    </div>

  </section>;
}

function SaveToMobileSection() {
  return <section id="save-to-mobile" className={`${styles.SaveToMobileSection} ${styles.SectionView} ${styles.HomeSaveToMobileSectionBgColor}`}>
    <div className={`${styles.HomeSaveToMobileSection} Container1920`}>
      <div className={`${styles.HomeMobileText} ${styles.HomeSectionTitleText}`}>Star Search To Mobile</div>
      <br/>
      <div className={`${styles.HomeMobileText} ${styles.HomeSaveToMobileSectionText}`}>
        Save Star Search on your mobile home screen to find better food choices anywhere.
      </div>
      <div className="ShowFor900BigScreenOnly">
        <br/>
      </div>
      <br/>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={12} xl={10.345}>
          <Box className={styles.HomeSaveToMobileSectionBox}>
            <Grid container columnSpacing={8} alignItems="center" justifyContent="center" style={{padding: "clamp(16px, 3.5vw + 3px, 70px) clamp(16px, 5.44vw - 4px, 100px)"}}>
              <Grid item xs={12} md={10} lg={6}>
                <div className={styles.HomeSaveToMobileTextTitle}>iOS (iPhone)</div>
                <div className={styles.HomeSaveToMobileText} style={{whiteSpace: "nowrap"}}>Open Star Search in Safari, tap
                  <img src={ShareIcon} alt="Share Icon" className={styles.HomeSaveToMobileShareIcon}/>
                  <br/>then "Add to Home Screen".
                </div>
              </Grid>
              <Grid item xs={12} md={10} lg={6} className={styles.HomeSaveToMobileSectionAndroid}>
                <div className="ShowFor1200SmallScreenOnly">
                  <br/>
                </div>
                <div className={styles.HomeSaveToMobileTextTitle}>Android or other</div>
                <div className={styles.HomeSaveToMobileText}>Just tap
                  <img src={SettingsIcon} alt="Settings Icon" className={styles.HomeSaveToMobileSettingIcon}/>
                  then "Add to Home Screen".
                </div>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </div>
  </section>;
}

const cards = [
  {
    title: "What is Star Search?",
    image: timerIcon,
    content: "Star Search was developed by the National Institute for Health Innovation (NIHI) to help New Zealand shoppers make healthier food choices. Users can find the Health Star Rating and nutrition information for most packaged food or non-alcoholic drinks. Star Search was developed as part of the Dietary Interventions Evidence & Transition (DIET) programme, a five-year programme of research generating information on the most effective and cost-efficient ways to improve population diets and health."
  },
  {
    title: "How do I get started",
    image: badgeIcon,
    content: "Star Search was developed to help New Zealanders make healthier food choices. Now you can find the Health Star Rating and nutrition information for most packaged food or non-alcoholic drinks. Enter the name of your packaged food or beverage product into the search field. Entering more information produces more accurate search results."
  },
  {
    title: "How does it benefit me?",
    image: starIcon,
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
  }
]

function WhatIsStarSearchSection() {
  return <section id="starsearch" className={styles.HomeWhatIsStarSearch}>
    <div className={styles.HomeWhatIsStarSearchBg}>
      <div className={styles.SectionView}>
        <div className={`${styles.HomePage} ${styles.HomeMobileText} ${styles.HomeSectionTitleText}`} style={{paddingRight: "120px"}}>What is StarSearch?</div>
        <div className="Container1920">
          <div style={{marginLeft: "clamp(0px, 7.77vw - 29px, 120px)"}}>
            <Carousel items={cards}/>
          </div>
        </div>
        <div className="Container1920" style={{textAlign: "right", paddingTop: "clamp(30px, 3.88vw + 15.5px, 90px)"}}>
          <div className={styles.HomePage}>
            <RouterLink to="/about"><button className="GreenButton">Find out more</button></RouterLink>
          </div>
        </div>
      </div>
    </div>
  </section>;
}

const paragraphs = [
  {
    id: "basics",
    navLinkText: "THE BASICS",
    image: basicsImg,
    paragraphTitle: `What are <br/><span style="color: #29A26E; white-space: nowrap;">Health Star Ratings?</span>`,
    paragraphTitleSmallScreen: `What are <span style="color: #29A26E">Health Star Ratings?</span>`,
    paragraphContent: `<div><p>The Health Star Rating system is a government-led nutrition labelling system. It rates the overall nutrition content and healthiness of packaged foods and drinks from half a star to 5 stars, with 5 stars being the healthiest. </p>
                            <p>The system is voluntary and Health Star Ratings are not present on all packaged foods and drinks – only those where a manufacturer chooses to display the star rating.</p></div>`
  },
  {
    id: "calculate",
    navLinkText: "HOW THEY ARE CALCULATED",
    image: calculateImg,
    paragraphTitle: `How are <span style="color: #29A26E">Health Star Ratings</span> calculated?`,
    paragraphContent: `<div><p>Health Star Ratings are calculated using a standard method involving a number of steps which result in an overall score for each product. The score take account of the levels of nutrients of concern (energy, saturated fat, sugars, and sodium) and positive components such as fibre and protein. The fruit and vegetable content of a product is also a part of the score. </p>
                            <p><a href="https://www.mpi.govt.nz/food-business/labelling-composition-food-drinks/health-star-ratings-food-labelling/calculating-and-applying-health-star-ratings/" target='_blank'>The Ministry for Primary Industries website</a> has detailed information on how Health Star Ratings are calculated, and an online calculator for manufacturers is available. The estimated Health Star Ratings displayed in Star Search are calculated using the same methods manufacturers use. However, the rating is an estimate because some of the required nutrition information (e.g., fruit and vegetable content) may not be available on-pack and therefore need to be estimated.</p></div>`
  },
  {
    id: "who",
    navLinkText: "HOW TO USE?",
    image: whoImg,
    paragraphTitle: `How do I use the <span style="color: #29A26E">Health Star Rating</span> information?`,
    paragraphContent: `<div><p>The Health Star Rating system is designed to provide simple information about how healthy a food is and to compare the healthiness of similar products e.g., to compare two types of breakfast cereal. Health Star Ratings shouldn’t be used to compare different types of foods e.g., breakfast cereal versus yoghurt. For more information visit <a href="https://www.mpi.govt.nz/food-business/labelling-composition-food-drinks/health-star-ratings-food-labelling/calculating-and-applying-health-star-ratings/" target='_blank'>The Ministry for Primary Industries website</a>.</p></div>`
  }
]

function WhatIsStarRatingSection() {
  return <section id="rating" className={styles.HomeWhatIsStarRating}>
    <ImageWithParagraphs paragraphs={paragraphs}/>
  </section>;
}

const Home: FC<HomeProps> = () => (
  <div className="FullWindowWidth" data-testid="Home">
    <FindFoodSection />
    <SaveToMobileSection />
    <WhatIsStarSearchSection />
    <WhatIsStarRatingSection />
  </div>
);

export default Home;
