import React, {FC, useContext} from 'react';
import styles from './Learn.module.css';

import {Alert, AlertTitle, Box, CircularProgress} from "@mui/material";
import { useNavigate } from "react-router-dom";

import ArticleListContext from "../../services/LearnService";
import { CarouselItemProps, default as Carousel } from "../../components/Carousel/Carousel";
import ArticleInfo from "../../components/ArticleInfo/ArticleInfo";
import ArrowLeftIcon from "../../resources/Learn/Icon ionic-ios-arrow-backward.svg";
import ArrowRightIcon from "../../resources/Learn/Icon ionic-ios-arrow-forward.svg";

interface LearnProps {}

export interface ArticleProps {
  id: string;
  image: string;
  title: string;
  content: string;
  authorAvatar: string;
  authorName: string;
  authorDesignation: string;
  timeToRead: number;
  tags: string;
  created: any;
}

function CardWithArticleInfo(props: {articleInfo: ArticleProps}) {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/article/${props.articleInfo.id}`);
  }

  return <Box id={props.articleInfo.id} className={styles.Card} onClick={handleClick}>
    <img src={props.articleInfo.image} alt="article img" className={styles.CardImg}/>
    <div className={`TextHeading2 ${styles.CardTitle}`}>{props.articleInfo.title}</div>
    <div className={`TextParagraph4 ${styles.CardContent}`} dangerouslySetInnerHTML={{__html: `${props.articleInfo.content.slice(0, 150)}...`}}/>
    <div className={styles.ArticleInfo}>
      <ArticleInfo authorAvatar={props.articleInfo.authorAvatar}
                   authorName={props.articleInfo.authorName}
                   authorDesignation={props.articleInfo.authorDesignation}
                   timeToRead={props.articleInfo.timeToRead}/>
    </div>
  </Box>;
}

function ArticleList(props: {articles: ArticleProps[]}) {
  // index of the first item of the list
  const [listIndex, setListIndex] = React.useState(0);

  const updateIndex = (newIndex: number) => {
    if (newIndex < 0) {
      newIndex = 0;
    } else if ((newIndex + 3) >= props.articles.length) {
      newIndex = props.articles.length - 3;
    }
    setListIndex(newIndex);
  }

  return <Box sx={{display: 'flex', flexWrap: 'nowrap', justifyContent: props.articles.length < 3 ? 'space-around' : 'space-between'}}>
    {/*TODO 8: animation if needed*/}
    <div className={styles.ArrowIcon} style={listIndex === 0 ? {display: "none"} : undefined}>
      <img src={ArrowLeftIcon} alt="ArrowLeft" onClick={() => {updateIndex(listIndex - 3)}}/>
    </div>
    {
      props.articles.map((article, i) => (
        ((i - listIndex) >= 0 && (i - listIndex < 3)) &&
          <CardWithArticleInfo articleInfo={article} key={i}/>
      ))
    }

    <div className={styles.ArrowIcon} style={(listIndex + 3) >= props.articles.length ? {display: "none"} : undefined}>
      <img src={ArrowRightIcon} alt="ArrowRight" onClick={() => { updateIndex(listIndex + 3)} }/>
    </div>
  </Box>
}

const Learn: FC<LearnProps> = () => {

  // navigate to a single article
  const navigate = useNavigate();
  function handleSelect(id: string) {
    navigate(`/article/${id}`);
  }

  // get data from context
  const articleListCtx = useContext(ArticleListContext);
  if (!articleListCtx) {
    throw new Error("Context must be used within a Provider");
  }

  if (articleListCtx.isLoading) {
    return <CircularProgress />;
  } else if (articleListCtx.receiveError) {
    return <Alert variant="filled" severity="error" className="FullWindowWidth" style={{textAlign: "left"}}>
      <AlertTitle>Error</AlertTitle>
      Fetch articles failed. — <strong>Try it again later!</strong>
      <div>If it happens again, please contact us by email (starsearch@auckland.ac.nz) so we can fix it for you.</div>
    </Alert>;
  } else {
    // add an attribute for mobile view
    const mobileViewItems: CarouselItemProps[] = articleListCtx.articles;
    const tags: string[] = articleListCtx.articleTags;

    return <div className={styles.Learn} data-testid="Learn">
      {
        tags.map((tag, i) => (
          <div style={{marginTop: "5px"}} key={i}>
            <div className="ShowFor1200BigScreenOnly">
              <div className="Container1920">
                <div className="TextBigHeading" style={{margin: "clamp(15px, 1.1vw + 11px, 30px) 0"}}>
                  {tag}
                </div>
                <ArticleList articles={articleListCtx.articles.filter( article => article.tags.indexOf(tag) >= 0 )}/>
              </div>
            </div>
            <div className="ShowFor1200SmallScreenOnly">
              <div className="TextBigHeading" style={{margin: "clamp(15px, 1.1vw + 11px, 30px) 0"}}>
                {tag}
              </div>
              <div style={{marginLeft: "-30px"}}>
                <Carousel handleSelect={handleSelect} items={
                  mobileViewItems
                    .filter(article => article.tags && article.tags.indexOf(tag) >= 0)
                    .map(article => {article.contentSliceSize = 100; return article;})
                }/>
              </div>
            </div>
          </div>
        ))
      }
    </div>
  }
}

export default Learn;
