import React, { FC } from 'react';
import styles from './About.module.css';
import '../../Shared.css';
import { Grid } from "@mui/material";

interface AboutProps {}

const About: FC<AboutProps> = () => (
  <div className={`${styles.About} Container1920`} data-testid="About">
    <br/>
    <div className="TextTitle">
      About Star Search
    </div>
    <Grid container spacing={2}>
      <Grid item xs={12} lg={8}>
        <div className="TextParagraph1">
          <p>Star Search has been developed with the help of experts from the University of Auckland and other health institutions from across New Zealand.</p>
          <p>With trustworthy information, you can focus on what matters most; the health of you and your family.</p>
        </div>
      </Grid>
    </Grid>
    <br/>
  </div>
);

export default About;
