import React, { FC } from 'react';
import styles from '../BetterForYou/BetterForYou.module.css';
import {Link as RouterLink} from "react-router-dom";

interface RatingReasonProps {}

const RatingReason: FC<RatingReasonProps> = () => {

  return <div>
    {/* small screen only*/}
    <div className="ShowFor900SmallScreenOnly">
      <div className={styles.LearnTitleText}>
        Why this rating?
      </div>
      <div className={styles.LearnText}>
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.&nbsp;
        <RouterLink to="/learn" className={styles.ReadMoreText}>Read more</RouterLink>
      </div>
    </div>
    {/* big screen only*/}
    <div className="ShowFor900BigScreenOnly">
      <div className={styles.LearnText}>
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.&nbsp;
        <div className="ShowFor900BigScreenOnly">
          <RouterLink to="/learn" className={styles.SeeMoreText}>See more</RouterLink>
          <br/>
        </div>
        <span className="ShowFor900SmallScreenOnly">
          <RouterLink to="/learn" className={styles.ReadMoreText}>Read more</RouterLink>
        </span>
      </div>
    </div>
  </div>;
}

export default RatingReason;
