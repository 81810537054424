import React, {FC, useEffect, useState} from 'react';
import styles from './Footer.module.css';

import {BottomNavigation, BottomNavigationAction, Grid, Typography} from "@mui/material";
import {Link as RouterLink, Link, useLocation} from "react-router-dom";
import MyLists from "../MyLists/MyLists";

import searchIcon from "../../resources/Home/Footer/Icon feather-search.svg";
import myListsIcon from "../../resources/Home/Footer/Icon feather-list.svg";
import LearnIcon from "../../resources/Home/Footer/Icon feather-book.svg";
import UniservicesLogo from "../../resources/Home/Footer/Uniservices-Logo.svg";
import UoALogo from "../../resources/Home/Footer/UoA-Logo.svg";
import logoWhite from "../../resources/Home/header/Logo White_svg.svg";

const ChevronDownIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
      <path d="M6.67188 10.291L12.9219 16.541L19.1719 10.291" stroke="#F4F2ED" strokeWidth="2.08333" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

const ChevronUpIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
      <path d="M6.67188 15.708L12.9219 9.45801L19.1719 15.708" stroke="#F4F2ED" strokeWidth="2.08333" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

interface FooterProps {}

interface MyLink {
  to: string;
  text: string;
}

interface FooterPanelProps {
  title: string;
  links: MyLink[];
  md?: number; // optional md prop for specifying the grid width
}

const FooterExpandablePanel: React.FC<FooterPanelProps> = ({ title, links }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
      <div>
        <div
            className={`TextParagraph3 ${styles.DesktopFooterSectionTextTitle}`}
            style={{ cursor: 'pointer', padding: '10px 0' }}
            onClick={handleToggle}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div>
              {title}
            </div>
            <div style={{display: 'flex'}}>
              {isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </div>
          </div>
        </div>
        {isExpanded && (
            <div>
              {links.map((link, index) => (
                  <div key={index} className={styles.DesktopFooterTextTiny}>
                    {link.to.startsWith("http") ? (
                        // If the link starts with "http", consider it an external link
                        <a href={link.to} target="_blank" rel="noopener noreferrer" className={styles.UnsetA}>
                          <div style={{padding: "7px 0"}}>{link.text}</div>
                        </a>
                    ) : (
                        // Otherwise, treat it as an internal link
                        <RouterLink to={link.to} className={styles.UnsetA}>
                          <div style={{padding: "7px 0"}}>{link.text}</div>
                        </RouterLink>
                    )}
                  </div>
              ))}
            </div>
        )}
        <hr style={{opacity:"0.2"}}/>
      </div>
  );
};

function DesktopFooterSection() {
  const location = useLocation();
  const footerPanels: FooterPanelProps[] = [
    {
      title: 'General',
      links: [
        { to: '/about', text: 'About us' },
        { to: '/meet-experts', text: 'Our Experts' },
        { to: '/faq', text: 'FAQs' },
      ],
      md: 3
    },
    {
      title: 'Support',
      links: [{ to: '/contact-us', text: 'Contact us' }],
      md: 3
    },
    {
      title: 'Explore more',
      links: [
        { to: 'https://www.nihi.org.nz/', text: 'The National Institute for Health Innovation' },
        { to: 'https://www.uniservices.co.nz/', text: 'UniServices' },
        { to: 'https://www.auckland.ac.nz/en.html', text: 'The University of Auckland' },
      ],
      md: 6
    },
  ];

  return <div>
    { /* not home nor product page */
      (location.pathname !== "/" && location.pathname.indexOf("product") < 0) &&
      <>
          <br/><br/>
      </>
    }
    <section id="footer" className={styles.DesktopFooter}>
      <div className={`${styles.DesktopPage} ${styles.SectionView}`}>
        <div className="Container1920">
          <Grid container columnSpacing={{xs: 4, lg: 8, xl: 16}}>
            <Grid item xs={12} md={6}>
              <br/>
              <img src={logoWhite} className={styles.Logo} alt="logo" />
              <br/><br/>
              <div className={styles.DesktopFooterTextTiny}>
                This website was developed by the <strong>
                <a href="https://www.nihi.org.nz/" target='_blank' rel="noreferrer" className={styles.UnsetA}>
                  National Institute for Health Innovation (NIHI)
                </a></strong>.
                <br/><br/>
                NIHI is part of Auckland UniServices, dedicated to transferring knowledge from
                The University of Auckland to the global community.
              </div>
              <br/>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="ShowFor900SmallScreenOnly">
                {footerPanels.map((panel, index) => (
                    <FooterExpandablePanel key={index} title={panel.title} links={panel.links}/>
                ))}
              </div>
              <div className="ShowFor900BigScreenOnly">
                <Grid container columnSpacing={4} rowSpacing={2} style={{textAlign: "left"}}>
                  {footerPanels.map((panel, index) => (
                      <Grid item xs={12} md={panel.md} key={index}>
                        <br/>
                        <div className={`TextParagraph3 ${styles.DesktopFooterSectionTextTitle}`}>{panel.title}</div>
                        <div>
                          {panel.links.map((link, index) => (
                              <div key={index} className={styles.DesktopFooterTextTiny}>
                                <br/>
                                {link.to.startsWith("http") ? (
                                    // If the link starts with "http", consider it an external link
                                    <a href={link.to} target="_blank" rel="noopener noreferrer" className={styles.UnsetA}>
                                      {link.text}
                                    </a>
                                ) : (
                                    // Otherwise, treat it as an internal link
                                    <RouterLink to={link.to} className={styles.UnsetA}>
                                      {link.text}
                                    </RouterLink>
                                )}
                              </div>
                          ))}
                        </div>
                        <br/>
                      </Grid>
                  ))}
                </Grid>
              </div>
            </Grid>
          </Grid>
          <br/><br/>
          <div className="ShowFor900BigScreenOnly">
            <hr style={{strokeWidth: "1.335px", stroke: "#FFF", opacity: "0.2"}}/>
            <br/>
          </div>
          <Grid container alignItems="center">
            <Grid item xs={12} md={6} container alignItems="center" style={{textAlign: "left"}}>
              <img src={UoALogo} alt="Uniservices logo" className={styles.DesktopFooterLogo} />
              <img src={UniservicesLogo} alt="Uniservices logo" className={styles.DesktopFooterLogo} />
            </Grid>
            <Grid item xs={12} md={6} alignItems="center" className={styles.DesktopFooterTextTiny}>
              <Typography variant="body1" align="right" className="ShowFor900BigScreenOnly">
                <RouterLink to="/terms-of-use" className={styles.UnsetA}>Terms of Use | StarSearch ©</RouterLink>
              </Typography>
              <div className="ShowFor900SmallScreenOnly" style={{textAlign: "left"}}>
                <br/>
                <RouterLink to="/terms-of-use" className={styles.UnsetA}>Terms of Use | StarSearch ©</RouterLink>
                <br/><br/>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </section>
  </div>;
}

const Footer: FC<FooterProps> = () => {
  const location = useLocation();

  // drawer state of "My Lists"
  const [drawerState, setDrawerState] = React.useState(false);

  // Bottom Navigation selection value
  const [value, setValue] = React.useState(-1);

  const toggleMyListsDrawer = (newState: boolean) => () => {
    setDrawerState(newState);
    if (!newState) {
      setValue(-1);
    }
  };

  // when path changes, value changes accordingly.
  useEffect(() => {
    // check whether the current location matches the 2 paths
    if (location.pathname === "/") {
      setValue(0);
    } else if (location.pathname === "/learn") {
      setValue(2);
    } else {
      setValue(-1);
    }
  }, [location.pathname]);

  return <div className={styles.Footer} data-testid="Footer">
    <div className="ShowFor900BigScreenOnly">
      <DesktopFooterSection />
    </div>
    {
      location.pathname === "/" && <div className="ShowFor900SmallScreenOnly">
          <DesktopFooterSection />
      </div>
    }
    {/* Mobile nav */}
    <div className={styles.MobileFooterSpaceTaken}/>
    <div className={styles.MobileFooter}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        className={styles.MobileFooterNav}
        sx={{display: 'flex', flexWrap: 'nowrap', justifyContent: 'space-between'}}
      >
        <BottomNavigationAction component={Link} to="/"
                                icon={<img src={searchIcon} alt="Search" className={value === 0 ? styles.MobileFooterIconSelected : undefined}/>}
                                label={<span className={value === 0 ? styles.MobileFooterLabelSelected : undefined}>Search</span>}/>
        <BottomNavigationAction onClick={toggleMyListsDrawer(true)}
                                icon={<img src={myListsIcon} alt="My Lists" className={value === 1 ? styles.MobileFooterIconSelected : undefined}/>}
                                label={<span className={value === 1 ? styles.MobileFooterLabelSelected : undefined}>My Lists</span>}/>
        <BottomNavigationAction component={Link} to="/learn"
                                icon={<img src={LearnIcon} alt="Learn" className={value === 2 ? styles.MobileFooterIconSelected :undefined}/>}
                                label={<span className={value === 2 ? styles.MobileFooterLabelSelected : undefined}>Learn</span>}/>
      </BottomNavigation>

      <MyLists drawerState={drawerState} toggleMyListsDrawer={toggleMyListsDrawer}/>
    </div>
  </div>
};

export default Footer;
