import React, { FC } from 'react';
import styles from './AlertDialog.module.css';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  styled
} from "@mui/material";

interface AlertDialogProps {
  open: boolean;
  handleCancel: any;
  handleAgree: any;
  title?: string;
  content: string;
  agreeText?: string;
  cancelText?: string;
}

const AlertDialog: FC<AlertDialogProps> = (props) => {
  const DialogButton = styled(Button)({
    textAlign: "left",
    font: "normal normal normal clamp(17px, 0.32vw + 16px, 22px)/clamp(23px, 0.26vw + 22px, 27px) Noto Sans, sans-serif",
    letterSpacing: "0px",
    color: "#29A26E",
    opacity: "1",
    textTransform: "none",
    margin: "0px !important",
  });

  return <div className={styles.AlertDialog} data-testid="AlertDialog">
      <Dialog
        open={props.open}
        onClose={props.handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiDialog-paper': {
            borderRadius: "25px",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {props.title ?? ""}
        </DialogTitle>
        <DialogContent>
          <div className={styles.DialogContent} id="alert-dialog-description">
            {props.content}
          </div>
        </DialogContent>
        <DialogActions style={{justifyContent: "space-evenly", textTransform: "none", marginBottom: "20px"}}>
          <DialogButton onClick={props.handleCancel}>{props.cancelText ?? "Cancel"}</DialogButton>
          <Divider sx={{ height: '21px', border: '0.5px solid #29A26E', backgroundColor: '#29A26E', m: 1 }} orientation="vertical" />
          <DialogButton onClick={props.handleAgree} autoFocus>{props.agreeText ?? "OK"}</DialogButton>
        </DialogActions>
      </Dialog>
    </div>;
}

export default AlertDialog;
