import {createContext, useEffect, useState} from "react";
import {ArticleProps} from "../pages/Learn/Learn";
import moment from "moment";
import {fetchWithTimeout, getUniqueItems} from "../modules/helper";

interface ArticleListContextInterface {
  articles: ArticleProps[];
  articleTags: string[];
  isLoading: boolean;
  receiveError: boolean;
  findArticle: (id: string) => ArticleProps[];
}

const ArticleListContext = createContext<ArticleListContextInterface | null>(null);

export interface ResponseArticleProps {
  nid: string;
  article_image: string;
  article_title: string;
  body: string;
  author_photo: string;
  author_name: string;
  author_title: string;
  minutes_to_read: string;
  tags: string;
  created: moment.Moment;
}

export function ArticleListProvider(props: any) {
  // get articleList from API then save in context
  const [articleList, setArticleList] = useState<ArticleProps[]>([]);
  const [articleTags, setArticleTags] = useState<string[]>([]);

  const [isLoading, setIsLoading] = useState(true);
  const [receiveError, setReceiveError]= useState(false);

  // fetch data
  useEffect( () => {
    fetchWithTimeout(process.env.REACT_APP_API_END_POINT + "/api/articles")
      .then(response => {
        setReceiveError(false);
        return response.json();
      })
      .then(data => {
        console.debug("Article Data fetched from API.");
        // data in ArticleProps style
        const returnedArticles: ResponseArticleProps[] = data;
        const articleDataList: ArticleProps[] = [];
        let articleTags: string = "";

        returnedArticles.forEach((article: ResponseArticleProps) => {
          articleTags += article.tags + ";";

          articleDataList.push(
            {
              id: article.nid,
              image: process.env.REACT_APP_API_END_POINT + article.article_image,
              title: article.article_title,
              content: article.body,
              authorAvatar: process.env.REACT_APP_API_END_POINT + article.author_photo,
              authorName: article.author_name,
              authorDesignation: article.author_title,
              timeToRead: +article.minutes_to_read,
              tags: article.tags,
              created: moment(article.created, 'DD/MM/YYYY')
            }
          );
        });

        // save article.tags sorted in alphabetical order
        const tags = getUniqueItems(articleTags.split(";")).sort((a, b) => (a > b ? 1 : -1));
        setArticleTags(tags.filter(item => item !== ""));

        // sort by created date, latest on top
        setArticleList(articleDataList.sort((a, b) => (a.created < b.created ? 1 : -1)));
        setIsLoading(false);
      })
      .catch(error => {
        console.error(error);
        setIsLoading(false);
        setArticleList([]);
        setReceiveError(true);
      });
  },[]);

  // store in this context
  const context: ArticleListContextInterface = {
    articles: articleList,
    articleTags: articleTags,
    isLoading: isLoading,
    receiveError: receiveError,
    findArticle: findArticleHandler
  };

  function findArticleHandler(articleId: string): ArticleProps[] {
    return articleList.filter(article => article.id === articleId);
  }

  return <ArticleListContext.Provider value={context}>
    {props.children}
  </ArticleListContext.Provider>
}

export default ArticleListContext;
