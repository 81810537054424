import React, {FC, useContext, useEffect} from 'react';
import styles from './BetterForYou.module.css';
import {Box, CircularProgress, Grid, styled} from "@mui/material";
import ProductBriefPanel, {ProductBriefProps} from "../ProductBriefPanel/ProductBriefPanel";
import { grey } from "@mui/material/colors";
import { useSwipeable } from "react-swipeable";
import { clamp } from "../../modules/helper";
import RatingReason from "./RatingReason";
import BetterForYouContext from "../../services/ProductBetterForYouService";

interface BetterForYouProps {
  productBarcode: string;
}

const BetterForYou: FC<BetterForYouProps> = (props) => {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  useEffect(() => {
    function reportWindowSize() {
      setWindowWidth(window.innerWidth)
    }
    // Trigger this function on resize
    window.addEventListener('resize', reportWindowSize)
    //  Cleanup for componentWillUnmount
    return () => window.removeEventListener('resize', reportWindowSize)
  }, [])

  const [tabIndex, setTabIndex] = React.useState(0);

  /* start: mobile drawer */
  const [currentDeltaY, setDeltaY] = React.useState(0); // Swiping down interaction
  const [drawerState, setDrawerState] = React.useState(0); // 0: close, 1: half-open 2: open
  const drawerBleeding = clamp(0.0667 * windowWidth + 39, 78, 181);

  const swipeHandlers = useSwipeable({
    onSwipedUp: () => {
      const newDrawerState = Math.min(2, drawerState + 1);
      setDrawerState(newDrawerState);
      setDeltaYDependsOnDrawerState(newDrawerState);
    },
    onSwipedDown: () => {
      const newDrawerState = Math.max(0, drawerState - 1);
      setDrawerState(newDrawerState);
      setDeltaYDependsOnDrawerState(newDrawerState);
    },
    onSwiping: ({ deltaY }) => {
      setDeltaY(deltaY);
    },
  });

  const setDeltaYDependsOnDrawerState = (newDrawerState: number) => {
    let deltaY = 0;
    switch (newDrawerState) {
      case 0:
        break;
      case 1:
        deltaY = drawerBleeding - 0.5 * window.innerHeight;
        break;
      case 2:
        deltaY = drawerBleeding * 2 - window.innerHeight;
        break;
      default:
    }
    setDeltaY(deltaY);
  };
  /* end: mobile drawer */

  // get data from better for you context
  const betterForYouCtx = useContext(BetterForYouContext);
  if (!betterForYouCtx) {
    throw new Error("Context must be used within a Provider");
  }

  useEffect(() => {
    if (betterForYouCtx.productBarcode !== props.productBarcode) {
      betterForYouCtx.searchForProduct(props.productBarcode);
    }
  }, [props.productBarcode, betterForYouCtx]);

  if (betterForYouCtx.isLoading) {
    return <CircularProgress />;
  }

  const betterProducts: ProductBriefProps[] = betterForYouCtx.betterProducts;

  // get height of Better for you items
  const getHeight = () => {
    // the whole item list size
    const itemsLength = betterProducts.length > 8 ? 8 : betterProducts.length;
    if (itemsLength < 1) {
      return 120;
    }

    // item size in a row
    let itemsInRow;
    if (windowWidth > 1536) {
      itemsInRow = 4;
    } else if (windowWidth > 1200) {
      itemsInRow = 3;
    } else if (windowWidth > 900) {
      itemsInRow = 2;
    } else {
      itemsInRow = 1;
    }

    // how many rows
    let rows = Math.floor(itemsLength/itemsInRow);
    rows = (itemsLength % itemsInRow > 0) ? rows + 1 : rows;

    // height
    const oneLineHeight = itemsInRow > 1 ? 280 : 250;
    return rows * oneLineHeight;
  }

  if (windowWidth <= 900) {
    if (betterProducts.length < 1) {
      return <></>;
    }

    const Puller = styled(Box)(() => ({
      width: 60,
      height: 5,
      backgroundColor: grey[300],
      borderRadius: 4,
      position: 'absolute',
      top: 12,
      left: 'calc(50% - 30px)',
    }));

    return <div>
      <div className={styles.DrawerPage} style={{ transform: `translateY(${currentDeltaY}px)`, transition: "transform 0.4s"}}>
        <Box
          sx={{
            backgroundColor: 'white',
            position: 'absolute',
            top: -drawerBleeding,
            right: 0,
            left: 0,
            overflow: 'auto',
            borderTopLeftRadius: 43,
            borderTopRightRadius: 43,
            boxShadow: '0px -10px 30px #0000000D',
          }}
        >
          {/* Drawer Head */}
          <div {...swipeHandlers}>
            <Puller />
            <div className={`TextParagraph2 ${styles.DrawerTitleText}`}>Better for you</div>
            <hr style={{color: "#0000001C", margin: "8px 20px 0 20px", opacity: '0.33'}}/>
          </div>

          {/* Drawer List */}
          <div className={styles.DrawerList}>
            <Grid container spacing={3}>
              {
                betterProducts.slice(0, 8).map((product, i) => (
                  <Grid item xs={12} md={6} lg={4} xl={3} key={i}>
                    <Box sx={{alignItems: "center", justifyContent: 'space-between'}}>
                      <ProductBriefPanel item={product}/>
                    </Box>
                  </Grid>
                ))
              }
            </Grid>
            <div style={{marginBottom: `${drawerBleeding * 3 * (drawerState === 1 ? 2 : 1)}px`}}/>
          </div>
        </Box>
      </div>
    </div>;
  }

  return <div className={styles.BetterForYou} data-testid="BetterForYou">
    <div className={styles.Tabs}>
      <div onClick={()=>{setTabIndex(0)}} className={tabIndex === 0 ? styles.SelectedTab : styles.UnselectedTab}>
        {tabIndex === 0 ? "Better for you" : "Experts prefer"}
      </div>
      <div onClick={()=>{setTabIndex(1)}} className={tabIndex === 1 ? styles.SelectedTab : styles.UnselectedTab}>
        Why this rating?
      </div>
    </div>
    <hr style={{opacity: "0.6", marginBottom: "20px"}}/>
    <div style={{position: "relative", top: "5px", height: `${getHeight()}px`}}>
      <div className={tabIndex === 0 ? styles.TabContentShow : styles.TabContentHide}>
        {betterProducts.length > 0 ? <Grid container spacing={{xs: 3, md:2, lg: 1}}>
          {
            betterProducts.slice(0, 8).map((product, i) => (
              <Grid item xs={12} md={6} lg={4} xl={3} key={i}>
                <Box sx={{alignItems: "center", justifyContent: 'space-between'}}>
                  <ProductBriefPanel item={product}/>
                </Box>
              </Grid>
            ))
          }
        </Grid> : <div className={styles.LearnText}>
          no better options
        </div>
        }
      </div>
      {/* rating reason */}
      <div className={tabIndex === 1 ? styles.TabContentShow : styles.TabContentHide}>
        <RatingReason/>
      </div>
    </div>
  </div>;
}

export default BetterForYou;
