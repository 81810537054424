import React, {FC, useContext, useEffect, useState} from 'react';
import styles from './PopularSearches.module.css';
import {CircularProgress, Grid} from "@mui/material";
import SearchRelatedDataContext from "../../services/SearchRelatedDataService";
import {useNavigate} from "react-router-dom";
import {set} from "idb-keyval";

interface PopularSearchesProps {
  isHome?: boolean
}

const PopularSearches: FC<PopularSearchesProps> = (props) => {
  const [topN, setTopN] = useState(10);

  useEffect(() => {
    const {isHome} = props;

    const handleResize = () => {
      if (window.innerWidth <= 900 || isHome) {
        setTopN(4);
      } else {
        setTopN(10);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [props]);

  const navigate = useNavigate();

  const handleSearch = (searchText: string) => {
    set('searchResultsRequestBody', {
      product_name: searchText,
      brand_name: "",
      food_group_code: ""
    }).then(() => {
      navigate(`/products/${searchText}`);
    }).catch((err) => {
      console.error('Set searchResultsRequestBody failed!', err);
      alert('Set search input parameters failed, please contact us so we can fix it for you.');
    });
  }

  // get the popular search list from context
  const searchRelatedDataCtx = useContext(SearchRelatedDataContext);
  if (!searchRelatedDataCtx) {
    throw new Error("Context must be used within a Provider");
  }

  if (searchRelatedDataCtx.isLoading) {
    return <CircularProgress />;
  }

  const mostPopularSearches: string[] = searchRelatedDataCtx.searchList;

  if (!mostPopularSearches || mostPopularSearches.length < 1) {
    return <></>;
  }

  // for Home Page, only show 4 in button style
  if (props.isHome) {
    return <div className={styles.HomePopularSearches}>
      <Grid container spacing={1} alignItems="center" justifyContent="center" >
        <Grid item xs={10} md={8} xl={6}>
          <div>Popular Searches</div>
          <Grid container spacing={0.5}> {
            mostPopularSearches.slice(0, topN).map((searchText, i) => (
              <Grid item xs={6} md={3} key={i}>
                <button onClick={() => handleSearch(searchText)} style={{maxWidth: "214px", minHeight: "61px"}}>{searchText}</button>
              </Grid>
            ))
          }
          </Grid>
        </Grid>
      </Grid>
    </div>
  }

  // for product page, show 10 in text style
  return <div>
    {(window.innerWidth > 900 || window.location.href.indexOf('/product/') === -1) && <div className={styles.PopularSearches} data-testid="PopularSearches">
    <div className="Page" style={{paddingTop: "6px"}}>
      <div className="Container1920">
        <div className="TextHeading" style={{opacity: "0.7"}}>
          Explore other popular searches
        </div>
        <Grid container rowSpacing={4} columnSpacing={{xs: 4, md: 16, xl: 32}} style={{paddingTop: "40px"}}>
          {mostPopularSearches.slice(0, topN).map((search, i) => (
              <Grid item xs={6} md={4} lg={3} key={i}>
                <div className={styles.SearchText} onClick={() => handleSearch(search)}>{search}</div>
              </Grid>
          ))}
        </Grid>
      </div>
    </div>
  </div>}
  </div>
}

export default PopularSearches;
