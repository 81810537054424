import React, { FC } from 'react';
import styles from './ArticleInfo.module.css';
import {Box} from "@mui/material";
import ExpertChip from "../ExpertChip/ExpertChip";

interface ArticleInfoProps {
  authorAvatar: string;
  authorName: string;
  authorDesignation: string;
  timeToRead: number;
}

const ArticleInfo: FC<ArticleInfoProps> = (props) => (
  <div className={styles.ArticleInfo} data-testid="ArticleInfo">
    <Box sx={{ display: 'flex', alignItems: 'center', p: 0, m: 0}}>
      <div>
        <ExpertChip avatar={props.authorAvatar} name={props.authorName} designation={props.authorDesignation} small={true}/>
      </div>
      <div className={styles.Splitter}/>
      <div className={styles.TimeToRead}>{props.timeToRead.toString()} min read</div>
    </Box>
  </div>
);

export default ArticleInfo;
