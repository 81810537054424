import React, { FC } from 'react';
import styles from './MeetOurExperts.module.css';
import {Grid} from "@mui/material";
import avatar from "../../resources/MeetExperts/Rachael_Smith.png";
import ExpertChip from "../../components/ExpertChip/ExpertChip";

interface MeetOurExpertsProps {}

const MeetOurExperts: FC<MeetOurExpertsProps> = () => {
  const experts = new Array(6).fill({
    avatar: avatar,
    name: "Rachael Smith",
    designation: "Nutritionist"
  })

  return <div className={`${styles.MeetOurExperts} Container1920`} data-testid="MeetOurExperts">
    <br/>
    <div className="TextTitle" style={{textAlign: "center"}}>
      Meet our experts
    </div>
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} style={{margin: "auto"}}>
        <div className="TextParagraph3" style={{textAlign: "center", paddingTop: "10px"}}>
          <p>Star Search has been developed with the help of experts from the University of Auckland and other health institutions from across New Zealand.</p>
          <p>With trustworthy information, you can focus on what matters most; the health of you and your family.</p>
        </div>
      </Grid>
    </Grid>
    <br/>
    <Grid container style={{margin: "auto"}}>
      <Grid item xs={11} style={{maxWidth: "1412px", margin: "auto"}}>
        <Grid container rowSpacing={3} columnSpacing={2}>
          {
            experts.map((expert, i) => (
              <Grid item xs={10} sm={6} lg={4} style={{margin: "auto"}} key={i}>
                <ExpertChip avatar={expert.avatar} name={expert.name} designation={expert.designation}/>
              </Grid>
            ))
          }
        </Grid>
      </Grid>
    </Grid>
    <br/>
  </div>;
}

export default MeetOurExperts;
