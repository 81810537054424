import React, { FC } from 'react';
import styles from './TermsOfUse.module.css';
import { Grid, Link } from "@mui/material";

interface TermsOfUseProps {}

const TermsOfUse: FC<TermsOfUseProps> = () => (
  <div className={`${styles.TermsOfUse} Container1920`} data-testid="TermsOfUse">
    <br/>
    <Grid container spacing={2}>
      <Grid item md={12} lg={10} xl={6}>
        <div className={"TextTitle"}>
          Terms of Use
        </div>
        <div className={styles.TermsOfUseContentText}>
          <p>Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
        </div>

        <br/>

        <div>
          <div className={`${styles.TableHeading} TextHeading3`}>Table of Contents</div>
          <div className={styles.ListItem} style={{paddingTop: "10px"}}>
            <div><Link href="#purpose" color="inherit">1. Purpose of Star Search</Link></div>
            <div><Link href="#conditions" color="inherit">2. Conditions of Use</Link></div>
            <div><Link href="#liability" color="inherit">3. Liability</Link></div>
            <div><Link href="#ownership" color="inherit">4. Ownership of Star Search</Link></div>
            <div><Link href="#privacy" color="inherit">5. Data Privacy</Link></div>
          </div>

          <br/>

          <div className={`${styles.TableHeading} TextHeading3`} id="purpose">1. Purpose of Star Search</div>
          <div className={styles.TermsOfUseContentText}>
            <p>Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. </p>
            <p>At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
          </div>
          <div className={`${styles.TableHeading} TextHeading3`} id="conditions">2. Conditions of Use</div>
          <div className={styles.TermsOfUseContentText}>
            <p>Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. </p>
            <p>At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
          </div>
          <div className={`${styles.TableHeading} TextHeading3`} id="liability">3. Liability</div>
          <div className={styles.TermsOfUseContentText}>
            <p>Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. </p>
            <p>At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
          </div>
          <div className={`${styles.TableHeading} TextHeading3`} id="ownership">4. Ownership of Star Search</div>
          <div className={styles.TermsOfUseContentText}>
            <p>Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. </p>
            <p>At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
          </div>
          <div className={`${styles.TableHeading} TextHeading3`} id="privacy">5. Data Privacy</div>
          <div className={styles.TermsOfUseContentText}>
            <p>Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. </p>
            <p>At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
          </div>
        </div>

      </Grid>
    </Grid>
  </div>
);

export default TermsOfUse;
