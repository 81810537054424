import React, {FC, useContext, useState} from 'react';
import styles from './MyLists.module.css';
import {Box, CircularProgress, Divider, InputBase, SwipeableDrawer} from "@mui/material";
import MyListsContext from "../../services/MyListsContext";
import ProductBriefPanel, {ProductBriefProps} from "../ProductBriefPanel/ProductBriefPanel";
import AlertDialog from "../AlertDialog/AlertDialog";
import {get} from "idb-keyval";
import BackIcon from "../../resources/View product/back icon.svg";
import MyListsIcon from "../../resources/My lists/my lists icon.svg";
import RightArrow from "../../resources/Learn/Icon ionic-ios-arrow-forward.svg";
import EditListIcon from "../../resources/My lists/Edit Icon.svg";
import RemoveListsIcon from "../../resources/My lists/Icon feather-trash-2.svg";
import ConfirmEditIcon from "../../resources/My lists/confirm_edit.svg";

interface MyListsProps {
  drawerState: boolean;
  toggleMyListsDrawer: any;
}

const MyLists: FC<MyListsProps> = (props) => {
  const [showListWithName, setShowListWithName] = React.useState("");
  const [productListItems, setProductListItems] = useState<ProductBriefProps[]>([]);
  const [isEditingList, setIsEditingList] = React.useState(false);
  const [newListName, setNewListName] = React.useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

  // get data from context
  const myListsCtx = useContext(MyListsContext);
  if (!myListsCtx) {
    throw new Error("Context must be used within a Provider");
  }

  function showList (listName: string) {
    get<ProductBriefProps[]>(listName).then(listItems => {
      if (listItems) {
        setProductListItems(listItems);
      }
    });
    setShowListWithName(listName);
  }

  function deleteList (listName: string) {
    myListsCtx?.deleteCustomList(listName);
    setDeleteDialogOpen(false);
  }

  function editListName (oldName: string) {
    myListsCtx?.editCustomList(oldName, newListName);
    console.debug("editListName");
    setIsEditingList(false);
  }

  if (myListsCtx.isLoading) {
    return <CircularProgress />;
  } else {
    const listNames: string[] = ["Favourites"].concat(myListsCtx.listNames);

    return <div className={styles.MyLists} data-testid="MyLists">
      <SwipeableDrawer
        anchor='right'
        open={props.drawerState}
        onClose={props.toggleMyListsDrawer(false)}
        onOpen={ props.toggleMyListsDrawer(true)}
        BackdropProps={{ style: { opacity: 0.1 } }}
        PaperProps={{
          sx: { maxWidth: "90%" },
        }}
      >
        <Box
          sx={{
            width: "clamp(400px, 18vw + 238px, 580px)",
            maxWidth: "100%",
            height: "100%"
          }}
          role="presentation"
        >
          <div className={styles.MyListContainer}>
            {/* My List Head */}
            <div className={styles.Head}>
              <div className="ShowFor1200BigScreenOnly">
                <div style={{width: "1px"}}></div>
              </div>
              <div className={styles.FlexRow} style={{padding: 0}}>
                <img src={MyListsIcon} alt="My Lists Icon"/>
                <span className={styles.HeadText}>My Lists</span>
              </div>
              <img src={RightArrow} className={styles.RightArrowIcon} alt="right arrow" onClick={props.toggleMyListsDrawer(false)}/>
            </div>

            {
              showListWithName ? <div>
                  {/* Product list */}
                  <div className={styles.BackArrowRow}>
                    <div className={styles.BackArrowSectionRow}>
                      <img src={BackIcon} alt="backIcon" onClick={() => setShowListWithName("")} className={styles.BackArrowRowIcon}/>
                      {
                        isEditingList ? <div className={styles.ListNameTitleText}>
                            <InputBase
                              className={`${styles.HeaderInputPlaceholder} ${styles.HeaderSearchBarSectionItemInput}`}
                              sx={{ ml: 1, width: "100%"}}
                              placeholder={showListWithName}
                              value={newListName}
                              onChange={(event: any) => {setNewListName(event.target.value)}}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  editListName(showListWithName);
                                }
                              }}
                              required={true}
                              inputProps={{ sx: { pt: "7px" }, maxLength: 18 }}
                              autoFocus={true}
                            />
                          </div> :
                          <div className={styles.ListNameTitleText}>{showListWithName}</div>
                      }
                    </div>
                    <div className={styles.BackArrowSectionRow}>
                      {
                        showListWithName !== "Favourites" && <>
                          {
                            isEditingList ? <img src={ConfirmEditIcon} alt="ConfirmEditIcon" onClick={() => editListName(showListWithName)} className={styles.BackArrowRowIcon}/> :
                              <img src={EditListIcon} alt="EditListIcon" onClick={() => setIsEditingList(true)} className={styles.BackArrowRowIcon}/>
                          }
                          <img src={RemoveListsIcon} alt="RemoveListsIcon" onClick={() => setDeleteDialogOpen(true)} className={styles.BackArrowRowIcon}/>
                          <AlertDialog open={deleteDialogOpen}
                                       handleCancel={() => setDeleteDialogOpen(false)}
                                       handleAgree={() => deleteList(showListWithName)}
                                       content={`Remove this list and items from "My List"?`}
                                       agreeText={"Yes, Remove"}
                          />
                        </>
                      }
                    </div>
                  </div>
                  <Divider style={{marginBottom: "20px"}}/>
                  {
                    productListItems.map((product, i) => (
                      <Box sx={{alignItems: "center", justifyContent: 'space-between'}} key={i}>
                        <ProductBriefPanel item={product} myListsName={showListWithName}/>
                      </Box>
                    ))
                  }
                </div>
                :
                <div>
                  {/* My List Names */}
                  <Divider />
                  {
                    listNames.map((listName, index) => (
                      <div key={index}>
                        <div className={styles.FlexRow} onClick={() => showList(listName)}>
                          <div className={styles.ListNameText}>
                            {listName}
                          </div>
                          <img src={RightArrow} className={styles.GreyRightArrowIcon} alt="right arrow"/>
                        </div>

                        <Divider />
                      </div>
                    ))
                  }
                </div>
            }
          </div>
        </Box>
      </SwipeableDrawer>
    </div>
  }
}

export default MyLists;
