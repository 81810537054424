import React, { FC } from 'react';
import styles from './ContactUs.module.css';
import {Alert, Button, Grid, Snackbar, TextField} from "@mui/material";
import SendIcon from "../../resources/Contact Us/send icon.svg";
import {fetchWithTimeout, errorMessage} from "../../modules/helper";


interface ContactUsProps {}

const ContactUs: FC<ContactUsProps> = () => {
  const [inputs, setInputs] = React.useState({name: null, email: null, message: null});
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [returnMessage, setReturnMessage] = React.useState("");

  // update input
  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}));
  }

  // send message to API
  const handleSubmit = (event: any) => {
    event.preventDefault();

    fetchWithTimeout(process.env.REACT_APP_API_END_POINT + "/api/send_contact_message.json", {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        username: inputs.name,
        email: inputs.email,
        enquiry: inputs.message
      })
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        const message = data.data ? data.data : "Send Message Failed. " + errorMessage;
        setReturnMessage(message);
        setOpenSnackbar(true);
      })
      .catch(error => {
        console.error(error);
        setReturnMessage("Send Message Failed. " + errorMessage);
        setOpenSnackbar(true);
      });
  }

  return <div className={`${styles.ContactUs} Container1920`} data-testid="ContactUs">
    <br/>
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      open={openSnackbar}
      autoHideDuration={6000}
      onClose={() => setOpenSnackbar(false)}
    >
      <Alert onClose={() => setOpenSnackbar(false)} severity="error" sx={{ width: '100%' }}>
        {returnMessage}
      </Alert>
    </Snackbar>
    <Grid container spacing={0} alignItems="center" justifyContent="center">
      <Grid item xs={12} sm={8} lg={6} xl={5}>
        <div className="TextTitle" style={{textAlign: "center", padding: 'clamp(-10px, 1.94vw - 17px, 20px) 0', whiteSpace: "nowrap"}}>
          We would love to hear <br/> from you!
        </div>
        <br/>
        <div className={styles.Box}>
          <div className="TextHeading" style={{color: '#29A26E', margin: 'auto', paddingBottom: 'clamp(10px, 0.65vw + 7.6px, 20px)'}}>
            Get in touch.
          </div>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6} >
                <div className={styles.Label}>Name</div>
                <TextField
                  fullWidth={true}
                  label="Full name"
                  name="name"
                  value={inputs.name || ""}
                  onChange={handleChange}
                  inputProps={{pattern: "^[a-zA-Z ,.'-]{2,20}"}}
                  required
                />
              </Grid>

              <Grid item xs={12} lg={6} >
                <div className={styles.Label}>Email</div>
                <TextField
                  fullWidth={true}
                  label="Email address"
                  name="email"
                  value={inputs.email || ""}
                  onChange={handleChange}
                  inputProps={{pattern: "^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,6}"}}
                  required

                />
              </Grid>

              <Grid item xs={12} >
                <div className={styles.Label}>Enquiry</div>
                <TextField
                  fullWidth={true}
                  label="Your message"
                  name="message"
                  value={inputs.message || ""}
                  onChange={handleChange}
                  multiline
                  rows={4}
                  required
                />
              </Grid>
            </Grid>
            <br/>

            <div>
              <Button type="submit" fullWidth={true} variant="contained"
                      style={{ backgroundColor: "#29A26E" }}
                      endIcon={<img src={SendIcon} alt="send icon"/>}>
                <div className={styles.SendButtonText}>Send Message</div>
              </Button>
            </div>
          </form>

        </div>
        <br/>
      </Grid>
    </Grid>
  </div>
};

export default ContactUs;
