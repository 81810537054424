import React, {FC, useEffect, useState} from 'react';
import styles from './Product.module.css';
import PopularSearches from "../../components/PopularSearches/PopularSearches";
import {useNavigate, useParams} from "react-router-dom";
import {Alert, AlertTitle, CircularProgress} from "@mui/material";
import ProductBriefPanel from "../../components/ProductBriefPanel/ProductBriefPanel";
import BackIcon from "../../resources/View product/back icon.svg";
import BetterForYou from "../../components/BetterForYou/BetterForYou";
import RatingReason from "../../components/BetterForYou/RatingReason";
import {get} from "idb-keyval";
import {SearchResultsRequestBody} from "../../components/SearchBar/SearchBar";
import {useQuery} from "@tanstack/react-query";
import {fetchWithTimeout} from "../../modules/helper";

interface ProductProps {}

const Product: FC<ProductProps> = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchInput, setSearchInput] = useState<string | undefined>(undefined);

  if (!id) {
    throw new Error("Need the product barcode as URL parameter.");
  }

  // get SearchInput
  useEffect(() => {
    // get the search food item text
    get<SearchResultsRequestBody>("searchResultsRequestBody").then(value => {
      setSearchInput(value?.product_name ? value?.product_name : value?.brand_name);
    });
  }, []);

  // get product details from API

  const { isLoading, error, data } = useQuery(['productData', id], () =>
    fetchWithTimeout(process.env.REACT_APP_API_END_POINT +  "/api/product_data.json", {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ barcode: id })
    }).then(res => res.json())
      .catch(error => console.error(error))
  );

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Alert variant="filled" severity="error" className="FullWindowWidth" style={{textAlign: "left"}}>
      <AlertTitle>Error</AlertTitle>
      The product you requested does not exist. — <strong>check the product id again!</strong>
      <div>{`An error has occurred: ${error}`}</div>
    </Alert>
  }

  const productData = data.data;

  // render it
  return <div className="FullWindowWidth" data-testid="Product">
    <div className={styles.Product}>
      <div className="Page">
        <div className="Container1920">
          <br/><br/>

          <div style={{textAlign: "left"}}>
            <div onClick={() => {searchInput && navigate(`/products/${searchInput}`);}} >
              <div className="NavigateBackText">
                <img src={BackIcon} alt="backIcon"/>
                <span className={styles.NavigateBackText}>&nbsp;Search Results</span>
              </div>
            </div>
          </div>
          <br/><br/>

          {/* Product Brief Info */}
          <ProductBriefPanel item={productData} isDetail={true}/>
          <br/><br/>

          {/* rating reason */}
          <div className={styles.RatingReason}>
            <RatingReason/>
          </div>

          {/* Better for you options */}
          <BetterForYou productBarcode={id}/>
          <br/>

        </div>
      </div>
    </div>

    <div>
      {/* Popular Search */}
      <PopularSearches/>
    </div>
  </div>
}

export default Product;
