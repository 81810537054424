import React, {createContext, useEffect, useState} from "react";
import {ProductBriefProps} from "../components/ProductBriefPanel/ProductBriefPanel";
import {get} from "idb-keyval";
import {SearchResultsRequestBody} from "../components/SearchBar/SearchBar";
import {fetchWithTimeout} from "../modules/helper";

interface SearchResultsContextInterface {
  products: ProductBriefProps[];
  isLoading: boolean;
  receiveError: boolean;
  findProduct: (id: string) => ProductBriefProps[];
}

const SearchResultsContext = createContext<SearchResultsContextInterface | null>(null);

export function SearchResultsProvider(props: any) {
  // get product list (by search parameters) from API then save in context
  const [productList, setProductList] = useState<ProductBriefProps[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [receiveError, setReceiveError] = useState(false);
  const [requestBody, setRequestBody] = useState<SearchResultsRequestBody | undefined>(undefined);

  // get search parameter first
  get<SearchResultsRequestBody>('searchResultsRequestBody')
    .then(value => {
        if (!value) {
          alert("The food items you requested is not accessible.\n" +
            "— Please check your search input again.");
          return;
        }

        if (!requestBody ||
          value.product_name !== requestBody.product_name ||
          value.brand_name !== requestBody.brand_name ||
          value.food_group_code !== requestBody.food_group_code) {
          setRequestBody(value);
        }
      }
    ).catch((err) => {
    console.error('Get searchResultsRequestBody failed!', err);
    alert('Get search input parameters failed, please contact us so we can fix it for you.');
    return;
  });

  // fetch data
  useEffect( () => {
    if (!requestBody || (!requestBody.product_name && !requestBody.brand_name)) {
      return;
    }

    setIsLoading(true);
    setReceiveError(false);

    // then fetch data from API with parameters
    fetchWithTimeout(process.env.REACT_APP_API_END_POINT + "/api/search_product.json?size=36", {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(requestBody)
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`API response error! RequestBody: ${JSON.stringify(requestBody)}`);
        }
        return response.json();
      })
      .then(data => {
        console.debug("Search results have been fetched from API.");
        // data in ProductBriefProps style
        setProductList(data.data);
        setIsLoading(false);
      })
      .catch(error => {
        console.error(error);
        setProductList([]);
        // TODO 1 occasionally, the backend returns a 500 error response first, then a 200 ok response. So just ignore the errors for now.
        // setReceiveError(true);
        setIsLoading(false);
      });

  },[requestBody]);

  // store in this context
  const context: SearchResultsContextInterface = {
    products: productList,
    isLoading: isLoading,
    receiveError: receiveError,
    findProduct: findProductHandler
  };

  function findProductHandler(productBarcode: string): ProductBriefProps[] {
    return productList.filter(product => product.barcode === productBarcode);
  }

  return <SearchResultsContext.Provider value={context}>
    {props.children}
  </SearchResultsContext.Provider>
}

export default SearchResultsContext;
