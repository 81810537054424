import React, {FC} from 'react';
import styles from './Header.module.css';

import {Link} from "react-router-dom";
import {Chip} from "@mui/material";

import SearchBar from "../SearchBar/SearchBar";
import MyLists from "../MyLists/MyLists";
import MyMenu from "../MyMenu/MyMenu";

import logo from '../../logo.svg';
import logoSmall from '../../logo-small.svg';
import myListsIcon from "../../resources/My lists/my lists_black icon.svg";

interface HeaderProps {}

const Header: FC<HeaderProps> = () => {
  // drawer state of "My Lists"
  const [drawerState, setDrawerState] = React.useState(false);

  const toggleMyListsDrawer = (newState: boolean) => () => {
    setDrawerState(newState);
  };

  return <div className="Container1920" style={{height: "100%"}} data-testid="Header">
    <div className={styles.Header}>
      {/* Logo */}
      <div className={styles.HeaderLogo}>
        <Link to="/">
          <img src={logo} className={styles.Logo} alt="logo" />
          <img src={logoSmall} className={styles.LogoSmall} alt="logo-small" />
        </Link>
      </div>

      {/* Search Bar */}
      <div className={styles.HeaderSearchBar}>
        <SearchBar isHeaderSearchBar={true} />
      </div>

      {/* My Lists */}
      <div className={styles.HeaderMyLists}>
        <div>
          <Chip style={{border: "0"}} variant="outlined"
                icon={<img src={myListsIcon} alt="my lists" />}
                label={<span className={styles.MyLists}>My Lists</span>}
                onClick={toggleMyListsDrawer(true)}/>
          <MyLists drawerState={drawerState} toggleMyListsDrawer={toggleMyListsDrawer}/>
        </div>
      </div>

      {/* My Menu */}
      <div className={styles.HeaderMenu}>
        <MyMenu toggleMyListsDrawer={toggleMyListsDrawer}/>
      </div>
    </div>
  </div>
};

export default Header;
