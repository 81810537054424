import React, {FC, useState} from 'react';
import styles from './Carousel.module.css';
import { Box } from "@mui/material";
import { useSwipeable } from "react-swipeable";

import LeftArrowIcon from "../../resources/Home/What is Star Search/left arrow default.svg";
import RightArrowIcon from "../../resources/Home/What is Star Search/right arrow default.svg";

export interface CarouselItemProps {
  id?: string;
  title: string;
  image: string;
  content: string;
  contentSliceSize?: number;
  timeToRead?: number;
  tags?: string;
}

interface CarouselProps {
  items: CarouselItemProps[];
  handleSelect?: (id: string)=>any;
}

function CarouselItem(props: {item: CarouselItemProps, handleSelect?: any}) {
  const [showLess, setShowLess] = React.useState(true);

  const handleClick = () => {
    if (props.handleSelect && props.item.id) {
      props.handleSelect(props.item.id);
    }
  };

  return (!props.item.contentSliceSize) ? // if short content, render with style like a simple card
    <Box className={styles.CarouselItem}>
      <div style={{padding: "calc(1.6vw + 11.6px) calc(2.3vw + 18.8px)", textAlign: "left"}}>
        <img className={styles.CarouselItemImage} src={props.item.image} alt="item img"/>
        <br/><br/>
        <div>
          <div className={styles.CarouselItemTitle}>{props.item.title}</div>
          <br/>
          <div>
            <div className={styles.CarouselItemContent} dangerouslySetInnerHTML={{
              __html: showLess ? `${props.item.content.slice(0, 120)}...` : props.item.content
            }}/>
            <br/>
            <div style={{textAlign: "left"}}>
              <div className={styles.ParagraphTextReadMore} onClick={() => setShowLess(!showLess)}>
                {showLess ? "Read More" : "Read Less"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box> : // if long content, render with another style to show more information
    <Box className={styles.CarouselItemRound} onClick={handleClick}>
      <div className={styles.CarouselItemRoundContainer}>
        <img className={styles.CarouselItemBigImage} src={props.item.image} alt="big item img"/>
        <div className={styles.CarouselItemLongContentTitle}>{props.item.title}</div>
        <div className={styles.CarouselItemLongContent} dangerouslySetInnerHTML={{__html:
            `${props.item.contentSliceSize ? props.item.content.slice(0, props.item.contentSliceSize) + "..." : props.item.content}`}}/>
        {
          props.item.timeToRead &&
          <div className={styles.TimeToRead}>
            {props.item.timeToRead} min read
          </div>
        }
      </div>
    </Box>;
}

const Carousel: FC<CarouselProps> = (props) => {
  const [itemIndex, setItemIndex] = useState(0);

  const updateIndex = (newIndex: number) => {
    if (newIndex < 0) {
      newIndex = 0;
    } else if (newIndex >= props.items.length) {
      newIndex = props.items.length - 1;
    }

    setItemIndex(newIndex);
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(itemIndex + 1),
    onSwipedRight: () => updateIndex(itemIndex - 1)
  });

  return <div className={styles.Carousel} data-testid="Carousel">
    <div className={styles.Indicators}>
      <img onClick={() => updateIndex(itemIndex - 1)} src={LeftArrowIcon} alt="left arrow icon" className={itemIndex <= 0 ? styles.DisabledArrowIcon : styles.ArrowIcon}/>
      <img onClick={() => updateIndex(itemIndex + 1)} src={RightArrowIcon} alt="right arrow icon" className={itemIndex >= (props.items.length - 1) ? styles.DisabledArrowIcon : styles.ArrowIcon}/>
      <br/>
    </div>
    <div {...handlers} style={{width: `${props.items.length * 78}vw`}} className={`${styles.CarouselContainer} ${props.handleSelect ? undefined : "Container1920"}`}>
      <Box sx={{ display: 'flex'}} style={{transform: `translateX(-${100 / props.items.length * itemIndex}%)`, transition: "transform 0.6s"}} >
        {
          props.items.map((item, i) => (
            <CarouselItem item={item} handleSelect={props.handleSelect} key={i}/>
          ))
        }
      </Box>
    </div>
  </div>;
}

export default Carousel;
